import React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { toast } from "react-toastify";

import { Box, Grid, TextField } from "@mui/material";
import { SubmitButton } from "../../../../../components/common/Buttons/SubmitButton";
import Errors from "../../../../../components/common/Errors";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { DeleteButton } from "../../../../../components/common/Buttons/deleteButton";
import { useUpdateFaqCategoryIconMutation } from "../../../../../store/RTK/faq";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const SUPPORTED_FORMATS = ["image/png", "image/jpeg", "image/jpg"];

const AddIcon = ({ open, handleClose, singleItem }: any) => {
  const schema = yup.object({
    icon: yup
      .mixed()
      .required("A file is required")
      .test("fileType", "Unsupported file format", (value: any) => {
        if (!value) return true; // If no file is provided, don't perform validation
        return SUPPORTED_FORMATS.includes(value.type);
      }),
  });
  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  const [updateFaqCategoryIcon, { isSuccess, isLoading, isError, data }] =
    useUpdateFaqCategoryIconMutation();
  const onSubmit = async (data: any) => {
    const formData = new FormData();
    formData.append("icon", data?.icon);
    formData.append("_method", "put");
    try {
      const sendData = {
        body: formData,
        id: singleItem?.id,
      };
      await updateFaqCategoryIcon(sendData);

      methods.reset();
      //   if (isSuccess) {
      toast.success("category has been deleted");
      //   }
    } catch (error: any) {
      toast.error("something went wrong");
    }
  };
  React.useEffect(() => {
    if (isSuccess) {
      toast.success("Icon has been created");
      handleClose();
    }
    if (isError) {
      toast.error("something went wrong");
    }
  }, [isSuccess, isError]);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid container columns={12} spacing={3}>
        <Grid item xs={12} style={{}}>
          <h4>Add Icon</h4>
        </Grid>
        <Box sx={style}>
          {/* <Typography
            id="modal-modal-description"
            sx={{ mt: 2, textAlign: "center" }}
          > */}
          <div
            className="btns"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              justifyContent: "center",
              padding: "1rem 0",
            }}
          >
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Grid container columns={12} spacing={3}>
                  <Grid item xs={12} style={{}}></Grid>

                  <Grid item xs={12}>
                    <label htmlFor="">Add Icon</label>
                    <Controller
                      control={methods.control}
                      name={"icon"}
                      render={({ field: { value, onChange, ...field } }) => {
                        return (
                          <TextField
                            {...field}
                            value={value?.fileName}
                            onChange={(event: any) => {
                              onChange(event.target.files[0]);
                            }}
                            type="file"
                            id="picture"
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SubmitButton type="submit" fullWidth>
                      {isLoading ? "creating" : "Save"}
                    </SubmitButton>
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </div>
          {/* </Typography> */}
        </Box>
      </Grid>
    </Modal>
  );
};

export default AddIcon;
