import React, { useState } from "react";
import { useGetBlogsQuery } from "../../../../store/RTK/blogs";
import { Bigsection } from "../../satisfiedCustomers/components/showData/style";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteBlog from "../deleteBlog";
import {
  DeleteButton,
  NoButton,
} from "../../../../components/common/Buttons/deleteButton";
import UpdateImg from "../updateImg";
import UpdateBlog from "../editBlog";

const AllBlogs = () => {
  const { isLoading, isSuccess, data, isFetching } = useGetBlogsQuery("");
  console.log("data?.data?.data", data?.data?.data);

  const [openDelete, setOpenDelete] = React.useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);

  const [openUpdateImg, setOpenUpdateImg] = React.useState(false);
  const handleOpenUpdateImg = () => setOpenUpdateImg(true);
  const handleCloseUpdateImg = () => setOpenUpdateImg(false);

  const [openUpdate, setOpenUpdate] = React.useState(false);
  const handleOpenUpdate = () => setOpenUpdate(true);
  const handleCloseUpdate = () => setOpenUpdate(false);

  const [singleBlog, setSingleBlog] = useState();

  const openDeleteModal = (e: any) => {
    setSingleBlog(e);
    handleOpenDelete();
  };

  const openUpdateImgModal = (e: any) => {
    setSingleBlog(e);
    handleOpenUpdateImg();
  };

  const openUpdateModal = (e: any) => {
    setSingleBlog(e);
    handleOpenUpdate();
  };
  const mapping: any = () => {
    return data?.data?.data?.map((e: any) => {
      return (
        <tr>
          <td>{e?.author_ar}</td>
          <td>{e?.author_en}</td>
          <td>{e?.title_ar}</td>
          <td>{e?.title_en}</td>
          <td>
            <a target="_blank" href={e?.media?.file_path}>
              <img src={e?.media?.file_path} alt="" />
            </a>
          </td>
          <td>
            <div className="actions">
              <Link to={`/home/blogs/singleBlog/${e?.id}`}>
                <Tooltip title="Show Single Blog" placement="top">
                  <RemoveRedEyeIcon />
                </Tooltip>
              </Link>
              <NoButton onClick={() => openUpdateImgModal(e)}>
                Edit img
              </NoButton>
              <NoButton onClick={() => openUpdateModal(e)}>Edit Blog</NoButton>
              <DeleteButton onClick={() => openDeleteModal(e)}>
                Delete
              </DeleteButton>
            </div>
          </td>
        </tr>
      );
    });
  };
  return (
    <div>
      <Bigsection>
        {isFetching || isLoading ? (
          <h1>Loading</h1>
        ) : (
          <table>
            <tr>
              <th>Author Ar</th>
              <th>Author EN</th>
              <th>Title Ar</th>
              <th>Title En</th>
              <th>Media</th>
              <th>Actions</th>
            </tr>
            {mapping()}
          </table>
        )}

        {/* <DeleteCategory
        open={open}
        handleClose={handleClose}
        singleItem={selected}
      /> */}
        {/* <AddAssetBundle
        open={openAssetBundle}
        handleClose={handleCloseAsset}
        singleItem={selected}
      /> */}
        {/* <UpdateCategoryImage
        open={openUpdateCategoryMedia}
        handleClose={handleCloseCategoryMedia}
        singleItem={selected}
        key={selected}
      /> */}
        <DeleteBlog
          open={openDelete}
          handleClose={handleCloseDelete}
          singleBlog={singleBlog}
          key={singleBlog}
        />

        <UpdateImg
          open={openUpdateImg}
          handleClose={handleCloseUpdateImg}
          singleBlog={singleBlog}
          key={singleBlog}
        />

        <UpdateBlog
          open={openUpdate}
          handleClose={handleCloseUpdate}
          singleBlog={singleBlog}
          key={singleBlog}
        />
      </Bigsection>
    </div>
  );
};

export default AllBlogs;
