import React from "react";
import {
  DeleteButton,
  NoButton,
} from "../../../../../../components/common/Buttons/deleteButton";
import { Bigsection } from "../../../../satisfiedCustomers/style";
import { NavLink, Outlet } from "react-router-dom";

const SinlgeCategory = () => {
  return (
    <Bigsection>
      <nav>
        <ul>
          <NavLink to="show">
            <li>Show All Questions for this category</li>
          </NavLink>
          <NavLink to="create">
            <li>Add Question </li>
          </NavLink>
        </ul>
      </nav>
      <div>
        <Outlet />
      </div>
    </Bigsection>
  );
};

export default SinlgeCategory;
