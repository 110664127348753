import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../helper/BaseQuery";

export const WalletApi = createApi({
  reducerPath: "WalletApi",
  baseQuery,
  tagTypes: ["wallet"],
  endpoints: (builder) => ({
    getWalletRequuests: builder.query({
      query: ({ page, status }) => ({
        url: "wallet-requests",
        method: "get",
        params: {
          page,
          status,
        },
      }),
      providesTags: ["wallet"],
    }),
    getSinglealletRequuest: builder.query({
      query: (id) => ({
        url: `wallet-requests/show/${id}`,
        method: "get",
      }),
      providesTags: ["wallet"],
    }),
    updateStatus: builder.mutation<any, any>({
      query: ({ body, id }) => ({
        url: `wallet-requests/change-status/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["wallet"],
    }),
  }),
});

export const {
  useGetWalletRequuestsQuery,
  useGetSinglealletRequuestQuery,
  useUpdateStatusMutation,
} = WalletApi;
