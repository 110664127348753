import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../helper/BaseQuery";

export const WalletProductApi = createApi({
  reducerPath: "WalletProductApi",
  baseQuery,
  tagTypes: ["walletProduct"],
  endpoints: (builder) => ({
    getWalletProductRequuests: builder.query({
      query: ({ page, status }) => ({
        url: "wallet-requests-product",
        method: "get",
        params: {
          page,
          status,
        },
      }),
      providesTags: ["walletProduct"],
    }),
    getSingleWalletProductRequuest: builder.query({
      query: (id) => ({
        url: `wallet-requests-product/show/${id}`,
        method: "get",
      }),
      providesTags: ["walletProduct"],
    }),
    updateWalletProductsStatus: builder.mutation<any, any>({
      query: ({ body, id }) => ({
        url: `wallet-requests-product/change-status/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["walletProduct"],
    }),
  }),
});

export const {
  useGetWalletProductRequuestsQuery,
  useGetSingleWalletProductRequuestQuery,
  useUpdateWalletProductsStatusMutation,
} = WalletProductApi;
