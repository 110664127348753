import React from "react";
import { Bigsection } from "./style";
import { NavLink, Outlet } from "react-router-dom";

const ColoringActivites = () => {
  return (
    <Bigsection>
      <nav>
        <ul className="navv">
          <NavLink to="show">
            <li>All coloring activites</li>
          </NavLink>
          <NavLink to="create">
            <li>Create coloring activites</li>
          </NavLink>
        </ul>
      </nav>
      <div style={{ margin: "2rem 0" }}>
        <Outlet />
      </div>
    </Bigsection>
  );
};

export default ColoringActivites;
