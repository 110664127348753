import React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { toast } from "react-toastify";

import { Box, Grid, TextField } from "@mui/material";
import StanderInput from "../../../../../components/common/standerInput";
import StanderTextArea from "../../../../../components/common/standardTextArea";
import { SubmitButton } from "../../../../../components/common/Buttons/SubmitButton";
import Errors from "../../../../../components/common/Errors";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useCreateFaqCategoryMutation } from "../../../../../store/RTK/faq";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const SUPPORTED_FORMATS = ["image/png", "image/jpeg", "image/jpg"];

const CreateCategoryFaq = ({ open, handleClose }: any) => {
  const schema = yup.object({
    title_en: yup.string().required("title_en is required"),
    title_ar: yup.string().required("title_ar is required"),
    icon: yup
      .mixed()
      .required("A file is required")
      .test("fileType", "Unsupported file format", (value: any) => {
        if (!value) return true; // If no file is provided, don't perform validation
        return SUPPORTED_FORMATS.includes(value.type);
      }),
  });
  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  const [error, setError] = React.useState([]);
  const [createFaqCategory, { isSuccess, isLoading, isError, data }] =
    useCreateFaqCategoryMutation();
  const onSubmit = async (data: any) => {
    try {
      const formData = new FormData();
      for (const property in data) {
        formData.append(property, data[property]);
      }

      await createFaqCategory(formData).unwrap();
      toast.success("Category created successfully");
      methods.reset();
    } catch (error: any) {
      toast.error("something went wrong");
    }
  };
  const errs: any = methods?.formState?.errors;
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid container columns={12} spacing={3}>
              <Grid item xs={12} style={{}}>
                <h4>Add Category</h4>
              </Grid>

              <Grid item xs={12}>
                <StanderInput
                  // required
                  name="title_en"
                  placeholder="title_en"
                  Label="title_en"
                  type="string"
                  defaultValue=""
                ></StanderInput>
              </Grid>
              <Grid item xs={12}>
                <StanderInput
                  // required
                  name="title_ar"
                  placeholder="title_ar"
                  Label="title_ar"
                  type="string"
                  defaultValue=""
                ></StanderInput>
              </Grid>
              <Grid item xs={12}>
                <label htmlFor="">Add Icon</label>
                <Controller
                  control={methods.control}
                  name={"icon"}
                  render={({ field: { value, onChange, ...field } }) => {
                    return (
                      <TextField
                        {...field}
                        value={value?.fileName}
                        onChange={(event: any) => {
                          onChange(event.target.files[0]);
                        }}
                        type="file"
                        id="picture"
                      />
                    );
                  }}
                />
                <p className="err">{errs?.icon && errs?.icon?.message}</p>
              </Grid>

              <Grid item xs={12}>
                <SubmitButton type="submit" fullWidth>
                  {isLoading ? "creating" : "Save"}
                </SubmitButton>
              </Grid>
              <Grid item xs={12}>
                <Errors errors={error}></Errors>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Box>
    </Modal>
  );
};

export default CreateCategoryFaq;
