import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../helper/BaseQuery";

export const PaymentApi = createApi({
    reducerPath: "PaymentApi",
    baseQuery,
    tagTypes: ["Payment"],
    endpoints: (builder) => ({
        getPayment: builder.query({
            query: () => ({
                url: "/payment-method",
                method: "get",
            }),
            providesTags: ["Payment"]
        }),
        createPayment: builder.mutation<any, any>({
            query: (body) => ({
                url: "payment-method/store",
                method: "POST",
                body,
            }),
            invalidatesTags: ["Payment"],
        }),
        updatePayment: builder.mutation<any, any>({
            query: ({ id, data }) => ({
                url: `payment-method/update-icon/${id}`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["Payment"],
        }),

    }),
});

export const {
    useGetPaymentQuery,
    useCreatePaymentMutation,
    useUpdatePaymentMutation,
} = PaymentApi;
