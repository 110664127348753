import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  useGetDescriptionQuery,
  useGetSingleBlogQuery,
} from "../../../../store/RTK/blogs";
import { Bigsection } from "../../satisfiedCustomers/components/showData/style";
import UpdateSingleDescription from "../updateSingleDescription";
import {
  DeleteButton,
  NoButton,
} from "../../../../components/common/Buttons/deleteButton";
import DeleteSingleDescription from "../deleteSingleDescription";
import AddSingleDescription from "../addSingleDescription";

const SingleBlog = () => {
  const { id } = useParams();
  const { data, isLoading, isFetching } = useGetSingleBlogQuery(id);

  const [openEdit, setOpenEdit] = React.useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  const [openDelete, setOpenDelete] = React.useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);

  const [openCreate, setOpenCreate] = React.useState(false);
  const handleOpenCreate = () => setOpenCreate(true);
  const handleCloseCreate = () => setOpenCreate(false);

  const [singleBlog, setSingleBlog] = useState();

  const openEditModal = (e: any) => {
    setSingleBlog(e);
    handleOpenEdit();
  };

  const openDeleteModal = (e: any) => {
    setSingleBlog(e);
    handleOpenDelete();
  };

  const openCreateModal = (e: any) => {
    handleOpenCreate();
  };
  const mapping: any = () => {
    return data?.data?.blog_descriptions?.map((e: any) => {
      return (
        <tr>
          <td>{e?.title_ar}</td>
          <td>{e?.title_en}</td>
          <td>{e?.body_ar}</td>
          <td>{e?.body_en}</td>
          <td>
            <div className="actions">
              <NoButton onClick={() => openEditModal(e)}>Edit</NoButton>
              <DeleteButton onClick={() => openDeleteModal(e)}>
                delete
              </DeleteButton>
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    <div>
      <Bigsection>
        <NoButton onClick={openCreateModal}>Add description</NoButton>
        {isFetching || isLoading ? (
          <h1>Loading</h1>
        ) : (
          <table>
            <tr>
              <th>title Ar</th>
              <th>title En</th>
              <th>body AR</th>
              <th>body En</th>
              <th>Actions</th>
            </tr>
            {mapping()}
          </table>
        )}

        {/* <DeleteCategory
        open={open}
        handleClose={handleClose}
        singleItem={selected}
      /> */}
        {/* <AddAssetBundle
        open={openAssetBundle}
        handleClose={handleCloseAsset}
        singleItem={selected}
      /> */}
        {/* <UpdateCategoryImage
        open={openUpdateCategoryMedia}
        handleClose={handleCloseCategoryMedia}
        singleItem={selected}
        key={selected}
      /> */}
        <UpdateSingleDescription
          open={openEdit}
          handleClose={handleCloseEdit}
          singleBlog={singleBlog}
          key={singleBlog}
        />
        <DeleteSingleDescription
          open={openDelete}
          handleClose={handleCloseDelete}
          singleBlog={singleBlog}
          key={singleBlog}
        />
        <AddSingleDescription
          open={openCreate}
          handleClose={handleCloseCreate}
          singleBlog={id}
          key={singleBlog}
        />
      </Bigsection>
    </div>
  );
};

export default SingleBlog;
