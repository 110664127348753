import React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { toast } from "react-toastify";
import { Checkbox, Grid, TextField } from "@mui/material";
import {
  useCreateSubscriptionMutation,
  useGetAccountTypesQuery,
} from "../../../store/RTK/subscription";
import { Bigsection } from "../../homePage/category/createCategory/style";
import StanderInput from "../../../components/common/standerInput";
import { SubmitButton } from "../../../components/common/Buttons/SubmitButton";
import SelectInput from "../../../components/common/SelectInput/SelectInput";

const SUPPORTED_FORMATS = ["image/png", "image/jpeg", "image/jpg"];
const isPublishDrop = [
  { id: 1, name: "Yes" },
  { id: 0, name: "No" },
];
const period = [
  { id: "Monthly", name: "Monthly" },
  { id: "Annual", name: "Annual" },
];
const CreateSub = () => {
  const schema = yup.object({
    title_en: yup.string().required("title_en is required"),
    title_ar: yup.string().required("title_ar is required"),
    account_type_id: yup.string().required("account type is required"),
    pound_price: yup.string().required("pound_price is required"),
    dolar_price: yup.string().required("dolar_price is required"),
    instead_pound: yup.string().required("instead_pound is required"),
    instead_dolar: yup.string().required("instead dolar is required"),
    period: yup.string().required("period is required"),
    is_publish: yup.string().required("is_publish is required"),
    contents: yup
      .array()
      .required("Array is required")
      .min(1, "Array must have at least 1 item")
      // .max(5, "Array must have at most 5 items")
      .of(
        yup.object().shape({
          content_en: yup.string().required("content_en is required"),
          content_ar: yup.string().required("content_ar is required"),
          // Define other properties as needed
        })
      ),
  });
  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      contents: [{ content_en: "", content_ar: "" }],
    },
  });

  const control = methods.control;
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: "contents",
    }
  );
  const validationErr: any = methods.formState.errors;
  const [error, setError] = React.useState([]);
  const [createSubscription, { isSuccess, isLoading, isError, data }] =
    useCreateSubscriptionMutation();
  const { data: dataPptions } = useGetAccountTypesQuery("");
  const onSubmit = async (data: any) => {
    const contents = data?.contents?.map((e: any) => {
      return e?.contents;
    });
    // const sendData = {
    //   account_type_id: data?.account_type_id,
    //   title: data?.title,
    //   pound_price: data?.pound_price,
    //   dolar_price: data?.dolar_price,
    //   period: data?.period,
    //   is_publish: data?.is_publish,
    //   contents: data?.contents,
    //   instead_pound: data?.instead_pound,
    //   instead_dolar: data?.instead_dolar,
    //   is_best_value: data?.is_best_value,
    // };
    try {
      // await createSubscription(sendData).unwrap();
      // await toast.success("Subscription has been created");
      console.log("send Data", data);
    } catch (e) {
      toast.error("something wrong");
    }
  };
  console.log("Watch", methods.watch("contents"));
  console.log("fields", fields);

  return (
    <Bigsection>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container columns={12} spacing={3}>
            <Grid item xs={12} style={{}}>
              <h4>Add Category</h4>
            </Grid>
            <Grid item xs={12}>
              <SelectInput
                label="account_type_id"
                name="account_type_id"
                data={dataPptions?.data}
              ></SelectInput>
            </Grid>
            <Grid item xs={12}>
              <StanderInput
                // required
                name="title_en"
                placeholder="title_en"
                Label="title_en"
                type="string"
                defaultValue=""
              ></StanderInput>
            </Grid>
            <Grid item xs={12}>
              <StanderInput
                // required
                name="title_ar"
                placeholder="title_ar"
                Label="title_ar"
                type="string"
                defaultValue=""
              ></StanderInput>
            </Grid>
            <Grid item xs={12}>
              <StanderInput
                // required
                name="pound_price"
                placeholder="pound_price"
                Label="pound_price"
                type="number"
                defaultValue=""
              ></StanderInput>
            </Grid>
            <Grid item xs={12}>
              <StanderInput
                // required
                name="instead_pound"
                placeholder="instead_pound"
                Label="instead_pound"
                type="number"
                defaultValue=""
              ></StanderInput>
            </Grid>
            <Grid item xs={12}>
              <StanderInput
                // required
                name="dolar_price"
                placeholder="dolar_price"
                Label="dolar_price"
                type="number"
                defaultValue=""
              ></StanderInput>
            </Grid>
            <Grid item xs={12}>
              <StanderInput
                // required
                name="instead_dolar"
                placeholder="instead_dolar"
                Label="instead_dolar"
                type="number"
                defaultValue=""
              ></StanderInput>
            </Grid>
            <Grid item xs={12}>
              <SelectInput
                // required
                name="period"
                placeholder="period"
                label="period"
                defaultValue=""
                data={period}
              ></SelectInput>
            </Grid>
            <Grid item xs={12}>
              <SelectInput
                // required
                name="is_publish"
                placeholder="is_publish"
                label="is_publish"
                defaultValue=""
                data={isPublishDrop}
              ></SelectInput>
            </Grid>
            <Grid item xs={12}>
              <label htmlFor="">is_best_value</label>
              <Controller
                control={control}
                name={"is_best_value"}
                render={({ field: { value, onChange, ...field } }) => {
                  return (
                    <Checkbox
                      {...field}
                      value={value?.fileName}
                      onChange={(event: any) => {
                        onChange(+event.target.checked);
                      }}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <button
                type="button"
                onClick={() => append({ content_en: "", content_ar: "" })}
              >
                add contents
              </button>
              {fields.map((field, index) => (
                <div key={index}>
                  <StanderInput
                    key={field.id} // important to include key with field's id
                    // name={`contents.[${index}].contents`}
                    name={`contents.[${index}].content_en`}
                    placeholder="contents EN"
                    Label="contents En"
                    type="string"
                    defaultValue=""
                  />
                  <StanderInput
                    key={field.id} // important to include key with field's id
                    // name={`contents.[${index}].contents`}
                    name={`contents.[${index}].content_ar`}
                    placeholder="contents Ar "
                    Label="contents Ar"
                    type="string"
                    defaultValue=""
                  />
                  <button type="button" onClick={() => remove(index)}>
                    Delete
                  </button>
                </div>
              ))}
            </Grid>
            <Grid item xs={12}>
              <SubmitButton type="submit" fullWidth>
                {isLoading ? "creating" : "Save"}
              </SubmitButton>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Bigsection>
  );
};

export default CreateSub;
