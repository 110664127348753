import React from "react";
import {
  DeleteButton,
  NoButton,
} from "../../../../../components/common/Buttons/deleteButton";
import { Bigsection } from "../../../satisfiedCustomers/components/showData/style";
import { useGetFaqCategoryQuery } from "../../../../../store/RTK/faq";
import CreateCategoryFaq from "../create";
import DeleteCategoryFaq from "../delete";
import { NavLink } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import AddIcon from "../addIcon";

const ShowCategoryFaq = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openِAddIcon, setOpenAddIcon] = React.useState(false);
  const handleOpenAddIcon = () => setOpenAddIcon(true);
  const handleCloseAddIcon = () => setOpenAddIcon(false);

  const [openDelete, setDelete] = React.useState(false);
  const handleOpenDelete = () => setDelete(true);
  const handleCloseDelete = () => setDelete(false);

  const [selected, setSelected] = React.useState<any>();

  const mapping: any = () => {
    return data?.data.map((e: any) => {
      return (
        <tr key={e?.id}>
          <td className="content">{e?.title_ar}</td>
          <td className="content">{e?.title_en}</td>
          <td className="content">
            <img src={e?.media?.file_path}></img>
          </td>
          <td>
            <div className="actions">
              <DeleteButton onClick={() => select(e)}>Delete</DeleteButton>
              <NavLink to={`/home/faqs/show/${e?.id}`}>
                <NoButton>
                  <RemoveRedEyeIcon />
                </NoButton>
              </NavLink>
              <NoButton onClick={() => addIcon(e)}>Add icon</NoButton>
            </div>
          </td>
        </tr>
      );
    });
  };
  const select = (feed: any) => {
    setSelected(feed);
    handleOpenDelete();
  };
  const addIcon = (feed: any) => {
    setSelected(feed);
    handleOpenAddIcon();
  };
  const { data, isLoading, isFetching } = useGetFaqCategoryQuery({});

  return (
    <div>
      <NoButton onClick={handleOpen}>Create Category</NoButton>
      <Bigsection>
        {isFetching || isLoading ? (
          <h1>Loading</h1>
        ) : (
          <table>
            <tr>
              <th>title_ar</th>
              <th>title_en</th>
              <th>icon</th>
              <th>actions</th>
            </tr>
            {mapping()}
          </table>
        )}
      </Bigsection>

      <CreateCategoryFaq
        open={open}
        handleClose={handleClose}
        singleItem={selected}
      />
      <DeleteCategoryFaq
        open={openDelete}
        handleClose={handleCloseDelete}
        singleItem={selected}
      />
      <AddIcon
        open={openِAddIcon}
        handleClose={handleCloseAddIcon}
        singleItem={selected}
      />
    </div>
  );
};

export default ShowCategoryFaq;
