import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../helper/BaseQuery";

export const satisfiedApi = createApi({
  reducerPath: "feedBackApi",
  baseQuery,
  tagTypes: ["FeedBack"],
  endpoints: (builder) => ({
    getFeedBack: builder.query({
      query: () => ({
        url: "/satisfied-customer",
        method: "get",
      }),
      providesTags: ["FeedBack"]
    }),
    createFeedBack: builder.mutation<any, any>({
      query: (body) => ({
        url: "satisfied-customer/store",
        method: "POST",
        body,
      }),
      invalidatesTags: ["FeedBack"],
    }),
    deleteFeedBack: builder.mutation<any, any>({
      query: (id) => ({
        url: `satisfied-customer/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["FeedBack"],
    }),
  }),
});

export const {
  useGetFeedBackQuery,
  useCreateFeedBackMutation,
  useDeleteFeedBackMutation,
} = satisfiedApi;
