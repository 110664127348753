import React, { useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { toast } from "react-toastify";
import { Grid, TextField } from "@mui/material";
import StanderInput from "../../../../components/common/standerInput";
import { SubmitButton } from "../../../../components/common/Buttons/SubmitButton";
import Errors from "../../../../components/common/Errors";
import { useCreateBlogMutation } from "../../../../store/RTK/blogs";
import StanderTextArea from "../../../../components/common/standardTextArea";

const SUPPORTED_FORMATS = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/webp",
];
const CreateBlog = () => {
  const schema = yup.object({
    title_en: yup.string().required("Filed is required"),
    title_ar: yup.string().required("Filed is required"),
    author_en: yup.string().required("Filed is required"),
    author_ar: yup.string().required("Filed is required"),
    media: yup
      .mixed()
      .required("A file is required")
      .test("fileType", "Unsupported file format", (value: any) => {
        if (!value) return true; // If no file is provided, don't perform validation
        return SUPPORTED_FORMATS.includes(value.type);
      }),
    descriptions: yup
      .array()
      .required("Array is required")
      .min(1, "Array must have at least 1 item")
      .max(10, "Array must have at most 5 items")
      .of(
        yup.object().shape({
          title_en: yup.string().required("title En is reqired is required"),
          title_ar: yup.string().required("title Ar is reqired is required"),
          body_en: yup.string().required("body En is reqired is required"),
          body_ar: yup.string().required("body Ar is reqired is required"),
        })
      ),
  });
  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      descriptions: [{ title_ar: "", body_ar: "", title_en: "", body_en: "" }],
    },
  });
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control: methods.control, // control props comes from useForm (optional: if you are using FormContext)
      name: "descriptions", // unique name for your Field Array+
    }
  );
  const [createBlog, { isLoading, isSuccess, isError }] =
    useCreateBlogMutation();

  const onSubmit = async (data: any) => {
    const formData = new FormData();
    formData.append("title_ar", data?.title_ar);
    formData.append("title_en", data?.title_en);
    formData.append("author_ar", data?.author_ar);
    formData.append("author_en", data?.author_en);
    formData.append("media", data?.media);
    data?.descriptions?.map((e: any, i: number) => {
      for (const property in e) {
        formData.append(`descriptions[${i}][${property}]`, `${e[property]}`);
      }
    });
    try {
      console.log("data", data);
      await createBlog(formData).unwrap();
    } catch (error: any) {}
  };
  useEffect(() => {
    if (isSuccess) {
      toast.success("Doneeeeeeeeeeeee");
      methods.reset();
    }
    if (isError) {
      toast.error("Errrrrrr");
    }
  }, [isSuccess, isError]);
  const { formState } = methods;
  console.log("formState", formState?.errors);
  let errs: any = formState?.errors;
  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container columns={12} spacing={3}>
            <Grid item xs={12} style={{}}>
              <h4>Add Feedback</h4>
            </Grid>
            <Grid item xs={12}>
              <StanderInput
                // required
                name="title_ar"
                placeholder="title_ar"
                Label="title_ar"
                type="string"
                defaultValue=""
              ></StanderInput>
            </Grid>
            <Grid item xs={12}>
              <StanderInput
                // required
                name="title_en"
                placeholder="title_en"
                Label="title_en"
                type="string"
                defaultValue=""
              ></StanderInput>
            </Grid>
            <Grid item xs={12}>
              <StanderInput
                // required
                name="author_ar"
                placeholder="author_ar"
                Label="author_ar"
                type="string"
                defaultValue=""
              ></StanderInput>
            </Grid>
            <Grid item xs={12}>
              <StanderInput
                // required
                name="author_en"
                placeholder="author_en"
                Label="author_en"
                type="string"
                defaultValue=""
              ></StanderInput>
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={methods.control}
                name={"media"}
                render={({ field: { value, onChange, ...field } }) => {
                  return (
                    <TextField
                      {...field}
                      value={value?.fileName}
                      onChange={(event: any) => {
                        onChange(event.target.files[0]);
                      }}
                      type="file"
                      id="picture"
                    />
                  );
                }}
              />
              <p className="err">{errs?.media && errs?.media?.message}</p>
            </Grid>
            <Grid item xs={12}>
              <button
                type="button"
                onClick={() =>
                  append({
                    title_ar: "",
                    body_ar: "",
                    title_en: "",
                    body_en: "",
                  })
                }
              >
                add description
              </button>
              {fields.map((field, index) => (
                <div key={field.id}>
                  <StanderInput
                    name={`descriptions.[${index}].title_en`}
                    // placeholder="description"
                    Label={"title En"}
                    type="string"
                    defaultValue=""
                  />
                  <StanderTextArea
                    name={`descriptions.[${index}].body_en`}
                    // placeholder="description"
                    Label="body En"
                    type="string"
                    defaultValue=""
                  />
                  <StanderInput
                    name={`descriptions.[${index}].title_ar`}
                    // placeholder="description"
                    Label={"title Ar"}
                    type="string"
                    defaultValue=""
                  />
                  <StanderTextArea
                    name={`descriptions.[${index}].body_ar`}
                    // placeholder="description"
                    Label="body Ar"
                    type="string"
                    defaultValue=""
                  />
                  <button type="button" onClick={() => remove(index)}>
                    Delete
                  </button>
                </div>
              ))}
            </Grid>
            <Grid item xs={12}>
              <SubmitButton type="submit" fullWidth>
                {isLoading ? "creating" : "Save"}
              </SubmitButton>
            </Grid>
            <Grid item xs={12}></Grid>
          </Grid>
        </form>
      </FormProvider>
    </div>
  );
};

export default CreateBlog;
