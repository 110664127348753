import React, { useEffect } from "react";
import { toast } from "react-toastify";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Modal, Switch } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useUpdateCategoryUnityAnswerMutation } from "../../../../../../../../../store/RTK/faq";
import StanderInput from "../../../../../../../../../components/common/standerInput";
import { SubmitButton } from "../../../../../../../../../components/common/Buttons/SubmitButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const UpdateAnswer = ({ open, handleClose, singleItem }: any) => {
  const schema = yup.object({
    ans_en: yup.string().required("que_en is required"),
    ans_ar: yup.string().required("que_ar is required"),
    is_write: yup.string().required("is_write is required"),
  });
  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const params = useParams();
  const id = params?.id;
  console.log("singleItem id", singleItem?.id);
  console.log("ID", id);
  const [error, setError] = React.useState([]);
  const [updateCategoryUnityAnswer, { isLoading, isSuccess, isError }] =
    useUpdateCategoryUnityAnswerMutation();

  useEffect(() => {
    methods?.setValue("ans_en", singleItem?.ans_en);
    methods?.setValue("ans_ar", singleItem?.ans_ar);
    methods?.setValue("is_write", singleItem?.is_write);
  }, [singleItem]);

  const onSubmit = async (data: any) => {
    try {
      const sendData = {
        id: singleItem?.id,
        body: data,
      };
      await updateCategoryUnityAnswer(sendData);
      console.log("Send data", data);
      // await methods.reset();
    } catch (error: any) {
      toast.error("something went wrong");
      setError(error?.data?.errors);
    }
  };

  React.useEffect(() => {
    if (isSuccess) {
      toast.success("عاش يابو الزووك");
    }
    if (isError) {
      toast.error("يووووة بقي");
    }
  }, [isSuccess, isError]);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={style}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid container columns={12} spacing={3}>
              <Grid item xs={12} style={{}}>
                <h4>ppppppppppppppp</h4>
              </Grid>
              <Grid item xs={12}>
                <StanderInput
                  name="ans_en"
                  placeholder="ans_en"
                  Label="ans_en"
                  type="string"
                  defaultValue=""
                ></StanderInput>
                <StanderInput
                  name="ans_ar"
                  placeholder="ans_ar"
                  Label="ans_ar"
                  type="string"
                  defaultValue=""
                ></StanderInput>
              </Grid>
              <Grid item xs={12}>
                <label htmlFor="">is_write</label>
                <Controller
                  control={methods.control}
                  name={`is_write`}
                  render={({ field: { value, onChange, ...field } }) => {
                    return (
                      <Switch
                        defaultChecked={
                          singleItem?.is_write == "1" ? true : false
                        }
                        onChange={(e) => {
                          onChange(Number(e.target.checked));
                        }}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <SubmitButton type="submit" fullWidth>
                  {isLoading ? "creating" : "Save"}
                </SubmitButton>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Box>
    </Modal>
  );
};

export default UpdateAnswer;
