import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import StanderInput from "../common/standerInput";
import { Tlogin } from "../../typs/loginType";
import {
  LoginContainer,
  LoginHeading,
  SignUpText,
} from "../../pages/login/loginPageStyle";
import { ButtonStyled } from "../common/Buttons/PrimaryButton";

import { useLoginMutation } from "../../store/RTK/auth";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../store/reducers/auth/authSlice";
import { toast } from "react-toastify";
import { redirect, useNavigate } from "react-router-dom";
import React from "react";

const LoginForm = () => {
  const dispatch = useDispatch();
  const methods = useForm<Tlogin>({ mode: "onChange" });
  const [login, { isLoading, isError, isSuccess, error }] = useLoginMutation();
  const navigate = useNavigate();
  const onSubmit = async (data: any) => {
    try {
      const res = await login({ ...data }).unwrap();
      await localStorage.setItem("octar-dash", JSON.stringify(res.data));
      await dispatch(loginSuccess());
      await navigate("/home");
    } catch (error: any) {
      console.log("error", error);
      toast.error(`${error?.data?.message}`);
    }
  };

  return (
    <LoginContainer>
      <LoginHeading>Sign In 👋</LoginHeading>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <StanderInput
            required
            name="email"
            placeholder="you@company.com"
            Label="Company Email"
          />
          <StanderInput
            required
            name="password"
            placeholder="password"
            Label="Password"
            type="password"
          />
          <ButtonStyled type="submit">
            {isLoading ? "Loading..." : "Let's Go"}
          </ButtonStyled>
        </form>
      </FormProvider>
      <a href="">Forget Password ?</a>
    </LoginContainer>
  );
};

export default LoginForm;
