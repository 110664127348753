import React from "react";
import HomeNav from "./components/nav";

const HomePage = () => {
  return (
    <div>
      <HomeNav />
    </div>
  );
};

export default HomePage;
