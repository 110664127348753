import React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useCreateFeedBackMutation } from "../../../../../store/RTK/feedBack";
import { Grid } from "@mui/material";
import StanderInput from "../../../../../components/common/standerInput";
import StanderTextArea from "../../../../../components/common/standardTextArea";
import { SubmitButton } from "../../../../../components/common/Buttons/SubmitButton";
import Errors from "../../../../../components/common/Errors";
import SelectInput from "../../../../../components/common/SelectInput/SelectInput";
const allRating = [
  { name: 1, id: 1 },
  { name: 2, id: 2 },
  { name: 3, id: 3 },
  { name: 4, id: 4 },
  { name: 5, id: 5 },
];
const CreateFeed = () => {
  const schema = yup.object({
    name: yup.string().required("name is required"),
    title: yup.string().required("title is required"),
    content_en: yup.string().required("content_en is required"),
    content_ar: yup.string().required("content_ar is required"),
    rate: yup.string().required("rate is required"),
  });
  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  const [error, setError] = React.useState([]);
  const [createFeedBack, { isSuccess, isLoading, isError, data }] =
    useCreateFeedBackMutation();
  const onSubmit = async (data: any) => {
    console.log("Send Date", data);

    try {
      await createFeedBack(data).unwrap();
      await methods.reset();
      //   if (isSuccess) {
      toast.success("feedback has been created");
      setError([]);
      //   }
    } catch (error: any) {
      toast.error("something went wrong");
      setError(error?.data?.errors);
    }
  };
  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container columns={12} spacing={3}>
            <Grid item xs={12} style={{}}>
              <h4>Add Feedback</h4>
            </Grid>

            <Grid item xs={12}>
              <StanderInput
                // required
                name="name"
                placeholder="Name"
                Label="Name"
                type="string"
                defaultValue=""
              ></StanderInput>
            </Grid>

            <Grid item xs={12}>
              <StanderInput
                // required
                name="title"
                placeholder="Title"
                Label="Title"
                type="string"
                defaultValue=""
              ></StanderInput>
            </Grid>

            <Grid item xs={12}>
              <StanderTextArea
                // required
                name="content_en"
                // placeholder="description"
                Label="Content_en"
                type="text"
                minRows={5}
                defaultValue=""
              ></StanderTextArea>
            </Grid>
            <Grid item xs={12}>
              <StanderTextArea
                // required
                name="content_ar"
                // placeholder="description"
                Label="Content_ar"
                type="text"
                minRows={5}
                defaultValue=""
              ></StanderTextArea>
            </Grid>
            <Grid item xs={12}>
              <SelectInput
                data={allRating}
                label="Rate"
                name={`rate`}
                defaultValue=""
              />
            </Grid>
            <Grid item xs={12}>
              <SubmitButton type="submit" fullWidth>
                {isLoading ? "creating" : "Save"}
              </SubmitButton>
            </Grid>
            <Grid item xs={12}>
              <Errors errors={error}></Errors>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </div>
  );
};

export default CreateFeed;
