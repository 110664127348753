import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../helper/BaseQuery";

export const subscriptionApi = createApi({
    reducerPath: "subscriptionApi",
    baseQuery,
    tagTypes: ["Subscription", "SingleSubscription"],
    endpoints: (builder) => ({

        getAccountTypes: builder.query({
            query: () => ({
                url: "/subscription/all-account-types",
                method: "get",
            }),
        }),

        getSubscription: builder.query({
            query: () => ({
                url: "/subscription/index-subscription-plans",
                method: "get",
            }),
            providesTags: ["Subscription"]
        }),

        getSingleSubscription: builder.query({
            query: (id) => ({
                url: `/subscription/show-subscription-plan/${id}`,
                method: "get",
            }),
            providesTags: ["SingleSubscription"]
        }),

        createSubscription: builder.mutation<any, any>({
            query: (body) => ({
                url: "subscription/store-subscription-plan",
                method: "POST",
                body,
            }),
            invalidatesTags: ["Subscription"],
        }),

        createSubscriptionDec: builder.mutation<any, any>({
            query: (body) => ({
                url: "subscription/add-subscription-plan-description",
                method: "POST",
                body,
            }),
            invalidatesTags: ["SingleSubscription"],
        }),

        updateSubscription: builder.mutation<any, any>({
            query: ({ id, data }) => ({
                url: `subscription/update-subscription-plan/${id}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["Subscription"],
        }),

        deleteSubscription: builder.mutation<any, any>({
            query: (id) => ({
                url: `subscription/delete-subscription-plan-description/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["SingleSubscription"],
        }),
    }),
});

export const {
    useGetAccountTypesQuery,
    useGetSubscriptionQuery,
    useGetSingleSubscriptionQuery,
    useCreateSubscriptionMutation,
    useCreateSubscriptionDecMutation,
    useUpdateSubscriptionMutation,
    useDeleteSubscriptionMutation,
} = subscriptionApi;
