import { Box, Checkbox, Modal, Typography } from "@mui/material";
import React, { useLayoutEffect } from "react";
import {
  useGetAccountTypesQuery,
  useUpdateSubscriptionMutation,
} from "../../../store/RTK/subscription";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { toast } from "react-toastify";
import { Grid, TextField } from "@mui/material";
import { Bigsection } from "../../homePage/category/createCategory/style";
import StanderInput from "../../../components/common/standerInput";
import { SubmitButton } from "../../../components/common/Buttons/SubmitButton";
import SelectInput from "../../../components/common/SelectInput/SelectInput";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
  height: "70%",
  display: "block",
};
const isPublishDrop = [
  { id: 1, name: "Yes" },
  { id: 0, name: "No" },
];
const period = [
  { id: "Monthly", name: "Monthly" },
  { id: "Annual", name: "Annual" },
];
const EditSub = ({ open, handleClose, singleItem }: any) => {
  console.log("singleItem", singleItem);
  const schema = yup.object({
    title_en: yup.string().required("title_en is required"),
    title_ar: yup.string().required("title_ar is required"),
    account_type_id: yup.string().required("account type is required"),
    pound_price: yup.string().required("pound_price is required"),
    dolar_price: yup.string().required("dolar_price is required"),
    instead_pound: yup.string().required("instead_pound is required"),
    instead_dolar: yup.string().required("instead dolar is required"),
    period: yup.string().required("period is required"),
    is_publish: yup.string().required("is_publish is required"),
  });
  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      contents: [{ contents: "" }],
    },
  });

  const control = methods.control;
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: "contents",
    }
  );
  const validationErr: any = methods.formState.errors;
  const [error, setError] = React.useState([]);
  const [updateSubscription, { isSuccess, isLoading, isError, data }] =
    useUpdateSubscriptionMutation();
  const { data: dataPptions } = useGetAccountTypesQuery("");
  const onSubmit = async (data: any) => {
    const contents = data?.contents?.map((e: any) => {
      return e?.contents;
    });
    const sendData = {
      id: singleItem?.id,
      data: data,
    };
    try {
      updateSubscription(sendData).unwrap();
      console.log("sendData", sendData);
    } catch (e) {
      toast.error("something wrong");
    }
    console.log("sendData", sendData);
  };
  React.useEffect(() => {
    const redirect = async () => {
      if (isSuccess) {
        await toast.success("Subscription has been edited");
        await handleClose();
      }
      if (!isSuccess && isError) {
        await toast.error("something went wrong");
      }
    };
    redirect();
  }, [isSuccess, isError]);
  console.log("Methods Erro", methods.formState.errors);
  useLayoutEffect(() => {
    methods.setValue("title_en", singleItem?.title_en);
    methods.setValue("title_ar", singleItem?.title_ar);
    methods.setValue("account_type_id", singleItem?.account_type_id);
    methods.setValue("pound_price", singleItem?.pound_price);
    methods.setValue("dolar_price", singleItem?.dolar_price);
    methods.setValue("period", singleItem?.period);
    methods.setValue("is_publish", singleItem?.is_publish);
    methods.setValue("instead_pound", singleItem?.instead_pound);
    methods.setValue("instead_dolar", singleItem?.instead_dolar);
    methods.setValue(
      "is_best_value",
      Number(singleItem?.is_best_value) === 1 ? true : false
    );
  }, [singleItem]);
  console.log("singleItem", singleItem);
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edit
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Grid container columns={12} spacing={3}>
                  <Grid item xs={12} style={{}}>
                    <h4>Edit Subscription</h4>
                  </Grid>
                  <Grid item xs={12}>
                    <SelectInput
                      label="account_type_id"
                      name="account_type_id"
                      data={dataPptions?.data}
                    ></SelectInput>
                  </Grid>
                  <Grid item xs={12}>
                    <StanderInput
                      // required
                      name="title_en"
                      placeholder="title_en"
                      Label="title_en"
                      type="string"
                      defaultValue=""
                    ></StanderInput>
                  </Grid>
                  <Grid item xs={12}>
                    <StanderInput
                      // required
                      name="title_ar"
                      placeholder="title_ar"
                      Label="title_ar"
                      type="string"
                      defaultValue=""
                    ></StanderInput>
                  </Grid>
                  <Grid item xs={12}>
                    <StanderInput
                      // required
                      name="pound_price"
                      placeholder="pound_price"
                      Label="pound_price"
                      type="number"
                      defaultValue=""
                    ></StanderInput>
                  </Grid>
                  <Grid item xs={12}>
                    <StanderInput
                      // required
                      name="instead_pound"
                      placeholder="instead_pound"
                      Label="instead_pound"
                      type="number"
                      defaultValue=""
                    ></StanderInput>
                  </Grid>
                  <Grid item xs={12}>
                    <StanderInput
                      // required
                      name="dolar_price"
                      placeholder="dolar_price"
                      Label="dolar_price"
                      type="number"
                      defaultValue=""
                    ></StanderInput>
                  </Grid>
                  <Grid item xs={12}>
                    <StanderInput
                      // required
                      name="instead_dolar"
                      placeholder="instead_dolar"
                      Label="instead_dolar"
                      type="number"
                      defaultValue=""
                    ></StanderInput>
                  </Grid>
                  <Grid item xs={12}>
                    <SelectInput
                      // required
                      name="period"
                      placeholder="period"
                      label="period"
                      defaultValue=""
                      data={period}
                    ></SelectInput>
                  </Grid>
                  <Grid item xs={12}>
                    <SelectInput
                      // required
                      name="is_publish"
                      placeholder="is_publish"
                      label="is_publish"
                      defaultValue=""
                      data={isPublishDrop}
                    ></SelectInput>
                  </Grid>
                  <Grid item xs={12}>
                    <label htmlFor="">is_best_value</label>

                    <Controller
                      control={control}
                      name={"is_best_value"}
                      render={({ field: { value, onChange, ...field } }) => {
                        return (
                          <Checkbox
                            {...field}
                            value={value?.fileName}
                            onChange={(event: any) => {
                              onChange(+event.target.checked);
                            }}
                            defaultChecked={
                              Number(singleItem?.is_best_value) === 1
                                ? true
                                : false
                            }
                          />
                        );
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <SubmitButton type="submit" fullWidth>
                      {isLoading ? "Editing..." : "Save"}
                    </SubmitButton>
                  </Grid>
                </Grid>
              </form>
            </FormProvider>{" "}
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default EditSub;
