import React from "react";
import { useGetPaymentQuery } from "../../../../store/RTK/payment";
import {
  DeleteButton,
  NoButton,
} from "../../../../components/common/Buttons/deleteButton";
import { Bigsection } from "../../../homePage/satisfiedCustomers/components/showData/style";
import Create from "../create";
import EditPayment from "../edit";

const AllPayment = () => {
  const { data, isLoading, isFetching, isSuccess, isError } =
    useGetPaymentQuery("");

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openCreate, setOpenCreate] = React.useState(false);
  const handleOpenCreate = () => setOpenCreate(true);
  const handleCloseCreate = () => setOpenCreate(false);
  const [selected, setSelected] = React.useState<any>();
  const select = () => {
    handleOpenCreate();
  };
  const selectEdit = (e: any) => {
    handleOpen();
    setSelected(e);
  };

  const mapping: any = () => {
    return data?.data.map((e: any) => {
      return (
        <tr key={e?.id}>
          <td>{e?.title}</td>
          <td className="content">
            <div>
              <a href={e?.media?.file_path}>
                <img src={e?.media?.file_path} alt="" />
              </a>
            </div>
          </td>
          <td>
            <NoButton onClick={() => selectEdit(e)}>Update</NoButton>
          </td>
        </tr>
      );
    });
  };

  return (
    <div>
      <Bigsection>
        <NoButton onClick={() => select()}>Create Payment</NoButton>

        {isFetching || isLoading ? (
          <h1>Loading</h1>
        ) : (
          <table>
            <tr>
              <th>Title</th>
              <th>icon</th>
              <th>Actions</th>
            </tr>
            {mapping()}
          </table>
        )}

        <Create open={openCreate} handleClose={handleCloseCreate} />
        <EditPayment
          open={open}
          handleClose={handleClose}
          singleItem={selected}
        />
      </Bigsection>
    </div>
  );
};

export default AllPayment;
