import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../helper/BaseQuery";

export const ColoringActivites = createApi({
  reducerPath: "ColoringActivites",
  baseQuery,
  tagTypes: ["coloring", "subColoring"],
  endpoints: (builder) => ({
    getColoringActivites: builder.query({
      query: ({ search, page }) => ({
        url: `activates`,
        method: "get",
        params: {
          search,
          page,
        },
      }),
      providesTags: ["coloring"],
    }),

    createColoringActivites: builder.mutation<any, any>({
      query: (body) => ({
        url: "activates/store",
        method: "POST",
        body,
      }),
      invalidatesTags: ["coloring"],
    }),

    getSingleColoringActivites: builder.query({
      query: (id) => ({
        url: `activates/show/${id}`,
        method: "get",
      }),
      providesTags: ["coloring"],
    }),

    updateActivity: builder.mutation<any, any>({
      query: ({ id, data }) => ({
        url: `activates/update/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["coloring"],
    }),

    deleteActivity: builder.mutation<any, any>({
      query: (id) => ({
        url: `activates/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["coloring"],
    }),
    // Sub
    getSingleSubColoringActivites: builder.query({
      query: ({ search, page, id }) => ({
        url: `activates/sub-activates/${id}`,
        method: "get",
        params: {
          search,
          page,
        },
      }),
      providesTags: ["subColoring"],
    }),
    createSingleSubColoringActivites: builder.mutation<any, any>({
      query: (body) => ({
        url: "activates/sub-activates/store",
        method: "POST",
        body,
      }),
      invalidatesTags: ["subColoring"],
    }),
    editSingleSubColoringActivites: builder.mutation<any, any>({
      query: ({ body, id }) => ({
        url: `activates/sub-activates/update/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["subColoring"],
    }),
    deleteSubActivity: builder.mutation<any, any>({
      query: (id) => ({
        url: `activates/sub-activates/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["subColoring"],
    }),
  }),
});

export const {
  useLazyGetColoringActivitesQuery,
  useCreateColoringActivitesMutation,
  useGetSingleColoringActivitesQuery,
  useDeleteActivityMutation,
  useUpdateActivityMutation,
  useGetSingleSubColoringActivitesQuery,
  useCreateSingleSubColoringActivitesMutation,
  useDeleteSubActivityMutation,
  useEditSingleSubColoringActivitesMutation,
} = ColoringActivites;
