import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store/store";
import { MAIN_URL } from "../config/Api";
import { logOut } from "../store/reducers/auth/authSlice";

export const baseQuery = async (args: any, api: any, extraOptions: any) => {
  // Use the existing baseQuery with your current setup
  const baseQueryFunction = fetchBaseQuery({
    baseUrl: MAIN_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.data.token;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("Accept", "application/json");
      // headers.set("Content-Type", "application/x-www-form-urlencoded");
      return headers;
    },
  });

  // Execute the request
  const result = await baseQueryFunction(args, api, extraOptions);

  // Check if the response is a 401 Unauthorized
  if (result.error && result.error.status === 401) {
    // Dispatch a logout action to clear user data
    api.dispatch(logOut());

    // Redirect to login page
    // navigate('/login');

    // Optionally return an error
    return {
      error: {
        status: 401,
        data: "Unauthorized - Redirecting to login",
      },
    };
  }

  // Return the original result for other status codes
  return result;
};
