import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Modal, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import StanderInput from "../../../../components/common/standerInput";
import {
  useDeleteBlogMutation,
  useDeleteDescriptionMutation,
  useUpdateDescriptionMutation,
} from "../../../../store/RTK/blogs";
import { SubmitButton } from "../../../../components/common/Buttons/SubmitButton";
import { toast } from "react-toastify";
import { Bigsection } from "../../satisfiedCustomers/components/delete/style";
import { DeleteButton } from "../../../../components/common/Buttons/deleteButton";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const DeleteBlog = ({ open, handleOpen, handleClose, singleBlog }: any) => {
  const [deleteBlog, { isLoading, isSuccess, isError }] =
    useDeleteBlogMutation();
  console.log("singleBlogssssssssssssssssssssssss", singleBlog);
  const onSubmit = async () => {
    try {
      await deleteBlog(singleBlog?.id);
    } catch (error: any) {}
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Doneeeeeeeeeeeee");
      handleClose();
    }
    if (isError) {
      toast.error("Errrrrrr");
    }
  }, [isSuccess, isError]);

  return (
    <Bigsection>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            textAlign={"center"}
          >
            Are you sure you want to delete this Blog
          </Typography>

          <div
            className="btns"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              justifyContent: "center",
              padding: "1rem 0",
            }}
          >
            <DeleteButton onClick={onSubmit}>
              {isLoading ? "Deleting..." : "Delete"}
            </DeleteButton>
            <SubmitButton className="btn" onClick={handleClose}>
              No
            </SubmitButton>
          </div>
        </Box>
      </Modal>
    </Bigsection>
  );
};

export default DeleteBlog;
