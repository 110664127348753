import { Checkbox, Grid, Pagination, Stack, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Bigsection } from "./style";
import {
  useCreateNotificationMutation,
  useGetUsersQuery,
  useLazyGetUsersQuery,
} from "../../../store/RTK/nontification";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import StanderInput from "../../../components/common/standerInput";
import { SubmitButton } from "../../../components/common/Buttons/SubmitButton";

const Nontification = () => {
  const [page, setPage] = React.useState<number>(1);
  const handleChangePage: any = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const [sendLeads, setSendLeads] = React.useState<any>([]);

  const [search, setSearch] = useState<any>("");
  const params = {
    page: page,
    search: search,
  };

  // const { data } = useGetUsersQuery(params);
  const [getUsers, { data: searchResult }] = useLazyGetUsersQuery();
  const [createNotification, { isLoading, isSuccess, isError }] =
    useCreateNotificationMutation();
  const showData = searchResult;
  console.log("search", search.length > 0);
  console.log("showData", showData);
  console.log("page", page);

  const handlChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearch(e.target.value);
  };
  useEffect(() => {
    const fetchApi = async () => {
      getUsers(params);
    };
    const delayDebounceFn = setTimeout(() => {
      fetchApi();
    }, 550);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  let totalPages = searchResult?.data?.total_pages;
  const schema = yup.object({
    title: yup.string().required("title is required"),
    description: yup.string().required("description is required"),
    hint: yup.string().required("hint is required"),
  });

  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  console.log("sendLeads?.includes(e?.id)", sendLeads?.includes(2));
  const mapping = () => {
    return searchResult?.data?.data?.map((e: any) => {
      return (
        <tr key={e?.id}>
          <td>
            <Checkbox
              value={e.id}
              onChange={checkValue}
              defaultChecked={sendLeads?.includes(e?.id) ? true : false}
            />
          </td>
          <td>{e?.first_name}</td>
          <td>{e?.last_name}</td>
          <td>{e?.email}</td>
          <td>{e?.phone}</td>
        </tr>
      );
    });
  };

  const checkValue = (event: any) => {
    var updatedList: any = [...sendLeads];
    if (event.target.checked) {
      updatedList = [...sendLeads, Number(event.target.value)];
    } else {
      updatedList.splice(sendLeads.indexOf(Number(event.target.value)), 1);
    }
    setSendLeads(updatedList);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("عاااااش يابو الزووك");
    }
    if (isError) {
      toast.error("something is wrong");
    }
  }, [isSuccess, isError]);

  const onSubmit = async (data: any) => {
    if (sendLeads.length > 0) {
      const sendData = {
        ...data,
        user_ids: sendLeads,
      };
      await createNotification(sendData);
    } else {
      toast.error("You have to select at least one user");
    }
    try {
    } catch (error: any) {}
  };
  console.log("sendLeads", sendLeads);
  return (
    <Bigsection>
      <Grid container columns={12}>
        <Grid item xs={12}>
          <TextField
            type="string"
            onChange={handlChange}
            label="Search by user"
          ></TextField>
          <table id="customers">
            <tr>
              <th>select</th>
              <th>first name</th>
              <th>last name</th>
              <th>email</th>
              <th>phone</th>
            </tr>
            {mapping()}
          </table>
        </Grid>
        <Grid item xs={12}>
          <div className="paginate">
            <Pagination
              page={page}
              count={totalPages}
              onChange={handleChangePage}
              color="primary"
            />
          </div>
        </Grid>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid container columns={12} spacing={3}>
              <Grid item xs={12} style={{}}>
                <h4>Add Category</h4>
              </Grid>

              <Grid item xs={12}>
                <StanderInput
                  // required
                  name="title"
                  placeholder="title"
                  Label="title"
                  type="string"
                  defaultValue=""
                ></StanderInput>
              </Grid>
              <Grid item xs={12}>
                <StanderInput
                  // required
                  name="hint"
                  placeholder="hint"
                  Label="hint"
                  type="string"
                  defaultValue=""
                ></StanderInput>
              </Grid>
              <Grid item xs={12}>
                <StanderInput
                  // required
                  name="description"
                  placeholder="description"
                  Label="description"
                  type="string"
                  defaultValue=""
                ></StanderInput>
              </Grid>

              <Grid item xs={12}>
                <SubmitButton type="submit" fullWidth>
                  {isLoading ? "creating" : "Save"}
                </SubmitButton>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Grid>
    </Bigsection>
  );
};

export default Nontification;
