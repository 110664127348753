import React, { useEffect } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Grid, TextField } from "@mui/material";
import { toast } from "react-toastify";
import { useCreateColoringActivitesMutation } from "../../../../../store/RTK/coloringActivities";
import SelectInput from "../../../../../components/common/SelectInput/SelectInput";
import StanderInput from "../../../../../components/common/standerInput";
import StanderTextArea from "../../../../../components/common/standardTextArea";
import { SubmitButton } from "../../../../../components/common/Buttons/SubmitButton";

const SUPPORTED_FORMATS = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/webP ",
];

const CreateActivity = () => {
  const [createActivity, { isLoading }] = useCreateColoringActivitesMutation();

  const schema = yup.object({
    title_en: yup.string().required("title_en is required"),
    title_ar: yup.string().required("title_ar is required"),
    description_en: yup.string().required("description_en is required"),
    description_ar: yup.string().required("description_ar is required"),
    activate_cover: yup
      .mixed()
      .required("A file is required")
      .test("fileType", "Unsupported file format", (value: any) => {
        if (!value) return true; // If no file is provided, don't perform validation
        return SUPPORTED_FORMATS.includes(value.type);
      }),
    activate_pdf: yup
      .mixed()
      .required("A file is required")
      .test(
        "fileType",
        "Invalid file format, only PDF allowed",
        (value: any) => {
          if (!value) return false; // No file provided
          return value && value.type === "application/pdf";
        }
      ),
  });

  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: any) => {
    console.log("data", data);
    try {
      const formData = new FormData();
      for (const property in data) {
        // console.log(`${property}: ${data[property]}`);
        formData.append(`${property}`, data[property]);
      }
      await createActivity(formData).unwrap();

      toast.success("Done");
    } catch (e) {
      toast.error("something wrong");
    }
  };
  // let accountTypes = data?.data?.account_type || [];
  // let currency = data?.data?.currency || [];

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container columns={12} spacing={3}>
            <Grid item xs={12}>
              <StanderInput
                name="title_en"
                placeholder="title_en"
                Label="title_en"
                type="string"
                defaultValue=""
              ></StanderInput>
            </Grid>
            <Grid item xs={12}>
              <StanderInput
                name="title_ar"
                placeholder="title_ar"
                Label="title_ar"
                type="string"
                defaultValue=""
              ></StanderInput>
            </Grid>
            <Grid item xs={12}>
              <StanderTextArea
                name="description_en"
                placeholder="description_en"
                Label="description_en"
                type="string"
                defaultValue=""
              ></StanderTextArea>
            </Grid>
            <Grid item xs={12}>
              <StanderTextArea
                name="description_ar"
                placeholder="description_ar"
                Label="description_ar"
                type="string"
                defaultValue=""
              ></StanderTextArea>
            </Grid>

            <Grid item xs={12}>
              <label htmlFor="">activate cover</label>
              <Controller
                control={methods.control}
                name={"activate_cover"}
                render={({ field: { value, onChange, ...field } }) => {
                  return (
                    <TextField
                      {...field}
                      value={value?.fileName}
                      onChange={(event: any) => {
                        onChange(event.target.files[0]);
                      }}
                      type="file"
                      id="picture"
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <label htmlFor="">activate_pdf</label>
              <Controller
                control={methods.control}
                name={"activate_pdf"}
                render={({ field: { value, onChange, ...field } }) => {
                  return (
                    <TextField
                      {...field}
                      value={value?.fileName}
                      onChange={(event: any) => {
                        onChange(event.target.files[0]);
                      }}
                      type="file"
                      id="picture"
                    />
                  );
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <SubmitButton type="submit" fullWidth>
                {isLoading ? "creating" : "Save"}
              </SubmitButton>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </div>
  );
};

export default CreateActivity;
