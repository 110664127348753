import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../helper/BaseQuery";

export const DeliveryFeesHelpDataApi = createApi({
  reducerPath: "DeliveryFeesHelpDataApi",
  baseQuery,
  tagTypes: ["Countries", "States", "Cities"],
  endpoints: (builder) => ({
    geCountries: builder.query({
      query: (search) => ({
        url: "delivery-fees/index-countries",
        method: "get",
        params: {
          search,
        },
      }),
      providesTags: ["Countries"],
    }),

    getStatesByCountryId: builder.query({
      query: ({ country_id, search }) => ({
        url: `/delivery-fees/index-states`,
        method: "get",
        params: {
          country_id,
          search,
        },
      }),
      providesTags: ["States"],
    }),

    getCitiesByStateId: builder.query({
      query: ({ state_id, search }) => ({
        url: `/delivery-fees/index-cities`,
        method: "get",
        params: {
          state_id,
          search,
        },
      }),
      providesTags: ["Cities"],
    }),

    // getGouvernmentByCityId: builder.query({
    //   query: ({ city_id, search }: any) => ({
    //     url: `/delivery-fees/index-governments`,
    //     method: "get",
    //     params: {
    //       city_id,
    //       search,
    //     },
    //     providesTags: ["Governments"],
    //     // providesTags: (result: any, error: any, { city_id }: any) => [
    //     //   { type: "Governments", id: city_id },
    //     // ],
    //   }),
    // }),

    // storeGouvernment: builder.mutation({
    //   query: (body) => ({
    //     url: `/delivery-fees/store-government`,
    //     method: "post",
    //     body,
    //   }),
    //   invalidatesTags: ["Governments"],
    // }),

    // updateGouvernment: builder.mutation<any, any>({
    //   query: ({ body, id }) => ({
    //     url: `delivery-fees/update-government/${id}`,
    //     method: "POST",
    //     body,
    //   }),
    //   invalidatesTags: ["Governments"],
    // }),

    // deleteGouvernment: builder.mutation<any, any>({
    //   query: (id) => ({
    //     url: `delivery-fees/delete-government/${id}`,
    //     method: "DELETE",
    //   }),
    //   invalidatesTags: ["Governments"],
    // }),
  }),
});

export const {
  useGeCountriesQuery,
  useGetStatesByCountryIdQuery,
  useGetCitiesByStateIdQuery,
  //   useGetGouvernmentByCityIdQuery,
  //   useStoreGouvernmentMutation,
  //   useUpdateGouvernmentMutation,
  //   useDeleteGouvernmentMutation,
  //   useLazyGetGouvernmentByCityIdQuery,
} = DeliveryFeesHelpDataApi;
