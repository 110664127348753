import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { dataType } from "../showData";
import { useDeleteFeedBackMutation } from "../../../../../store/RTK/feedBack";
import { toast } from "react-toastify";
import { Bigsection } from "./style";
import { SubmitButton } from "../../../../../components/common/Buttons/SubmitButton";
import { DeleteButton } from "../../../../../components/common/Buttons/deleteButton";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
type props = {
  open: boolean;
  handleClose: any;
  singleFeed: dataType;
};
const DeleteFeedBack = ({ open, handleClose, singleFeed }: props) => {
  const [deleteFeedBack, { isLoading, isSuccess }] =
    useDeleteFeedBackMutation();
  const deleteFeed = async () => {
    try {
      await deleteFeedBack(singleFeed?.id);
      toast.success("has been deleted");
      handleClose();
    } catch (e) {
      toast.error("Error");
    }
  };
  return (
    <Bigsection>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure you want to delete this feedBack ({singleFeed?.name})
          </Typography>
          {/* <Typography
            id="modal-modal-description"
            sx={{ mt: 2, textAlign: "center" }}
          > */}
          <div
            className="btns"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              justifyContent: "center",
              padding: "1rem 0",
            }}
          >
            <DeleteButton onClick={deleteFeed}>
              {isLoading ? "Deleting..." : "Delete"}
            </DeleteButton>
            <SubmitButton className="btn" onClick={handleClose}>
              No
            </SubmitButton>
          </div>
          {/* </Typography> */}
        </Box>
      </Modal>
    </Bigsection>
  );
};

export default DeleteFeedBack;
