import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../helper/BaseQuery";

export const faqApi = createApi({
  reducerPath: "faqApi",
  baseQuery,
  tagTypes: ["Faqs", "categoryUnity", "categoryUnityQuestion", "Answers"],
  endpoints: (builder) => ({
    getFaqCategory: builder.query({
      query: () => ({
        url: "/faq/index-faq-category",
        method: "get",
      }),
      providesTags: ["Faqs"],
    }),
    createFaqCategory: builder.mutation<any, any>({
      query: (body) => ({
        url: "/faq/store-faq-category",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Faqs"],
    }),
    deleteFaqCategory: builder.mutation<any, any>({
      query: (id) => ({
        url: `faq/delete-faq-category/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Faqs"],
    }),
    updateFaqCategoryIcon: builder.mutation<any, any>({
      query: ({ body, id }) => ({
        url: `faq/upload-faq-category-icon/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Faqs"],
    }),
    getFaq: builder.query({
      query: ({ id }) => ({
        url: `/faq/${id}`,
        method: "get",
      }),
      providesTags: ["Answers"],
    }),
    createFaq: builder.mutation<any, any>({
      query: (body) => ({
        url: "faq/store",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Answers"],
    }),
    deleteFaq: builder.mutation<any, any>({
      query: (id) => ({
        url: `faq/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Answers"],
    }),

    ///////////////////////////////////////
    ////////////* CategoryUnity*//////////
    /////////////////////////////////////
    createFaqCategoryUnity: builder.mutation<any, any>({
      query: (body) => ({
        url: "/questions/store-category",
        method: "POST",
        body,
      }),
      invalidatesTags: ["categoryUnity"],
    }),
    getCategoryUnity: builder.query({
      query: () => ({
        url: "/questions/index-categories",
        method: "get",
      }),
      providesTags: ["categoryUnity"],
    }),
    getSingleCategoryUnity: builder.query({
      query: ({ id }) => ({
        url: `/questions/show-categoryUnity/${id}`,
        method: "get",
      }),
      providesTags: ["categoryUnity"],
    }),
    updateCategoryUnity: builder.mutation<any, any>({
      query: ({ body, id }) => ({
        url: `/questions/update-categoryUnity/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["categoryUnity"],
    }),
    deleteCategoryUnity: builder.mutation<any, any>({
      query: (id) => ({
        url: `questions/delete-category/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["categoryUnity"],
    }),
    ///////////////////////////////////////
    ///////* Categories questions*////////
    /////////////////////////////////////

    createCategoryUnityQuestion: builder.mutation<any, any>({
      query: (body) => ({
        url: `/questions/store`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["categoryUnityQuestion"],
    }),
    getCategoryUnityQuestion: builder.query({
      query: (id) => ({
        url: `/questions/index/${id}`,
        method: "get",
      }),
      providesTags: ["categoryUnityQuestion"],
    }),
    getSingleCategoryUnityQuestion: builder.query({
      query: (id) => ({
        url: `/questions/show/${id}`,
        method: "get",
      }),
      providesTags: ["categoryUnityQuestion", "Answers"],
    }),
    updateCategoryUnityQuestion: builder.mutation<any, any>({
      query: ({ body, id }) => ({
        url: `/questions/update-question/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["categoryUnityQuestion"],
    }),

    deleteCategoryUnityQuestion: builder.mutation<any, any>({
      query: (id) => ({
        url: `questions/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["categoryUnityQuestion"],
    }),
    ///////////////////////////////////////
    ///////* Categories questions*////////
    /////////////////////////////////////
    updateCategoryUnityAnswer: builder.mutation<any, any>({
      query: ({ body, id }) => ({
        url: `/questions/update-answer/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Answers"],
    }),
    updateCategoryUnityAnswerMedia: builder.mutation<any, any>({
      query: (body) => ({
        url: `/questions/update-answer-media`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Answers"],
    }),
  }),
});

export const {
  useGetFaqQuery,
  useCreateFaqMutation,
  useDeleteFaqMutation,
  useGetCategoryUnityQuery,
  useGetSingleCategoryUnityQuery,
  useUpdateCategoryUnityMutation,
  useDeleteCategoryUnityMutation,
  useGetFaqCategoryQuery,
  useCreateFaqCategoryMutation,
  useDeleteFaqCategoryMutation,
  useCreateFaqCategoryUnityMutation,
  useGetCategoryUnityQuestionQuery,
  useCreateCategoryUnityQuestionMutation,
  useUpdateCategoryUnityQuestionMutation,
  useDeleteCategoryUnityQuestionMutation,
  useGetSingleCategoryUnityQuestionQuery,
  useUpdateCategoryUnityAnswerMutation,
  useUpdateCategoryUnityAnswerMediaMutation,
  useUpdateFaqCategoryIconMutation,
} = faqApi;
