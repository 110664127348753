import React from "react";
import { useGetSinglealletRequuestQuery } from "../../../../../store/RTK/wallet";
import { useNavigate, useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { NoButton } from "../../../../../components/common/Buttons/deleteButton";

const SingleWallet = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, isLoading, isSuccess } = useGetSinglealletRequuestQuery(id);
  console.log("dataaaaaaaaaaaaaaaaaaaaaaaaa", data?.data);
  const singlePay = data?.data;
  return (
    <div>
      <Grid columns={12}>
        <Grid item xs={12}>
          <NoButton
            onClick={() => {
              navigate(-1);
            }}
          >
            Go back
          </NoButton>
        </Grid>
      </Grid>
    </div>
  );
};

export default SingleWallet;
