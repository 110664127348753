import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../helper/BaseQuery";

export const blogsApi = createApi({
  reducerPath: "blogsApi",
  baseQuery,
  tagTypes: ["blogs", "singleBlog"],
  endpoints: (builder) => ({
    getBlogs: builder.query({
      query: () => ({
        url: "/blogs",
        method: "get",
      }),
      providesTags: ["blogs"],
    }),
    getSingleBlog: builder.query({
      query: (id) => ({
        url: `/blogs/show/${id}`,
        method: "get",
      }),
      providesTags: ["singleBlog"],
    }),
    createBlog: builder.mutation<any, any>({
      query: (body) => ({
        url: "/blogs/store",
        method: "POST",
        body,
      }),
      invalidatesTags: ["blogs"],
    }),
    updateBlog: builder.mutation<any, any>({
      query: ({ body, id }) => ({
        url: `/blogs/update/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["blogs"],
    }),
    deleteBlog: builder.mutation<any, any>({
      query: (id) => ({
        url: `/blogs/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["blogs"],
    }),
    updateBlogMedia: builder.mutation<any, any>({
      query: ({ body, id }) => ({
        url: `blogs/update-media/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["blogs"],
    }),

    // Descriptions
    getDescription: builder.query({
      query: (id) => ({
        url: `/blogs/show-description/${id}`,
        method: "get",
      }),
      providesTags: ["singleBlog"],
    }),
    createDescription: builder.mutation<any, any>({
      query: (body) => ({
        url: "/blogs/add-description",
        method: "POST",
        body,
      }),
      invalidatesTags: ["singleBlog"],
    }),
    updateDescription: builder.mutation({
      query: ({ id, body }) => ({
        url: `blogs/update-description/${id}`,
        method: "put",
        body,
      }),
      invalidatesTags: ["blogs"],
    }),
    deleteDescription: builder.mutation<any, any>({
      query: (id) => ({
        url: `/blogs/delete-description/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["singleBlog", "blogs"],
    }),
  }),
});

export const {
  useGetBlogsQuery,
  useCreateBlogMutation,
  useDeleteBlogMutation,
  useCreateDescriptionMutation,
  useDeleteDescriptionMutation,
  useUpdateDescriptionMutation,
  useGetDescriptionQuery,
  useGetSingleBlogQuery,
  useUpdateBlogMediaMutation,
  useUpdateBlogMutation,
} = blogsApi;
