import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Modal } from "@mui/material";
import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import StanderInput from "../../../../components/common/standerInput";
import {
  useCreateDescriptionMutation,
  useUpdateDescriptionMutation,
} from "../../../../store/RTK/blogs";
import { SubmitButton } from "../../../../components/common/Buttons/SubmitButton";
import { toast } from "react-toastify";
import StanderTextArea from "../../../../components/common/standardTextArea";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AddSingleDescription = ({
  open,
  handleOpen,
  handleClose,
  singleBlog,
}: any) => {
  const schema = yup.object({
    title_en: yup.string().required("title_en is required"),
    title_ar: yup.string().required("title_ar is required"),
    body_en: yup.string().required("body_en is required"),
    body_ar: yup.string().required("body_ar is required"),
  });

  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const [createDescription, { isLoading, isSuccess, isError }] =
    useCreateDescriptionMutation();

  const onSubmit = async (data: any) => {
    try {
      const sendData = {
        title_en: data.title_en,
        title_ar: data.title_ar,
        body_en: data.body_en,
        body_ar: data.body_ar,
        blog_id: singleBlog,
      };
      console.log("sendData", sendData);
      await createDescription({ ...sendData });
    } catch (error: any) {}
  };
  useEffect(() => {
    if (isSuccess) {
      toast.success("Doneeeeeeeeeeeee");
      handleClose();
    }
    if (isError) {
      toast.error("Errrrrrr");
    }
  }, [isSuccess, isError]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Grid container columns={12} spacing={3}>
                <Grid item xs={12} style={{}}>
                  <h4>Add Description</h4>
                </Grid>
                <Grid item xs={12}>
                  <StanderInput
                    name="title_en"
                    placeholder="title_en"
                    Label="title_en"
                    type="string"
                    defaultValue=""
                  ></StanderInput>
                </Grid>
                <Grid item xs={12}>
                  <StanderInput
                    name="title_ar"
                    placeholder="title_ar"
                    Label="title_ar"
                    type="string"
                    defaultValue=""
                  ></StanderInput>
                </Grid>
                <Grid item xs={12}>
                  <StanderTextArea
                    name="body_en"
                    placeholder="body_en"
                    Label="body_en"
                    type="string"
                    defaultValue=""
                  ></StanderTextArea>
                </Grid>
                <Grid item xs={12}>
                  <StanderTextArea
                    name="body_ar"
                    placeholder="body_ar"
                    Label="body_ar"
                    type="string"
                    defaultValue=""
                  ></StanderTextArea>
                </Grid>
                <Grid item xs={12}>
                  <SubmitButton type="submit" fullWidth>
                    {isLoading ? "creating" : "Save"}
                  </SubmitButton>{" "}
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Box>
      </Modal>
    </div>
  );
};

export default AddSingleDescription;
