import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Modal, TextField, Typography } from "@mui/material";
import React from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import {
  useAddTargetMutation,
  useUpdateTargetMutation,
} from "../../../../../../store/RTK/category";
import { toast } from "react-toastify";
import StanderTextArea from "../../../../../../components/common/standardTextArea";
import { SubmitButton } from "../../../../../../components/common/Buttons/SubmitButton";
import StanderInput from "../../../../../../components/common/standerInput";
import { StadardSelect } from "../../../../../../components/common/standardSelect";
import SelectInput from "../../../../../../components/common/SelectInput/SelectInput";
import { Bigsection } from "../../../../satisfiedCustomers/components/delete/style";

const SUPPORTED_FORMATS = ["image/png", "image/jpeg", "image/jpg"];
type props = {
  open: boolean;
  handleClose: any;
  singleItem: any;
};
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const UpdateTarget = ({ open, handleClose, singleItem }: props) => {
  const id = singleItem?.id;
  const [updateTarget, { isLoading, isSuccess }] = useUpdateTargetMutation();

  const schema = yup.object({
    title: yup.string().required("title is required"),
    is_free: yup.string().required("is free is required"),
    image: yup
      .mixed()
      // .required("A file is required")
      .test("fileType", "Unsupported file format", (value: any) => {
        if (!value) return true;
        return SUPPORTED_FORMATS.includes(value.type);
      }),
    sound_pdf: yup
      .mixed()
      // .required("Sound pdf is required")
      // .test(
      //   "fileType",
      //   "Invalid file format, only PDF allowed",
      //   (value: any) => {
      //     if (value === null || value === undefined) return true; // Allow nullable or undefined
      //     return value && value.type === "application/pdf";
      //   }
      // )
      .nullable(),
  });
  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  const control = methods.control;
  const validationErr: any = methods.formState.errors;

  const dropDown = [
    { name: "Yes", id: "1" },
    { name: "No", id: "0" },
  ];
  const onSubmit = async (data: any) => {
    console.log("data", data);

    try {
      const formData = new FormData();
      // formData.append("title", data.title);
      // if (data.image !== null) {
      //   formData.append("image", data.image ? data.image : null);
      // }
      // if (data.sound_pdf !== null) {
      //   formData.append("sound_pdf", data.sound_pdf ? data.sound_pdf : null);
      // }
      // formData.append("is_free", data.is_free);
      for (const property in data) {
        formData.append(property, data[property]);
      }
      formData.append("_method", "PUT");

      const sendData = {
        id: id,
        data: formData,
      };
      await updateTarget(sendData).unwrap();
      await toast.success("Updated");
      await handleClose();
    } catch (error: any) {
      await toast.error("something went wrong");
    }
  };

  console.log("singleItem", singleItem);
  console.log("What", methods.formState.errors);
  return (
    <Bigsection>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            textAlign={"center"}
          >
            Are you sure you want to update this Target
          </Typography>
          <div>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Grid container columns={12} spacing={3}>
                  <Grid item xs={12} style={{}}>
                    <h4>Add Category</h4>
                  </Grid>
                  <Grid item xs={12}>
                    <StanderInput
                      // required
                      name="title"
                      placeholder="title"
                      Label="title"
                      type="string"
                      defaultValue={singleItem?.title}
                    ></StanderInput>
                  </Grid>
                  <Grid item xs={12}>
                    <SelectInput
                      name="is_free"
                      placeholder="is_free"
                      label="Is free"
                      data={dropDown}
                      defaultValue={singleItem?.is_free === 1 ? 1 : "0"}
                    ></SelectInput>
                  </Grid>
                  <Grid item xs={12}>
                    <label htmlFor="">category image</label>
                    <Controller
                      control={control}
                      name={"image"}
                      defaultValue={singleItem?.image}
                      render={({ field: { value, onChange, ...field } }) => {
                        return (
                          <TextField
                            {...field}
                            value={value?.fileName}
                            onChange={(event: any) => {
                              onChange(event.target.files[0]);
                            }}
                            type="file"
                            id="picture"
                          />
                        );
                      }}
                    />
                    <p className="err">
                      {validationErr?.category_image
                        ? validationErr?.category_image.message
                        : ""}
                    </p>
                  </Grid>
                  <Grid item xs={12}>
                    <label htmlFor="">Sound pdf</label>
                    <Controller
                      control={control}
                      name={"sound_pdf"}
                      render={({ field: { value, onChange, ...field } }) => {
                        return (
                          <TextField
                            {...field}
                            value={value?.fileName}
                            onChange={(event: any) => {
                              onChange(event.target.files[0]);
                            }}
                            type="file"
                            id="picture"
                          />
                        );
                      }}
                    />
                    <p className="err">
                      {validationErr?.sound_pdf
                        ? validationErr?.sound_pdf.message
                        : ""}
                    </p>
                  </Grid>
                  <Grid item xs={12}>
                    <SubmitButton type="submit" fullWidth>
                      {isLoading ? "Updateing..." : "Update"}
                    </SubmitButton>
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </div>
        </Box>
      </Modal>
    </Bigsection>
  );
};

export default React.memo(UpdateTarget);
