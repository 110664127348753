import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Modal, TextField, Typography } from "@mui/material";
import React from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { useEditAccountTypeMediaMutation } from "../../../../../store/RTK/accountType";
import { toast } from "react-toastify";
import { SubmitButton } from "../../../../../components/common/Buttons/SubmitButton";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
type props = {
  open?: boolean | any;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selected: any;
};
const EditAccountTypeImage = ({ open, setOpen, selected }: props) => {
  console.log("selected", selected);
  const handleClose = () => setOpen(false);
  const SUPPORTED_FORMATS = ["image/png", "image/jpeg", "image/jpg"];
  const schema = yup.object({
    media: yup
      .mixed()
      .required("A file is required")
      .test("fileType", "Unsupported file format", (value: any) => {
        if (!value) return true; // If no file is provided, don't perform validation
        return SUPPORTED_FORMATS.includes(value.type);
      }),
  });
  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      account_type_description: [{ account_type_description: "" }],
    },
  });
  const validationErr = methods.formState.errors;
  const control = methods.control;
  const [editAccountTypeMedia, { isLoading, isSuccess }] =
    useEditAccountTypeMediaMutation();
  const onSubmit = async (data: any) => {
    const formData = new FormData();
    console.log("Send data", data);
    formData.append("media", data.media);
    formData.append("_method", "put");
    try {
      const sendData = {
        id: selected.id,
        data: formData,
      };
      await editAccountTypeMedia(sendData);

      await methods.reset();
      if (isSuccess) {
        await toast.success("Account has been created");
        // setError([]);
      }
    } catch (error: any) {
      await toast.error("something went wrong");
    }
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edit this img
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Grid container columns={12} spacing={3}>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name={"media"}
                      render={({ field: { value, onChange, ...field } }) => {
                        return (
                          <TextField
                            {...field}
                            value={value?.fileName}
                            onChange={(event: any) => {
                              onChange(event.target.files[0]);
                            }}
                            type="file"
                            id="picture"
                          />
                        );
                      }}
                    />
                    <p style={{ color: "red", fontSize: "0.7rem" }}>
                      {" "}
                      {validationErr?.media ? (
                        <>{validationErr?.media.message}</>
                      ) : (
                        <></>
                      )}
                    </p>
                  </Grid>
                  <Grid item xs={12}>
                    <SubmitButton type="submit" fullWidth>
                      {isLoading ? "creating" : "Save"}
                    </SubmitButton>
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default EditAccountTypeImage;
