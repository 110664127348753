import React from "react";
import { Bigsection } from "../../../../satisfiedCustomers/components/delete/style";
import { toast } from "react-toastify";
import {
  useAddTargetMutation,
  useDeleteTargetMutation,
} from "../../../../../../store/RTK/category";
import { Box, Modal, Typography } from "@mui/material";
import { DeleteButton } from "../../../../../../components/common/Buttons/deleteButton";
import { SubmitButton } from "../../../../../../components/common/Buttons/SubmitButton";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

type props = {
  open: boolean;
  handleClose: any;
  singleItem: any;
};
const DeleteTarget = ({ open, handleClose, singleItem }: props) => {
  const id = singleItem?.id;
  const [deleteTarget, { isLoading, isSuccess }] = useDeleteTargetMutation();
  const deleteFeed = async () => {
    try {
      await deleteTarget(id);
      toast.success("target has been deleted");
      handleClose();
    } catch (e) {
      toast.error("Error");
    }
  };
  return (
    <Bigsection>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            textAlign={"center"}
          >
            Are you sure you want to delete this Target
          </Typography>
          {/* <Typography
        id="modal-modal-description"
        sx={{ mt: 2, textAlign: "center" }}
      > */}
          <div
            className="btns"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              justifyContent: "center",
              padding: "1rem 0",
            }}
          >
            <DeleteButton onClick={deleteFeed}>
              {isLoading ? "Deleting..." : "Delete"}
            </DeleteButton>
            <SubmitButton className="btn" onClick={handleClose}>
              No
            </SubmitButton>
          </div>
          {/* </Typography> */}
        </Box>
      </Modal>
    </Bigsection>
  );
};

export default DeleteTarget;
