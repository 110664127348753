import React from "react";
import { Bigsection } from "./style";
import { NavLink, Outlet } from "react-router-dom";

const SatisfiedCustomer = () => {
  return (
    <Bigsection>
      <nav>
        <ul>
          <NavLink to="show">
            <li>all feedback</li>
          </NavLink>
          <NavLink to="create">
            <li>create</li>
          </NavLink>
        </ul>
      </nav>
      <div>
        <Outlet />
      </div>
    </Bigsection>
  );
};

export default SatisfiedCustomer;
