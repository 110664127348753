import styled from "styled-components";

export const Bigsection = styled.div`
nav{
    ul{
        display: flex;
        justify-content: flex-start;
        gap: 2rem;
        padding: 2rem 0;
        li{
            all: unset;
        }
        a{
            width: auto;
        }
    }
}
`