import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  useEditAccountTypeMutation,
  useGetSingleAcountQuery,
} from "../../../../../store/RTK/accountType";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { Grid, TextField } from "@mui/material";
import StanderInput from "../../../../../components/common/standerInput";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitButton } from "../../../../../components/common/Buttons/SubmitButton";
import Errors from "../../../../../components/common/Errors";

const SUPPORTED_FORMATS = ["image/png", "image/jpeg", "image/jpg"];

const EditAccountType = () => {
  const params = useParams();
  const { id } = params;
  const {
    data,
    isLoading: isLoadingFetching,
    isError,
  } = useGetSingleAcountQuery(id, {
    refetchOnMountOrArgChange: true,
  });
  const singleAccountType = data?.data;
  const [cehck, setChecked] = React.useState(true);

  const schema = yup.object({
    title_en: yup.string().required("title_en is required"),
    title_ar: yup.string().required("title_ar is required"),
    account_type_descriptions: yup
      .array()
      .required("Array is required")
      .min(1, "Array must have at least 1 item")
      .of(
        yup.object().shape({
          content_en: yup.string().required("English description is required"),
          content_ar: yup.string().required("Arabic description is required"),
        })
      ),
  });

  const navigate = useNavigate();
  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  const control = methods.control;
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: "account_type_descriptions",
    }
  );
  const [editAccountType, { isLoading, isSuccess }] =
    useEditAccountTypeMutation();
  const [error, setError] = React.useState([]);
  useEffect(() => {
    const excute = () => {
      if (cehck) {
        if (fields.length > 0) {
          setChecked(false);
        }
        for (const property in singleAccountType) {
          methods.setValue(property, singleAccountType[property]);
        }
        if (singleAccountType?.account_type_description.length > 0) {
          append(singleAccountType?.account_type_description);
        }
      }
    };
    excute();
  }, [data?.data]);
  const onSubmit = async (data: any) => {
    new Set(data.account_type_descriptions);
    delete data?.account_type_description;
    const sendData = {
      id: id,
      data: data,
    };
    console.log("Data", data);
    try {
      await editAccountType(sendData).unwrap();
      await toast.success("Account has been edited");
      await navigate("/home/home-page/account-type/show");
      await setError([]);
    } catch (error: any) {
      await toast.error("something went wrong");
      await setError(error?.data?.errors);
    }
  };

  // const [addedIds, setAddedIds] = useState(new Set());
  // useLayoutEffect(() => {
  //   if (fields.length < 1) {
  //     setChecked(true);
  //   } else {
  //     setChecked(false);
  //   }
  // }, []);
  // useLayoutEffect(() => {
  //   const fetchInitialValues = () => {
  //     try {
  //       const data = singleAccountType?.account_type_description;

  //       data?.forEach((item: any) => {
  //         if (!addedIds.has(item.id)) {
  //           append(item);
  //           setAddedIds((prevIds) => new Set(prevIds.add(item.id)));
  //         }
  //       });
  //     } catch (error) {
  //       console.error("Error fetching initial values:", error);
  //     }
  //   };
  //   if (cehck) fetchInitialValues();
  // }, [append, addedIds, singleAccountType, cehck]);

  if (isError) {
    return <div>Errrrrrrrrrrrrr </div>;
  }
  console.log("singleAccountType", singleAccountType?.account_type_description);
  console.log("Fields", fields);
  console.log("cehck", cehck);
  console.log("Errr", methods?.formState?.errors);
  return (
    <div>
      {isLoadingFetching ? (
        <p>Loading...</p>
      ) : (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid container columns={12} spacing={3}>
              <Grid item xs={12} style={{}}>
                <h4>Edit Account type</h4>
              </Grid>
              <Grid item xs={12}>
                <StanderInput
                  name="title_en"
                  placeholder="title_en"
                  Label="title_en"
                  type="string"
                  defaultValue=""
                ></StanderInput>
              </Grid>
              <Grid item xs={12}>
                <StanderInput
                  name="title_ar"
                  placeholder="title_ar"
                  Label="title_ar"
                  type="string"
                  defaultValue=""
                ></StanderInput>
              </Grid>
              <Grid item xs={12}>
                <button
                  type="button"
                  onClick={() =>
                    append({
                      account_type_descriptions: {
                        content_en: "",
                        content_ar: "",
                      },
                    })
                  }
                >
                  add description
                </button>
                {fields.length > 0 ? (
                  <>
                    {fields.map((field, index) => (
                      <div key={field?.id}>
                        <StanderInput
                          key={field.id} // important to include key with field's id
                          name={`account_type_descriptions.[${index}][content_en]`}
                          placeholder="description EN"
                          Label="description EN"
                          type="string"
                          defaultValue=""
                        />
                        <StanderInput
                          key={field.id} // important to include key with field's id
                          name={`account_type_descriptions.[${index}][content_ar]`}
                          placeholder="description Ar"
                          Label="description Ar"
                          type="string"
                          defaultValue=""
                        />
                        <button type="button" onClick={() => remove(index)}>
                          Delete
                        </button>
                      </div>
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </Grid>
              <Grid item xs={12}>
                <SubmitButton type="submit" fullWidth>
                  {isLoading ? "creating" : "Save"}
                </SubmitButton>
              </Grid>
              <Grid item xs={12}>
                <Errors errors={error}></Errors>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      )}
    </div>
  );
};

export default EditAccountType;
