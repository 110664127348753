import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import * as yup from "yup";

import { toast } from "react-toastify";
import { Grid, TextField } from "@mui/material";
import StanderInput from "../../../../../components/common/standerInput";
import { SubmitButton } from "../../../../../components/common/Buttons/SubmitButton";
import Errors from "../../../../../components/common/Errors";
import { useCreateAccountTypeMutation } from "../../../../../store/RTK/accountType";

const CreateAccountType = () => {
  const SUPPORTED_FORMATS = ["image/png", "image/jpeg", "image/jpg"];
  const schema = yup.object({
    title_en: yup.string().required("title_en is required"),
    title_ar: yup.string().required("title_ar is required"),
    media: yup
      .mixed()
      .required("A file is required")
      .test("fileType", "Unsupported file format", (value: any) => {
        if (!value) return true; // If no file is provided, don't perform validation
        return SUPPORTED_FORMATS.includes(value.type);
      }),
    account_type_description: yup
      .array()
      .required("Array is required")
      .min(1, "Array must have at least 1 item")
      .max(5, "Array must have at most 5 items")
      .of(
        yup.object().shape({
          content_en: yup.string().required("English description is required"),
          content_ar: yup.string().required("Arabic description is required"),
          // Define other properties as needed
        })
      ),
  });
  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      account_type_description: [{ content_en: "", content_ar: "" }],
    },
  });
  const validationErr = methods.formState.errors;
  const control = methods.control;
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: "account_type_description",
    }
  );
  const [error, setError] = React.useState([]);
  const [createAccountType, { isSuccess, isLoading, isError, data }] =
    useCreateAccountTypeMutation();
  const onSubmit = async (data: any) => {
    const formData = new FormData();
    console.log("Send data", data);
    formData.append("title_en", data.title_en);
    formData.append("title_ar", data.title_ar);
    formData.append("media", data.media);
    data.account_type_description?.map((desc: any, i: number) => {
      formData.append(
        `account_type_description[${i}][content_en]`,
        desc.content_en
      );
      formData.append(
        `account_type_description[${i}][content_ar]`,
        desc.content_ar
      );
    });

    try {
      await createAccountType(formData).unwrap();
      toast.success("Account has been created");

      // await fetch(
      //   "https://octarback.adgrouptech.com/admin/subscription/store-subscription-plan",
      //   {
      //     method: "post",
      //     body: formData,
      //   }
      // );
      await methods.reset();
    } catch (error: any) {
      toast.error("something went wrong");
      setError(error?.data?.errors);
    }
  };
  console.log("Errrrs", validationErr);
  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container columns={12} spacing={3}>
            <Grid item xs={12} style={{}}>
              <h4>Add Account type</h4>
            </Grid>
            <Grid item xs={12}>
              <StanderInput
                name="title_en"
                placeholder="title_en"
                Label="title_en"
                type="string"
                defaultValue=""
              ></StanderInput>
            </Grid>
            <Grid item xs={12}>
              <StanderInput
                name="title_ar"
                placeholder="title_ar"
                Label="title_ar"
                type="string"
                defaultValue=""
              ></StanderInput>
            </Grid>
            <Grid item xs={12}>
              <button
                type="button"
                onClick={() => append({ account_type_description: "" })}
              >
                add description
              </button>
              {fields.map((field, index) => (
                <div key={field?.id}>
                  <StanderInput
                    key={field.id} // important to include key with field's id
                    name={`account_type_description.[${index}][content_en]`}
                    placeholder="description EN"
                    Label="description EN"
                    type="string"
                    defaultValue=""
                  />
                  <StanderInput
                    key={field.id} // important to include key with field's id
                    name={`account_type_description.[${index}][content_ar]`}
                    placeholder="description Ar"
                    Label="description Ar"
                    type="string"
                    defaultValue=""
                  />
                  <button type="button" onClick={() => remove(index)}>
                    Delete
                  </button>
                </div>
              ))}
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={"media"}
                render={({ field: { value, onChange, ...field } }) => {
                  return (
                    <TextField
                      {...field}
                      value={value?.fileName}
                      onChange={(event: any) => {
                        onChange(event.target.files[0]);
                      }}
                      type="file"
                      id="picture"
                    />
                  );
                }}
              />
              <p style={{ color: "red", fontSize: "0.7rem" }}>
                {" "}
                {validationErr?.media ? (
                  <>{validationErr?.media.message}</>
                ) : (
                  <></>
                )}
              </p>
            </Grid>
            <Grid item xs={12}>
              <SubmitButton type="submit" fullWidth>
                {isLoading ? "creating" : "Save"}
              </SubmitButton>
            </Grid>
            <Grid item xs={12}>
              <Errors errors={error}></Errors>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </div>
  );
};

export default CreateAccountType;
