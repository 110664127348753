import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, TextField } from "@mui/material";
import React from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useAddTargetMutation } from "../../../../../../store/RTK/category";
import { toast } from "react-toastify";
import StanderTextArea from "../../../../../../components/common/standardTextArea";
import { SubmitButton } from "../../../../../../components/common/Buttons/SubmitButton";
import StanderInput from "../../../../../../components/common/standerInput";
import { StadardSelect } from "../../../../../../components/common/standardSelect";
import SelectInput from "../../../../../../components/common/SelectInput/SelectInput";
const SUPPORTED_FORMATS = ["image/png", "image/jpeg", "image/jpg"];

const CreateTarget = () => {
  const schema = yup.object({
    title: yup.string().required("title is required"),
    is_free: yup.string().required("is free is required"),
    image: yup
      .mixed()
      .required("A file is required")
      .test("fileType", "Unsupported file format", (value: any) => {
        if (!value) return true;
        return SUPPORTED_FORMATS.includes(value.type);
      }),
    sound_pdf: yup.mixed().required("Sound pdf is required"),
    // .test(
    //   "fileType",
    //   "Invalid file format, only PDF allowed",
    //   (value: any) => {
    //     if (!value) return false; // No file provided
    //     return value && value.type === "application/pdf";
    //   }
    // ),
  });
  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  const control = methods.control;
  const validationErr: any = methods.formState.errors;
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.pathname.split("/")[4];
  const goBack = () => {
    navigate(-1);
  };
  const dropDown = [
    { name: "Yes", id: "1" },
    { name: "No", id: "0" },
  ];
  const [createTarget, { isLoading, isSuccess }] = useAddTargetMutation();
  const onSubmit = async (data: any) => {
    console.log("Sned data", data);

    try {
      const formData = new FormData();
      formData.append("title", data.title);
      formData.append("category_id", id);
      formData.append("image", data.image);
      formData.append("is_free", data.is_free);
      formData.append("sound_pdf", data.sound_pdf);
      await createTarget(formData);
    } catch (error: any) {
      toast.error("something went wrong");
    }
  };
  React.useEffect(() => {
    let fun = async () => {
      if (isSuccess) {
        await toast.success("Category has been created");
        await navigate(`/home/cetegory/singleCategory/${id}`);
      }
    };
    fun();
  }, [isSuccess]);
  return (
    <div>
      <nav>
        <ul>
          <li onClick={goBack}>Back to single Category</li>
        </ul>
      </nav>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container columns={12} spacing={3}>
            <Grid item xs={12} style={{}}>
              <h4>Add Category</h4>
            </Grid>
            <Grid item xs={12}>
              <StanderInput
                // required
                name="title"
                placeholder="title"
                Label="title"
                type="string"
                defaultValue=""
              ></StanderInput>
            </Grid>
            <Grid item xs={12}>
              <SelectInput
                name="is_free"
                placeholder="is_free"
                label="Is free"
                data={dropDown}
              ></SelectInput>
            </Grid>
            <Grid item xs={12}>
              <label htmlFor="">category image</label>
              <Controller
                control={control}
                name={"image"}
                render={({ field: { value, onChange, ...field } }) => {
                  return (
                    <TextField
                      {...field}
                      value={value?.fileName}
                      onChange={(event: any) => {
                        onChange(event.target.files[0]);
                      }}
                      type="file"
                      id="picture"
                    />
                  );
                }}
              />
              <p className="err">
                {validationErr?.category_image
                  ? validationErr?.category_image.message
                  : ""}
              </p>
            </Grid>
            <Grid item xs={12}>
              <label htmlFor="">Sound pdf</label>
              <Controller
                control={control}
                name={"sound_pdf"}
                render={({ field: { value, onChange, ...field } }) => {
                  return (
                    <TextField
                      {...field}
                      value={value?.fileName}
                      onChange={(event: any) => {
                        onChange(event.target.files[0]);
                      }}
                      type="file"
                      id="picture"
                    />
                  );
                }}
              />
              <p className="err">
                {validationErr?.sound_pdf
                  ? validationErr?.sound_pdf.message
                  : ""}
              </p>
            </Grid>

            <Grid item xs={12}>
              <SubmitButton type="submit" fullWidth>
                {isLoading ? "creating" : "Save"}
              </SubmitButton>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </div>
  );
};

export default CreateTarget;
