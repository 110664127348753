import React from "react";
import { Bigsection } from "../satisfiedCustomers/style";
import { NavLink, Outlet } from "react-router-dom";

const Blogs = () => {
  return (
    <Bigsection>
      <nav>
        <ul>
          <NavLink to="showallBlogs">
            <li>All Blogs</li>
          </NavLink>
          <NavLink to="create">
            <li>Create</li>
          </NavLink>
        </ul>
      </nav>
      <div>
        <Outlet />
      </div>
    </Bigsection>
  );
};

export default Blogs;
