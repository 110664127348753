import React, { ReactNode } from "react";
import { useGetFeedBackQuery } from "../../../../../store/RTK/feedBack";
import { Bigsection } from "./style";
import DeleteFeedBack from "../delete";
import { DeleteButton } from "../../../../../components/common/Buttons/deleteButton";
export type dataType = {
  rate: string;
  name: string;
  title: String;
  content_en: string;
  content_ar: string;
  id: any;
};
const ShowData = () => {
  const { data, isFetching, isLoading } = useGetFeedBackQuery({});
  console.log("data", data);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selected, setSelected] = React.useState<any>();
  const select = (feed: dataType) => {
    setSelected(feed);
    handleOpen();
  };
  const mapping: any = () => {
    return data?.data.map((e: dataType) => {
      return (
        <tr key={e?.id}>
          <td>{e?.name}</td>
          <td>{e?.title}</td>
          <td className="content">
            <div>{e?.content_ar}</div>
          </td>
          <td className="content">
            <div>{e?.content_en}</div>
          </td>
          <td>{e?.rate}</td>
          <td>
            <DeleteButton onClick={() => select(e)}>Delete</DeleteButton>
          </td>
        </tr>
      );
    });
  };
  return (
    <Bigsection>
      {isFetching || isLoading ? (
        <h1>Loading</h1>
      ) : (
        <table>
          <tr>
            <th>Name</th>
            <th>Title</th>
            <th>Content Ar</th>
            <th>Content En</th>
            <th>rate</th>
            <th>Actions</th>
          </tr>
          {mapping()}
        </table>
      )}

      <DeleteFeedBack
        open={open}
        handleClose={handleClose}
        singleFeed={selected}
      />
    </Bigsection>
  );
};

export default ShowData;
