import React from "react";
import {
  useAddAssetBundleMutation,
  useUpdateCategoryMediaMutation,
} from "../../../../store/RTK/category";
import { Bigsection } from "../../satisfiedCustomers/components/delete/style";
import { Box, Grid, Modal, TextField, Typography } from "@mui/material";
import { SubmitButton } from "../../../../components/common/Buttons/SubmitButton";
import { Controller, FormProvider, useForm } from "react-hook-form";
import StanderInput from "../../../../components/common/standerInput";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

type props = {
  open: boolean;
  handleClose: any;
  singleItem: any;
};

const SUPPORTED_FORMATS = ["image/png", "image/jpeg", "image/jpg"];

const UpdateCategoryImage = ({ open, handleClose, singleItem }: props) => {
  const schema = yup.object({
    media: yup
      .mixed()
      .required("A file is required")
      .test("fileType", "Unsupported file format", (value: any) => {
        if (!value) return true; // If no file is provided, don't perform validation
        return SUPPORTED_FORMATS.includes(value.type);
      }),
  });
  const [updateCategoryMedia, { isLoading, isSuccess, isError }] =
    useUpdateCategoryMediaMutation();
  const id = singleItem?.id;
  console.log("singleItemAssetBundle", singleItem);
  console.log("id", id);
  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  const validationErr: any = methods.formState.errors;

  const control = methods.control;
  const onSubmit = async (data: any) => {
    try {
      const formData = new FormData();
      formData.append("media", data.media);
      formData.append("_method", "put");
      const sendData = {
        id: singleItem.id,
        data: formData,
      };
      await updateCategoryMedia(sendData);
    } catch (error: any) {}
  };
  React.useEffect(() => {
    if (isSuccess) {
      toast.success("Cover has been Edited");
    }
    if (!isSuccess && isError) {
      toast.error("somthing wrong");
    }
  }, [isSuccess, isError]);
  return (
    <Bigsection>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            textAlign={"center"}
          >
            Update category cover
          </Typography>

          <div
            className="btns"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              justifyContent: "center",
              padding: "1rem 0",
            }}
          >
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Grid container columns={12} spacing={3}>
                  <Grid item xs={12} style={{}}></Grid>

                  <Grid item xs={12}>
                    <label htmlFor="">category image</label>
                    <Controller
                      control={control}
                      name={"media"}
                      render={({ field: { value, onChange, ...field } }) => {
                        return (
                          <TextField
                            {...field}
                            value={value?.fileName}
                            onChange={(event: any) => {
                              onChange(event.target.files[0]);
                            }}
                            type="file"
                            id="picture"
                          />
                        );
                      }}
                    />
                    <p className="err">
                      {validationErr?.asset_bundle
                        ? validationErr?.asset_bundle.message
                        : ""}
                    </p>
                  </Grid>
                  <Grid item xs={12}>
                    <SubmitButton type="submit" fullWidth>
                      {isLoading ? "creating" : "Save"}
                    </SubmitButton>
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </div>
        </Box>
      </Modal>
    </Bigsection>
  );
};

export default React.memo(UpdateCategoryImage);
