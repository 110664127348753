import { Box, Modal, Typography } from "@mui/material";
import React, { useEffect, useLayoutEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { toast } from "react-toastify";
import { Grid, TextField } from "@mui/material";
import { SubmitButton } from "../../../../../components/common/Buttons/SubmitButton";
import SelectInput from "../../../../../components/common/SelectInput/SelectInput";
import StanderInput from "../../../../../components/common/standerInput";
import StanderTextArea from "../../../../../components/common/standardTextArea";
import { useUpdateActivityMutation } from "../../../../../store/RTK/coloringActivities";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
  height: "80%",
  display: "block",
};
const SUPPORTED_FORMATS = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/webP ",
];

const Edit = ({ open, handleClose, singleItem }: any) => {
  const [updateActivity, { isLoading }] = useUpdateActivityMutation();

  const schema = yup.object({
    title_ar: yup.string().required("title_ar is required"),
    title_en: yup.string().required("title_en is required"),
    description_ar: yup.string().required("description_ar is required"),
    description_en: yup.string().required("description_en is required"),
    activate_cover: yup
      .mixed()
      //   .required("A file is required")
      .test("fileType", "Unsupported file format", (value: any) => {
        if (!value) return true; // If no file is provided, don't perform validation
        return SUPPORTED_FORMATS.includes(value.type);
      }),
    activate_pdf: yup
      .mixed()
      //   .required("A file is required")
      .test(
        "fileType",
        "Invalid file format, only PDF allowed",
        (value: any) => {
          if (value === null || value === undefined) {
            return true; // Accepts undefined or null
          } // No file provided
          return value && value.type === "application/pdf";
        }
      )
      .nullable("true"),
  });

  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: any) => {
    try {
      const formData = new FormData();
      for (const property in data) {
        formData.append(`${property}`, data[property]);
      }
      const sendData = {
        id: singleItem?.id,
        data: formData,
      };
      await updateActivity(sendData).unwrap();
      handleClose();

      toast.success("Done");
    } catch (e) {
      toast.error("something wrong");
    }
  };
  console.log("asdas", methods.formState.errors);
  console.log("watch", methods.watch("activate_pdf"));

  useLayoutEffect(() => {
    methods.setValue("title_ar", singleItem?.title_ar);
    methods.setValue("title_en", singleItem?.title_en);
    methods.setValue("description_ar", singleItem?.description_ar);
    methods.setValue("description_en", singleItem?.description_en);
    methods.setValue("_method", "put");
  }, [singleItem]);
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Grid container columns={12} spacing={3}>
                  <Grid item xs={12}>
                    <StanderInput
                      name="title_ar"
                      placeholder="title_ar"
                      Label="title_ar"
                      type="string"
                      defaultValue=""
                    ></StanderInput>
                  </Grid>
                  <Grid item xs={12}>
                    <StanderInput
                      name="title_en"
                      placeholder="title_en"
                      Label="title_en"
                      type="string"
                      defaultValue=""
                    ></StanderInput>
                  </Grid>
                  <Grid item xs={12}>
                    <StanderTextArea
                      name="description_ar"
                      placeholder="description_ar"
                      Label="description_ar"
                      type="string"
                      defaultValue=""
                    ></StanderTextArea>
                  </Grid>
                  <Grid item xs={12}>
                    <StanderTextArea
                      name="description_en"
                      placeholder="description_en"
                      Label="description_en"
                      type="string"
                      defaultValue=""
                    ></StanderTextArea>
                  </Grid>

                  <Grid item xs={12}>
                    <label htmlFor="">activate cover</label>
                    <Controller
                      control={methods.control}
                      name={"activate_cover"}
                      render={({ field: { value, onChange, ...field } }) => {
                        return (
                          <TextField
                            {...field}
                            value={value?.fileName}
                            onChange={(event: any) => {
                              onChange(event.target.files[0]);
                            }}
                            type="file"
                            id="picture"
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <label htmlFor="">activate_pdf</label>
                    <Controller
                      control={methods.control}
                      name={"activate_pdf"}
                      render={({ field: { value, onChange, ...field } }) => {
                        return (
                          <TextField
                            {...field}
                            value={value?.fileName}
                            onChange={(event: any) => {
                              onChange(event.target.files[0]);
                            }}
                            type="file"
                            id="picture"
                          />
                        );
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <SubmitButton type="submit" fullWidth>
                      {isLoading ? "creating" : "Save"}
                    </SubmitButton>
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default Edit;
