import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../helper/BaseQuery";

export const CouponApi = createApi({
  reducerPath: "CouponApi",
  baseQuery,
  tagTypes: ["Coupons"],
  endpoints: (builder) => ({
    getHelpcoupon: builder.query({
      query: () => ({
        url: "/coupons/help-data",
        method: "get",
      }),
    }),

    generateCode: builder.query({
      query: () => ({
        url: "/coupons/generate-coupon",
        method: "get",
      }),
      providesTags: ["Coupons"],
    }),

    getcoupons: builder.query({
      query: ({ search, page }) => ({
        url: `coupons`,
        method: "get",
        params: {
          search,
          page,
        },
      }),
      providesTags: ["Coupons"],
    }),

    createCoupon: builder.mutation<any, any>({
      query: (body) => ({
        url: "coupons/store",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Coupons"],
    }),

    updateCoupon: builder.mutation<any, any>({
      query: ({ id, data }) => ({
        url: `coupons/update/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Coupons"],
    }),

    deleteCoupon: builder.mutation<any, any>({
      query: (id) => ({
        url: `coupons/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Coupons"],
    }),
  }),
});

export const {
  useGetHelpcouponQuery,
  useGenerateCodeQuery,
  useLazyGenerateCodeQuery,
  useGetcouponsQuery,
  useLazyGetcouponsQuery,
  useCreateCouponMutation,
  useUpdateCouponMutation,
  useDeleteCouponMutation,
} = CouponApi;
