import React from "react";
import { useParams } from "react-router-dom";
import {
  useCreateSubscriptionDecMutation,
  useDeleteSubscriptionMutation,
  useGetSingleSubscriptionQuery,
} from "../../../store/RTK/subscription";
import { Bigsection } from "../../homePage/satisfiedCustomers/components/showData/style";
import {
  DeleteButton,
  NoButton,
} from "../../../components/common/Buttons/deleteButton";
import { Box, Grid, Modal, Typography } from "@mui/material";
import { SubmitButton } from "../../../components/common/Buttons/SubmitButton";
import { toast } from "react-toastify";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import StanderInput from "../../../components/common/standerInput";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const AddDescription = () => {
  const params = useParams();
  const { id } = params;
  const { data, isFetching, isLoading } = useGetSingleSubscriptionQuery(id);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selected, setSelected] = React.useState<any>();
  console.log("selected", selected);
  const select = (feed: any) => {
    setSelected(feed);
    handleOpen();
  };

  const [openCreate, setOpenCreate] = React.useState(false);

  const handleOpenCreate = () => setOpenCreate(true);
  const handleCloseCreate = () => setOpenCreate(false);

  const selectCreate = () => {
    handleOpenCreate();
  };

  const mapping: any = () => {
    return data?.data?.subscription_plan_descriptions?.map((e: any) => {
      return (
        <tr key={e?.id}>
          <th>{e?.content_ar}</th>
          <th>{e?.content_en}</th>
          <th>
            <div className="actions">
              <DeleteButton onClick={() => select(e)}>Delete</DeleteButton>
            </div>
          </th>
        </tr>
      );
    });
  };

  return (
    <Bigsection>
      <NoButton onClick={selectCreate}>Add content</NoButton>
      {isFetching || isLoading ? (
        <h1>Loading</h1>
      ) : (
        <table>
          <tr>
            <th>content_ar</th>
            <th>content_en</th>
            <th>Actions</th>
          </tr>
          {mapping()}
        </table>
      )}
      <DeleteDesc open={open} handleClose={handleClose} singleItem={selected} />
      <AddContent id={id} open={openCreate} handleClose={handleCloseCreate} />
    </Bigsection>
  );
};

const DeleteDesc = ({ open, handleClose, singleItem }: any) => {
  const [deleteSubscription, { isLoading, isSuccess }] =
    useDeleteSubscriptionMutation();
  const id = singleItem?.id;
  console.log("singleItem", singleItem);
  console.log("id", id);
  const deleteFeed = async () => {
    try {
      await deleteSubscription(id);
    } catch (e) {
      toast.error("Error");
    }
  };
  React.useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [isSuccess]);
  return (
    <Bigsection>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            textAlign={"center"}
          >
            Are you sure you want to delete this Category
          </Typography>

          <div
            className="btns"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              justifyContent: "center",
              padding: "1rem 0",
            }}
          >
            <DeleteButton onClick={deleteFeed}>
              {isLoading ? "Deleting..." : "Delete"}
            </DeleteButton>
            <SubmitButton className="btn" onClick={handleClose}>
              No
            </SubmitButton>
          </div>
        </Box>
      </Modal>
    </Bigsection>
  );
};

const AddContent = ({ id, open, handleClose }: any) => {
  const [createSubscriptionDec, { isLoading }] =
    useCreateSubscriptionDecMutation();
  const schema = yup.object({
    content_en: yup.string().required("content_en is required"),
    content_ar: yup.string().required("content_ar is required"),
  });

  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: any) => {
    console.log("data", data.content);
    const sendData = {
      subscription_plan_id: id,
      ...data,
    };
    console.log("sendData", sendData);
    try {
      await createSubscriptionDec(sendData).unwrap();
      await toast.success("Edited");
      await handleClose();
    } catch (e) {
      await toast.error("something wrong");
    }
  };
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add Content
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Grid container columns={12} spacing={3}>
                  <Grid item xs={12}>
                    <StanderInput
                      // required
                      name="content_en"
                      placeholder="content_en"
                      Label="content_en"
                      type="string"
                      defaultValue=""
                    ></StanderInput>
                    <StanderInput
                      // required
                      name="content_ar"
                      placeholder="content_ar"
                      Label="content_ar"
                      type="string"
                      defaultValue=""
                    ></StanderInput>
                  </Grid>

                  <Grid item xs={12}>
                    <SubmitButton type="submit" fullWidth>
                      {isLoading ? "creating" : "Save"}
                    </SubmitButton>
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};
export default AddDescription;
