import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { RootState } from "../store/store";

export const Anonymous = () => {
  const { data } = useSelector((state: RootState) => state.auth);
  const user: any = localStorage.getItem("octar-dash");
  let token = JSON.parse(user)?.token;

  return data?.token || token ? <Navigate to="/" replace /> : <Outlet />;
};
