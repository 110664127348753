import React from "react";
import { Bigsection } from "../../homePage/satisfiedCustomers/components/showData/style";
import {
  DeleteButton,
  NoButton,
} from "../../../components/common/Buttons/deleteButton";
import { useGetSubscriptionQuery } from "../../../store/RTK/subscription";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditSub from "../EditSub";
import DeleteDesc from "../deleteSub";
import { Link } from "react-router-dom";

const ShowDataSub = () => {
  const { data, isFetching, isLoading } = useGetSubscriptionQuery({});
  console.log("data", data);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selected, setSelected] = React.useState<any>();
  console.log("selected", selected);
  const select = (feed: any) => {
    setSelected(feed);
    handleOpen();
  };

  const [openEdit, setOpenEdit] = React.useState(false);

  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  const selectEdit = (feed: any) => {
    setSelected(feed);
    handleOpenEdit();
  };
  const mapping: any = () => {
    return data?.data.map((e: any) => {
      return (
        <tr key={e?.id}>
          <th>{e?.account_type?.title_en}</th>
          <th>{e?.dolar_price}</th>
          <th>{e?.instead_dolar}</th>
          <th>{e?.pound_price}</th>
          <th>{e?.instead_pound}</th>
          <th>{Boolean(e?.is_publish)?.toString()}</th>
          <th>{e?.period}</th>
          <th>{e?.title_ar}</th>
          <th>{e?.title_en}</th>
          <th>{Boolean(e?.is_best_value)?.toString()}</th>

          <th>
            <div className="actions">
              <Link to={`/home/subscription/add-description/${e?.id}`}>
                <RemoveRedEyeIcon />
              </Link>

              {/* <DeleteButton onClick={() => select(e)}>Delete</DeleteButton> */}
              <NoButton onClick={() => selectEdit(e)}>Edit</NoButton>
            </div>
          </th>
        </tr>
      );
    });
  };

  return (
    <Bigsection>
      {isFetching || isLoading ? (
        <h1>Loading</h1>
      ) : (
        <table>
          <tr>
            <th>account_type</th>
            <th>dolar_price</th>
            <th>instead_dolar</th>
            <th>pound_price</th>
            <th>instead_pound</th>
            <th>is_publish</th>
            <th>period</th>
            <th>title Ar</th>
            <th>title En</th>
            <th>is_best_value</th>
            <th>Actions</th>
          </tr>
          {mapping()}
        </table>
      )}
      <DeleteDesc open={open} handleClose={handleClose} singleItem={selected} />
      <EditSub
        open={openEdit}
        handleClose={handleCloseEdit}
        singleItem={selected}
      />
    </Bigsection>
  );
};

export default ShowDataSub;
