import React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { Box, Grid } from "@mui/material";

import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { SubmitButton } from "../../../../../../components/common/Buttons/SubmitButton";
import StanderInput from "../../../../../../components/common/standerInput";
import { useCreateFaqCategoryUnityMutation } from "../../../../../../store/RTK/faq";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const CreateCategoryFaqUnity = ({ open, handleClose }: any) => {
  const schema = yup.object({
    title_en: yup.string().required("title_en is required"),
    title_ar: yup.string().required("title_ar is required"),
  });
  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  const [error, setError] = React.useState([]);
  const [createFaqCategoryUnity, { isSuccess, isLoading, isError, data }] =
    useCreateFaqCategoryUnityMutation();
  const onSubmit = async (data: any) => {
    try {
      await createFaqCategoryUnity(data);
    } catch (error: any) {}
  };
  React.useEffect(() => {
    if (isSuccess) {
      toast.success("Faq has been created");
      methods.reset();
      handleClose();
    }
    if (isError) {
      toast.error("something went wrong");
    }
  }, [isSuccess, isError]);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid container columns={12} spacing={3}>
              <Grid item xs={12} style={{}}>
                <h4>Add Category</h4>
              </Grid>

              <Grid item xs={12}>
                <StanderInput
                  // required
                  name="title_en"
                  placeholder="title_en"
                  Label="title_en"
                  type="string"
                  defaultValue=""
                ></StanderInput>
              </Grid>
              <Grid item xs={12}>
                <StanderInput
                  // required
                  name="title_ar"
                  placeholder="title_ar"
                  Label="title_ar"
                  type="string"
                  defaultValue=""
                ></StanderInput>
              </Grid>

              <Grid item xs={12}>
                <SubmitButton type="submit" fullWidth>
                  {isLoading ? "creating" : "Save"}
                </SubmitButton>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Box>
    </Modal>
  );
};

export default CreateCategoryFaqUnity;
