import React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { toast } from "react-toastify";
import { Checkbox, Grid, TextField } from "@mui/material";
import StanderInput from "../../../../components/common/standerInput";
import StanderTextArea from "../../../../components/common/standardTextArea";
import { SubmitButton } from "../../../../components/common/Buttons/SubmitButton";
import Errors from "../../../../components/common/Errors";
import { useCreateCategoryMutation } from "../../../../store/RTK/category";
import { Bigsection } from "./style";
const SUPPORTED_FORMATS = ["image/png", "image/jpeg", "image/jpg"];

const CreateCategory = () => {
  const schema = yup.object({
    is_english: yup.string().required("Is english is required"),
    sort_number: yup.string().required("Sort number is required"),
    title: yup.string().required("title is required"),
    qr_code: yup.string().required("qr_code is required"),
    description: yup.string().required("description is required"),
    targets_description: yup
      .string()
      .required("targets_description is required"),
    category_image: yup
      .mixed()
      .required("A file is required")
      .test("fileType", "Unsupported file format", (value: any) => {
        if (!value) return true; // If no file is provided, don't perform validation
        return SUPPORTED_FORMATS.includes(value.type);
      }),
    category_pdf: yup
      .mixed()
      .required("Category pdf is required")
      .test(
        "fileType",
        "Invalid file format, only PDF allowed",
        (value: any) => {
          if (!value) return false; // No file provided
          return value && value.type === "application/pdf";
        }
      ),

    soon_title: yup.string().when("is_publish", {
      is: 0,
      then: (schema) => schema.required("soon_title is required"),
    }),
    soon_description: yup.string().when("is_publish", {
      is: 0,
      then: (schema) => schema.required("soon description is required"),
    }),
  });

  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      is_publish: +true,
      is_english: +true,
    },
  });

  const control = methods.control;
  const validationErr: any = methods.formState.errors;
  const [error, setError] = React.useState([]);
  const [createCategory, { isSuccess, isLoading, isError, data }] =
    useCreateCategoryMutation();
  const onSubmit = async (data: any) => {
    try {
      console.log("Sned data", data);
      const formData = new FormData();
      formData.append("title", data.title);
      formData.append("is_english", data.is_english);
      formData.append("sort_number", data.sort_number);
      formData.append("qr_code", data.qr_code);
      formData.append("description", data.description);
      formData.append("targets_description", data.targets_description);
      formData.append("category_image", data.category_image);
      formData.append("category_pdf", data.category_pdf);
      formData.append("is_publish", data.is_publish);
      formData.append("soon_title", data.soon_title);
      formData.append("soon_description", data.soon_description);
      await createCategory(formData);
      if (isSuccess) {
        toast.success("Category has been created");
        setError([]);
      }
    } catch (error: any) {
      setError(error?.data?.errors);
    }
  };
  React.useEffect(() => {
    const redirect = async () => {
      if (isSuccess) {
        await toast.success("Category has been created");
        await methods.reset();
      }
      if (!isSuccess && isError) {
        await toast.error("something went wrong");
      }
    };
    redirect();
  }, [isSuccess, isError]);
  const isPublish = methods.watch("is_publish");
  console.log("erroes", methods.formState.errors);
  return (
    <Bigsection>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container columns={12} spacing={3}>
            <Grid item xs={12} style={{}}>
              <h4>Add Category</h4>
            </Grid>
            <Grid item xs={12}>
              <StanderInput
                // required
                name="title"
                placeholder="title"
                Label="title"
                type="string"
                defaultValue=""
              ></StanderInput>
            </Grid>
            <Grid item xs={12}>
              <StanderInput
                // required
                name="qr_code"
                placeholder="Qr code"
                Label="Qr code"
                type="string"
                defaultValue=""
              ></StanderInput>
            </Grid>
            <Grid item xs={12}>
              <StanderInput
                // required
                name="sort_number"
                placeholder="Sort number"
                Label="Sort number"
                type="number"
                defaultValue=""
              ></StanderInput>
            </Grid>
            <Grid item xs={12}>
              <label htmlFor="">category image</label>
              <Controller
                control={control}
                name={"category_image"}
                render={({ field: { value, onChange, ...field } }) => {
                  return (
                    <TextField
                      {...field}
                      value={value?.fileName}
                      onChange={(event: any) => {
                        onChange(event.target.files[0]);
                      }}
                      type="file"
                      id="picture"
                    />
                  );
                }}
              />
              <p className="err">
                {validationErr?.category_image
                  ? validationErr?.category_image.message
                  : ""}
              </p>
            </Grid>
            <Grid item xs={12}>
              <label htmlFor="">category pdf</label>
              <Controller
                control={control}
                name={"category_pdf"}
                render={({ field: { value, onChange, ...field } }) => {
                  return (
                    <TextField
                      {...field}
                      value={value?.fileName}
                      onChange={(event: any) => {
                        onChange(event.target.files[0]);
                      }}
                      type="file"
                      id="picture"
                    />
                  );
                }}
              />
              <p className="err">
                {" "}
                {validationErr?.category_pdf
                  ? validationErr?.category_pdf.message
                  : ""}
              </p>
            </Grid>

            <Grid item xs={12}>
              <StanderTextArea
                // required
                name="description"
                placeholder="description"
                Label="description"
                type="text"
                minRows={5}
                defaultValue=""
              ></StanderTextArea>
            </Grid>
            <Grid item xs={12}>
              <StanderTextArea
                // required
                name="targets_description"
                placeholder="targets_description"
                Label="targets_description"
                type="text"
                minRows={5}
                defaultValue=""
              ></StanderTextArea>
            </Grid>
            <Grid item xs={12} md={2}>
              <label htmlFor="">Is publish</label>
              <Controller
                control={control}
                name={"is_publish"}
                render={({ field: { value, onChange, ...field } }) => {
                  return (
                    <Checkbox
                      {...field}
                      value={value?.fileName}
                      onChange={(event: any) => {
                        onChange(+event.target.checked);
                      }}
                      defaultChecked={true}
                    />
                  );
                }}
              />
              <p className="err">
                {" "}
                {validationErr?.is_publish
                  ? validationErr?.is_publish.message
                  : ""}
              </p>
            </Grid>
            <Grid item xs={12} md={2}>
              <label htmlFor="">Is English</label>
              <Controller
                control={control}
                name={"is_english"}
                render={({ field: { value, onChange, ...field } }) => {
                  return (
                    <Checkbox
                      {...field}
                      value={value?.fileName}
                      onChange={(event: any) => {
                        onChange(+event.target.checked);
                      }}
                      defaultChecked={true}
                    />
                  );
                }}
              />
              <p className="err">
                {" "}
                {validationErr?.is_publish
                  ? validationErr?.is_publish.message
                  : ""}
              </p>
            </Grid>
            {!isPublish ? (
              <>
                <Grid item xs={12} md={5}>
                  <StanderInput
                    // required
                    name="soon_title"
                    placeholder="soon_title"
                    Label="soon_title"
                    type="string"
                    defaultValue=""
                  ></StanderInput>
                </Grid>
                <Grid item xs={12} md={5}>
                  <StanderTextArea
                    // required
                    name="soon_description"
                    placeholder="soon_description"
                    Label="soon_description"
                    type="text"
                    minRows={5}
                    defaultValue=""
                  ></StanderTextArea>
                </Grid>
              </>
            ) : (
              <></>
            )}
            <Grid item xs={12}>
              <SubmitButton type="submit" fullWidth>
                {isLoading ? "creating" : "Save"}
              </SubmitButton>
            </Grid>
            <Grid item xs={12}>
              <Errors errors={error}></Errors>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Bigsection>
  );
};

export default CreateCategory;
