import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Grid, TextField } from "@mui/material";
import StanderInput from "../../../../../../../components/common/standerInput";
import { SubmitButton } from "../../../../../../../components/common/Buttons/SubmitButton";
import { toast } from "react-toastify";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useCreateSingleSubColoringActivitesMutation,
  useEditSingleSubColoringActivitesMutation,
} from "../../../../../../../store/RTK/coloringActivities";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const EditSub = ({ handleClose, open, selected }: any) => {
  const SUPPORTED_FORMATS = ["image/png", "image/jpeg", "image/jpg"];
  const schema = yup.object({
    title_en: yup.string().required("title_en is required"),
    title_ar: yup.string().required("title_ar is required"),
    sub_activate_cover: yup
      .mixed()
      // .required("A file is required")
      .test("fileType", "Unsupported file format", (value: any) => {
        if (!value) return true; // If no file is provided, don't perform validation
        return SUPPORTED_FORMATS.includes(value.type);
      }),
  });
  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  const [error, setError] = React.useState([]);
  const [editSub, { isSuccess, isLoading, isError, data }] =
    useEditSingleSubColoringActivitesMutation();
  const validationErr = methods.formState.errors;

  useEffect(() => {
    methods.setValue("title_ar", selected?.title_ar);
    methods.setValue("title_en", selected?.title_en);
  }, [selected]);

  const onSubmit = async (data: any) => {
    console.log("Send Date", data);
    try {
      const formData = new FormData();
      // formData.append("title_en", data?.title_en);
      // formData.append("title_ar", data?.title_ar);
      // formData.append("sub_activate_cover", data?.sub_activate_cover);

      for (const property in data) {
        formData.append(property, data[property]);
      }

      formData.append("_method", "put");
      const sendData = {
        body: formData,
        id: selected?.id,
      };
      await editSub(sendData).unwrap();
      //   if (isSuccess) {
      await toast.success("Sub has been edited");
      await handleClose();
      setError([]);
      //   }
    } catch (error: any) {
      toast.error("something went wrong");
    }
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          <h4>Add Sub</h4>
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Grid container columns={12} spacing={3}>
                <Grid item xs={12}>
                  <StanderInput
                    // required
                    name="title_ar"
                    placeholder="title_ar"
                    Label="title_ar"
                    type="string"
                    defaultValue=""
                  ></StanderInput>
                </Grid>
                <Grid item xs={12}>
                  <StanderInput
                    // required
                    name="title_en"
                    placeholder="title_en"
                    Label="title_en"
                    type="string"
                    defaultValue=""
                  ></StanderInput>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={methods.control}
                    name={"sub_activate_cover"}
                    render={({ field: { value, onChange, ...field } }) => {
                      return (
                        <TextField
                          {...field}
                          value={value?.fileName}
                          onChange={(event: any) => {
                            onChange(event.target.files[0]);
                          }}
                          type="file"
                          id="picture"
                        />
                      );
                    }}
                  />
                  <p style={{ color: "red", fontSize: "0.7rem" }}>
                    {" "}
                    {validationErr?.media ? (
                      <>{validationErr?.media.message}</>
                    ) : (
                      <></>
                    )}
                  </p>
                </Grid>

                <Grid item xs={12}>
                  <SubmitButton type="submit" fullWidth>
                    {isLoading ? "creating" : "Save"}
                  </SubmitButton>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Typography>
      </Box>
    </Modal>
  );
};

export default EditSub;
