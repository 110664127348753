import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../helper/BaseQuery";

export const categoriesProductApi = createApi({
  reducerPath: "categoriesProductApi",
  baseQuery,
  tagTypes: ["category"],
  endpoints: (builder) => ({
    geCategoriesProducts: builder.query({
      query: ({ page, search }) => ({
        url: "category-product/index",
        method: "get",
        params: {
          page,
          search,
        },
      }),
      providesTags: ["category"],
    }),
    storeCategoryProduct: builder.mutation<any, any>({
      query: (body) => ({
        url: `category-product/store`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["category"],
    }),
    getSingleCategoryProduct: builder.query({
      query: (id) => ({
        url: `category-product/show/${id}`,
        method: "get",
      }),
      providesTags: ["category"],
    }),
    updateCategoryProduct: builder.mutation<any, any>({
      query: ({ body, id }) => ({
        url: `category-product/update/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["category"],
    }),
    deleteCategoryProduct: builder.mutation<any, any>({
      query: (id) => ({
        url: `category-product/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["category"],
    }),
  }),
});

export const {
  useLazyGeCategoriesProductsQuery,
  useGeCategoriesProductsQuery,
  useGetSingleCategoryProductQuery,
  useStoreCategoryProductMutation,
  useUpdateCategoryProductMutation,
  useDeleteCategoryProductMutation,
} = categoriesProductApi;
