import React from "react";
import { useGetAllAcountsQuery } from "../../../../../store/RTK/accountType";
import {
  DeleteButton,
  NoButton,
} from "../../../../../components/common/Buttons/deleteButton";
import { Bigsection } from "../../../satisfiedCustomers/components/showData/style";
import { NavLink } from "react-router-dom";
import EditAccountTypeImage from "../editImage";

const ShowAllAccountTypes = () => {
  const { data, isFetching, isLoading } = useGetAllAcountsQuery({});
  console.log("data", data);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selected, setSelected] = React.useState<any>();
  const select = (feed: any) => {
    setSelected(feed);
    handleOpen();
  };
  const mapping: any = () => {
    return data?.data.map((e: any) => {
      return (
        <tr key={e?.id}>
          <td>{e?.title_en}</td>
          <td>{e?.title_ar}</td>
          <td>
            {e?.account_type_description?.map((dec: any) => {
              return (
                <div>
                  <p>English: {dec?.content_en}</p>
                  <p>Arabic: {dec?.content_ar}</p>
                  <hr />
                </div>
              );
            })}
          </td>
          <td className="content">
            <div>
              <a href={e?.media?.file_path} target="_blank">
                {" "}
                <img src={e?.media?.file_path}></img>
              </a>
            </div>
          </td>
          <td>
            <div className="actions">
              <NavLink to={`/home/home-page/account-type/edit/${e?.id}`}>
                <NoButton onClick={() => select(e)}>Edit</NoButton>
              </NavLink>
              <NoButton onClick={() => select(e)}>Edit img</NoButton>
            </div>
          </td>
        </tr>
      );
    });
  };
  return (
    <div>
      <Bigsection>
        {isFetching || isLoading ? (
          <h1>Loading</h1>
        ) : (
          <table>
            <tr>
              <th>Title English</th>
              <th>Title Arabic</th>
              <th>Account type description</th>
              <th>Media</th>
              <th>Actions</th>
            </tr>
            {mapping()}
          </table>
        )}

        <EditAccountTypeImage
          open={open}
          setOpen={setOpen}
          selected={selected}
        />
      </Bigsection>
    </div>
  );
};

export default ShowAllAccountTypes;
