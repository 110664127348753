import React, { useEffect, useState } from "react";
import { Bigsection } from "../../homePage/satisfiedCustomers/components/showData/style";
import {
  DeleteButton,
  NoButton,
} from "../../../components/common/Buttons/deleteButton";
import EditSub from "../EditCoupon";
import DeleteDesc from "../deleteCoupons";
import { Link } from "react-router-dom";
import DeleteCoupon from "../deleteCoupons";
import EditCoupon from "../EditCoupon";
import {
  useGetcouponsQuery,
  useLazyGetcouponsQuery,
} from "../../../store/RTK/copoun";
import { Grid, TextField } from "@mui/material";

const ShowCoupons = () => {
  const [getCoupons, { data, isFetching, isLoading }] =
    useLazyGetcouponsQuery();
  const [page, setPage] = React.useState<number>(1);
  const handleChangePage: any = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const [search, setSearch] = useState<any>("");
  const params = {
    page: page,
    search: search,
  };
  const handlChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearch(e.target.value);
  };
  useEffect(() => {
    const fetchApi = async () => {
      getCoupons(params);
    };
    const delayDebounceFn = setTimeout(() => {
      fetchApi();
    }, 550);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selected, setSelected] = React.useState<any>();
  console.log("selected", selected);
  const select = (feed: any) => {
    setSelected(feed);
    handleOpen();
  };

  const [openEdit, setOpenEdit] = React.useState(false);

  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  const selectEdit = (feed: any) => {
    setSelected(feed);
    handleOpenEdit();
  };
  console.log("dataaaaaaaaaaaaaaaaa", data?.data?.data);
  const mapping: any = () => {
    return data?.data?.data?.map((e: any) => {
      return (
        <tr key={e?.id}>
          <th>{e?.account_type?.title}</th>
          <th>{e?.coupon_code}</th>
          <th>{e?.currency}</th>
          <th>{e?.title}</th>
          <th>{e?.description}</th>
          <th>{e?.number_user}</th>
          <th>{e?.percentage}</th>
          <th>
            <div className="actions">
              <DeleteButton onClick={() => select(e)}>Delete</DeleteButton>
              <NoButton onClick={() => selectEdit(e)}>Edit</NoButton>
            </div>
          </th>
        </tr>
      );
    });
  };

  return (
    <Bigsection>
      {isFetching || isLoading ? (
        <h1>Loading</h1>
      ) : (
        <Grid container columns={12}>
          <Grid item xs={12}>
            <TextField
              type="search"
              onChange={handlChange}
              label="Search"
              value={search}
            ></TextField>
            <table>
              <tr>
                <th>account type</th>
                <th>coupon code</th>
                <th>currency</th>
                <th>title</th>
                <th>description</th>
                <th>number of user</th>
                <th>percentage</th>
              </tr>
              {mapping()}
            </table>
          </Grid>
        </Grid>
      )}
      <DeleteCoupon
        open={open}
        handleClose={handleClose}
        singleItem={selected}
        key={selected?.id}
      />
      <EditCoupon
        open={openEdit}
        handleClose={handleCloseEdit}
        singleItem={selected}
        key={selected?.id}
      />
    </Bigsection>
  );
};

export default ShowCoupons;
