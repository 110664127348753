import React, { Suspense, useState } from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import {
  MyBsPerson,
  MyCalendarTodayOutlinedIcon,
  MyChevronRightIcon,
  MyFiBriefcase,
  MyFiClipboard,
  MyIoDocumentTextOutline,
  MyMenuIcon,
} from "../common/icons";
import IconButton from "@mui/material/IconButton";
import { NavBarStyle } from "./NabVarStyle";
import { Link, NavLink, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { GiExitDoor } from "react-icons/gi";
import { ExitToApp } from "@mui/icons-material";
import { logOut } from "../../store/reducers/auth/authSlice";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

type Tlist = {
  id: number;
  name: string;
  icon: any;
  path: string;
  children?: {
    id: number;
    name: string;
    icon: any;
    path: string;
  }[];
}[];

const AllRouts: Tlist = [
  {
    id: 1,
    name: "Home",
    icon: <MyCalendarTodayOutlinedIcon />,
    path: "home-page",
  },

  {
    id: 2,
    name: "Faqs",
    icon: <MyCalendarTodayOutlinedIcon />,
    path: "faqs",
  },
  {
    id: 3,
    name: "Categogries",
    icon: <MyCalendarTodayOutlinedIcon />,
    path: "cetegory",
  },
  {
    id: 4,
    name: "Payment",
    icon: <MyCalendarTodayOutlinedIcon />,
    path: "payment-method",
  },
  {
    id: 5,
    name: "Subscription",
    icon: <MyCalendarTodayOutlinedIcon />,
    path: "subscription",
  },
  {
    id: 6,
    name: "faq unity",
    icon: <MyCalendarTodayOutlinedIcon />,
    path: "faq-unity",
  },
  {
    id: 7,
    name: "Nontification",
    icon: <MyCalendarTodayOutlinedIcon />,
    path: "nontification",
  },
  {
    id: 8,
    name: "Blogs",
    icon: <MyCalendarTodayOutlinedIcon />,
    path: "blogs",
  },
  {
    id: 9,
    name: "coupons",
    icon: <MyCalendarTodayOutlinedIcon />,
    path: "Coupons",
  },
  {
    id: 10,
    name: "Coloring actvities",
    icon: <MyCalendarTodayOutlinedIcon />,
    path: "coloring-actvities",
  },
  {
    id: 11,
    name: "Wallet",
    icon: <MyCalendarTodayOutlinedIcon />,
    path: "wallet",
  },
  {
    id: 12,
    name: "CategoriesProducts",
    icon: <MyCalendarTodayOutlinedIcon />,
    path: "CategoriesProducts",
  },
  {
    id: 13,
    name: "products",
    icon: <MyCalendarTodayOutlinedIcon />,
    path: "products",
  },
  {
    id: 14,
    name: "wallet Product",
    icon: <MyCalendarTodayOutlinedIcon />,
    path: "walletProduct",
  },
  {
    id: 15,
    name: "Orders",
    icon: <MyCalendarTodayOutlinedIcon />,
    path: "orders",
  },
  {
    id: 16,
    name: "DeliveryFees",
    icon: <MyCalendarTodayOutlinedIcon />,
    path: "deliveryFees",
  },
  {
    id: 16,
    name: "Songs",
    icon: <MyCalendarTodayOutlinedIcon />,
    path: "songs",
  },
];

const NavBar = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state: RootState) => state.auth);
  const [open, setOpen] = React.useState(true);
  const [openSubMenu, setOpenSubMenu] = useState<boolean>(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(!open);
  };

  return (
    <NavBarStyle open={open}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,
            ...(open && { display: "none" }),
          }}
        >
          <MyMenuIcon />
        </IconButton>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader className={open ? "header-open" : "header-close"}>
            {open ? (
              <>
                <img src=""></img>
              </>
            ) : (
              <></>
            )}
            <IconButton onClick={handleDrawerClose}>
              {open ? (
                <>
                  <MyChevronRightIcon />
                </>
              ) : (
                <>
                  <MyMenuIcon></MyMenuIcon>
                </>
              )}
            </IconButton>
          </DrawerHeader>
          <div className="nav">
            <div>
              <List>
                <ul>
                  {AllRouts.map((item) => (
                    <>
                      <li>
                        <NavLink to={item.path ? `${item.path}` : "#"}>
                          {item.name}
                        </NavLink>
                        {item.children ? (
                          <button
                            onClick={() => {
                              setOpenSubMenu(!openSubMenu);
                            }}
                          >
                            sub menu
                          </button>
                        ) : null}
                      </li>
                      {item.children ? (
                        <>
                          {openSubMenu ? (
                            <div className="subDiv">
                              {item.children.map((sub) => {
                                return (
                                  <li>
                                    <NavLink to={`${sub.path}`}>
                                      {sub.name}
                                    </NavLink>
                                  </li>
                                );
                              })}
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ))}
                </ul>
              </List>
            </div>
            <div>
              <List>
                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                    className="profile"
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <img className="profile-img" src={data?.image} />
                    </ListItemIcon>
                    <div style={{ display: !open ? "none" : "block" }}>
                      <ListItemText
                        primary={data?.name}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </div>
                  </ListItemButton>

                  <ListItemButton
                    onClick={() => dispatch(logOut())}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                    className="profile"
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <ExitToApp />
                    </ListItemIcon>
                    <div style={{ display: !open ? "none" : "block" }}>
                      <ListItemText
                        primary="loge Out"
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </div>
                  </ListItemButton>
                </ListItem>
              </List>
            </div>
          </div>
        </Drawer>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            backgroundColor: "#FAFCFE",
            minHeight: "100vh",
            padding: "1rem 2rem",
          }}
          // style={{ overflowX: "hidden" }}
        >
          {/* <DrawerHeader /> */}
          <Suspense fallback={<h1>Loading....</h1>}>
            <Outlet />
          </Suspense>
        </Box>
      </Box>
    </NavBarStyle>
  );
};

export default NavBar;
