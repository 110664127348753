import React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { Box, Grid } from "@mui/material";
import { useCreateFaqMutation } from "../../../../store/RTK/faq";
import StanderInput from "../../../../components/common/standerInput";
import StanderTextArea from "../../../../components/common/standardTextArea";
import { SubmitButton } from "../../../../components/common/Buttons/SubmitButton";
import Errors from "../../../../components/common/Errors";
import SelectInput from "../../../../components/common/SelectInput/SelectInput";
import { useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const CreateFaq = ({ open, handleClose }: any) => {
  const schema = yup.object({
    question_ar: yup.string().required("question_ar is required"),
    question_en: yup.string().required("question_en is required"),
    answer_en: yup.string().required("answer_en is required"),
    answer_ar: yup.string().required("answer_ar is required"),
  });
  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  const params = useParams();
  const [error, setError] = React.useState([]);
  const [createFaq, { isSuccess, isLoading, isError, data }] =
    useCreateFaqMutation();
  const onSubmit = async (data: any) => {
    let category_id = {
      category_id: params?.id,
    };
    try {
      await createFaq({ ...data, ...category_id }).unwrap();
      methods.reset();
    } catch (error: any) {}
  };
  React.useEffect(() => {
    if (isSuccess) {
      toast.success("Faq has been created");
      handleClose();
    }
    if (isError) {
      toast.error("something went wrong");
    }
  }, [isSuccess, isError]);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid container columns={12} spacing={3}>
              <Grid item xs={12} style={{}}>
                <h4>Add Feedback</h4>
              </Grid>
              <Grid item xs={12}>
                <StanderInput
                  // required
                  name="question_en"
                  placeholder="question_en"
                  Label="question_en"
                  type="string"
                  defaultValue=""
                ></StanderInput>
              </Grid>
              <Grid item xs={12}>
                <StanderInput
                  // required
                  name="question_ar"
                  placeholder="question_ar"
                  Label="question_ar"
                  type="string"
                  defaultValue=""
                ></StanderInput>
              </Grid>
              <Grid item xs={12}>
                <StanderTextArea
                  // required
                  name="answer_en"
                  placeholder="answer_en"
                  Label="answer_en"
                  type="text"
                  minRows={5}
                  defaultValue=""
                ></StanderTextArea>
              </Grid>
              <Grid item xs={12}>
                <StanderTextArea
                  // required
                  name="answer_ar"
                  placeholder="answer_ar"
                  Label="answer_ar"
                  type="text"
                  minRows={5}
                  defaultValue=""
                ></StanderTextArea>
              </Grid>

              <Grid item xs={12}>
                <SubmitButton type="submit" fullWidth>
                  {isLoading ? "creating" : "Save"}
                </SubmitButton>
              </Grid>
              <Grid item xs={12}>
                <Errors errors={error}></Errors>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Box>
    </Modal>
  );
};

export default CreateFaq;
