import React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { Box, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { DeleteButton } from "../../../../../../../components/common/Buttons/deleteButton";
import { SubmitButton } from "../../../../../../../components/common/Buttons/SubmitButton";
import { useDeleteCategoryUnityQuestionMutation } from "../../../../../../../store/RTK/faq";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const DeleteQuestionUnity = ({ open, handleClose, singleItem }: any) => {
  const [deleteCategoryUnityQuestion, { isSuccess, isLoading, isError, data }] =
    useDeleteCategoryUnityQuestionMutation();
  const deleteCategory = async () => {
    try {
      await deleteCategoryUnityQuestion(singleItem?.id);
    } catch (error: any) {
      toast.error("something went wrong");
    }
  };
  React.useEffect(() => {
    if (isSuccess) {
      toast.success("Faq has been Deleted");
      handleClose();
    }
    if (isError) {
      toast.error("something went wrong");
    }
  }, [isSuccess, isError]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid container columns={12} spacing={3}>
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure you want to delete this question ({singleItem?.que_en})
          </Typography>

          <div
            className="btns"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              justifyContent: "center",
              padding: "1rem 0",
            }}
          >
            <DeleteButton onClick={deleteCategory}>
              {isLoading ? "Deleting..." : "Delete"}
            </DeleteButton>
            <SubmitButton className="btn" onClick={handleClose}>
              No
            </SubmitButton>
          </div>
        </Box>
      </Grid>
    </Modal>
  );
};

export default DeleteQuestionUnity;
