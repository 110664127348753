import { Box, Grid, Modal, TextField, Typography } from "@mui/material";
import React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { toast } from "react-toastify";
import {
  useCreatePaymentMutation,
  useUpdatePaymentMutation,
} from "../../../../store/RTK/payment";
import { SubmitButton } from "../../../../components/common/Buttons/SubmitButton";
import StanderInput from "../../../../components/common/standerInput";
import SelectInput from "../../../../components/common/SelectInput/SelectInput";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const dropDown = [
  { id: "card", name: "Card" },
  { id: "wallet", name: "Wallet" },
];
const dropDownCurrency = [
  { id: "EGY", name: "EGY" },
  { id: "USD", name: "USD" },
];
const EditPayment = ({ handleClose, open, singleItem }: any) => {
  const SUPPORTED_FORMATS = ["image/png", "image/jpeg", "image/jpg"];
  const schema = yup.object({
    title: yup.string().required("title is required"),
    icon: yup
      .mixed()
      .required("A file is required")
      .test("fileType", "Unsupported file format", (value: any) => {
        if (!value) return true; // If no file is provided, don't perform validation
        return SUPPORTED_FORMATS.includes(value.type);
      }),
  });
  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  const [error, setError] = React.useState([]);
  const [updatePayment, { isSuccess, isLoading, isError, data }] =
    useUpdatePaymentMutation();
  const validationErr = methods.formState.errors;

  const onSubmit = async (data: any) => {
    console.log("Send Date", data);
    try {
      const formData = new FormData();
      formData.append("title", data?.title);
      formData.append("icon", data?.icon);
      formData.append("_method", "put");
      const sendData = {
        id: singleItem.id,
        data: formData,
      };
      await updatePayment(sendData);
      await methods.reset();
    } catch (error: any) {
      toast.error("something went wrong");
      setError(error?.data?.errors);
    }
  };
  React.useEffect(() => {
    methods.setValue("title", singleItem?.title);
    methods.setValue("currency", singleItem?.currency);
    methods.setValue("type", singleItem?.type);
    methods.setValue("phone", singleItem?.phone);
  }, [singleItem]);
  React.useEffect(() => {
    if (isSuccess && !isError) {
      toast.success("Payment has been Edited");
      handleClose();
    }
    if (!isError) {
      //   toast.error("something went wrong");
    }
  }, [isSuccess]);
  const typeData = methods.watch("type");

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <h4>Edit payment</h4>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Grid container columns={12} spacing={3}>
                  <Grid item xs={12}>
                    <SelectInput
                      name="type"
                      placeholder="Type"
                      label="Type"
                      defaultValue=""
                      data={dropDown}
                    ></SelectInput>
                  </Grid>
                  <Grid item xs={12}>
                    <StanderInput
                      // required
                      name="title"
                      placeholder="title"
                      Label="title"
                      type="string"
                      defaultValue=""
                    ></StanderInput>
                  </Grid>
                  {typeData === "wallet" && (
                    <Grid item xs={12}>
                      <StanderInput
                        // required
                        name="phone"
                        placeholder="phone"
                        Label="phone"
                        type="string"
                        defaultValue=""
                      ></StanderInput>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <SelectInput
                      name="currency"
                      placeholder="currency"
                      label="currency"
                      defaultValue=""
                      data={dropDownCurrency}
                    ></SelectInput>
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={methods.control}
                      name={"icon"}
                      render={({ field: { value, onChange, ...field } }) => {
                        return (
                          <TextField
                            {...field}
                            value={value?.fileName}
                            onChange={(event: any) => {
                              onChange(event.target.files[0]);
                            }}
                            type="file"
                            id="picture"
                          />
                        );
                      }}
                    />
                    <p style={{ color: "red", fontSize: "0.7rem" }}>
                      {" "}
                      {validationErr.icon?.message ? (
                        <>{validationErr.icon?.message}</>
                      ) : (
                        <></>
                      )}
                    </p>
                  </Grid>

                  <Grid item xs={12}>
                    <SubmitButton type="submit" fullWidth>
                      {isLoading ? "creating" : "Save"}
                    </SubmitButton>
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default EditPayment;
