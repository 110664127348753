import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../helper/BaseQuery";

export const accountTypeApi = createApi({
    reducerPath: "accountTypeApi",
    baseQuery,
    tagTypes: ["accountType"],
    endpoints: (builder) => ({
        getAllAcounts: builder.query({
            query: () => ({
                url: "/subscription/account-type",
                method: "get",
            }),
            providesTags: ["accountType"]
        }),

        getSingleAcount: builder.query({
            query: (id) => ({
                url: `/subscription/show-account-type/${id}`,
                method: "get",
            }),
            // providesTags: ["accountType"]
        }),

        createAccountType: builder.mutation<any, any>({
            query: (body) => ({
                url: "/subscription/store-account-type",
                method: "POST",
                body,
                formData: true,
                // headers: {
                //     'Content-Type': 'multipart/form-data;'
                // },
            }),
            invalidatesTags: ["accountType"],
        }),
        editAccountType: builder.mutation<any, any>({
            query: (data) => ({
                url: `/subscription/update-account-type/${data?.id}`,
                method: "PUT",
                body: data?.data,
            }),
            invalidatesTags: ["accountType"],
        }),
        editAccountTypeMedia: builder.mutation<any, any>({
            query: (data) => ({
                url: `/subscription/update-media-account-type/${data?.id}`,
                method: "POST",
                body: data?.data,

            }),

            invalidatesTags: ["accountType"],
        }),

    }),
});

export const {
    useGetAllAcountsQuery,
    useGetSingleAcountQuery,
    useCreateAccountTypeMutation,
    useEditAccountTypeMutation,
    useEditAccountTypeMediaMutation,
} = accountTypeApi;
