import React from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useGetSingleCategoriesQuery } from "../../../../../store/RTK/category";
import { Bigsection } from "../../../satisfiedCustomers/components/showData/style";
import {
  DeleteButton,
  NoButton,
} from "../../../../../components/common/Buttons/deleteButton";
import DeleteTarget from "./deleteTarget";
import UpdateTarget from "./UpdateTarget";

const SigleCategory = () => {
  const params = useParams();
  const id = params?.id;
  const { data, isLoading } = useGetSingleCategoriesQuery({ id });
  const targets = data?.data?.targets;
  console.log("targets", targets);
  const [selected, setSelected] = React.useState<any>();
  const select = (e: any) => {
    setSelected(e);
  };
  const [open, setOpened] = React.useState<boolean>(false);
  const [openUpdate, setOpenedUpdate] = React.useState<boolean>(false);
  const handleClose = () => setOpened(false);
  const handleCloseUpdate = () => setOpenedUpdate(false);
  const tergetMapping: any = () => {
    return targets.map((e: any) => {
      return (
        <tr key={e?.id}>
          <td>{e?.title}</td>
          <td>{Boolean(e?.is_free)?.toString()}</td>
          <td>
            <a href={e?.media?.file_path} target="_blank">
              <img src={e?.media?.file_path} alt="" />
            </a>
          </td>
          <td>
            <div className="actions">
              <DeleteButton
                onClick={async () => {
                  select(e);
                  setOpened(!open);
                }}
              >
                Delete
              </DeleteButton>
              <NoButton
                onClick={async () => {
                  select(e);
                  setOpenedUpdate(!openUpdate);
                }}
              >
                Update
              </NoButton>
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <Bigsection>
        <nav>
          <ul>
            <NavLink to="add-target">
              <li>create Target</li>
            </NavLink>
          </ul>
        </nav>
        <div>
          <h1>Targets Media</h1>
          <table>
            <tr>
              <td>Title</td>
              <td>Is free</td>
              <td>imgs</td>
              <td>Actions</td>
            </tr>
            {targets?.length > 0 && tergetMapping()}
          </table>
        </div>
      </Bigsection>
      <DeleteTarget
        open={open}
        singleItem={selected}
        key={selected?.id}
        handleClose={handleClose}
      />
      <UpdateTarget
        open={openUpdate}
        singleItem={selected}
        key={selected?.id}
        handleClose={handleCloseUpdate}
      />
    </>
  );
};

export default SigleCategory;
