import React from "react";
import { useGetFaqQuery } from "../../../../store/RTK/faq";
import {
  DeleteButton,
  NoButton,
} from "../../../../components/common/Buttons/deleteButton";
import { Bigsection } from "../../satisfiedCustomers/components/showData/style";
import DeleteFaq from "../delete";
import { useParams } from "react-router-dom";
import CreateFaq from "../create";

const ShowFaq = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);
  const [selected, setSelected] = React.useState<any>();
  const mapping: any = () => {
    return data?.data.map((e: any) => {
      return (
        <tr key={e?.id}>
          <td className="content">{e?.question_en}</td>
          <td className="content">
            <p style={{ textAlign: "right", direction: "rtl" }}>
              {e?.question_ar}
            </p>
          </td>
          <td className="content">{e?.answer_en}</td>
          <td className="content">
            <p style={{ textAlign: "right", direction: "rtl" }}>
              {e?.answer_ar}
            </p>
          </td>
          <td>
            <DeleteButton onClick={() => select(e)}>Delete</DeleteButton>
          </td>
        </tr>
      );
    });
  };
  const select = (feed: any) => {
    setSelected(feed);
    handleOpenDelete();
  };
  const params = useParams();
  const id = params?.id;
  const { data, isLoading, isFetching } = useGetFaqQuery({ id });

  return (
    <div>
      <NoButton onClick={handleOpen}>Add question and answer</NoButton>
      <Bigsection>
        {isFetching || isLoading ? (
          <h1>Loading</h1>
        ) : (
          <table>
            <tr>
              <th>question En</th>
              <th>question Ar</th>
              <th>answer En</th>
              <th>answer Ar</th>
              <th>Actions</th>
            </tr>
            {mapping()}
          </table>
        )}
      </Bigsection>
      <CreateFaq open={open} handleClose={handleClose} singleItem={selected} />
      <DeleteFaq
        open={openDelete}
        handleClose={handleCloseDelete}
        singleItem={selected}
      />
    </div>
  );
};

export default ShowFaq;
