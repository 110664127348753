import React from "react";
import {
  DeleteButton,
  NoButton,
} from "../../../../../../components/common/Buttons/deleteButton";
import { Bigsection } from "../../../../satisfiedCustomers/components/showData/style";
import {
  useGetCategoryUnityQuery,
  useGetFaqCategoryQuery,
} from "../../../../../../store/RTK/faq";

import { NavLink } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CreateCategoryFaqUnity from "../create";
import DeleteCategoryFaqUnity from "../delete";

const ShowCategory = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openDelete, setDelete] = React.useState(false);
  const handleOpenDelete = () => setDelete(true);
  const handleCloseDelete = () => setDelete(false);
  const [selected, setSelected] = React.useState<any>();

  const mapping: any = () => {
    return data?.data.map((e: any) => {
      return (
        <tr key={e?.id}>
          <td className="content">{e?.title_en}</td>
          <td className="content">{e?.title_ar}</td>
          <td>
            <div className="actions">
              <DeleteButton onClick={() => select(e)}>Delete</DeleteButton>
              <NavLink to={`/home/faq-unity/singleCategory/${e?.id}`}>
                <NoButton>
                  <RemoveRedEyeIcon />
                </NoButton>
              </NavLink>
            </div>
          </td>
        </tr>
      );
    });
  };

  const select = (feed: any) => {
    setSelected(feed);
    handleOpenDelete();
  };

  const { data, isLoading, isFetching } = useGetCategoryUnityQuery({});

  return (
    <div>
      <NoButton onClick={handleOpen}>Create Category</NoButton>
      <Bigsection>
        {isFetching || isLoading ? (
          <h1>Loading</h1>
        ) : (
          <table>
            <tr>
              <th>title English</th>
              <th>title Arabic</th>
              <th>actions</th>
            </tr>
            {mapping()}
          </table>
        )}
      </Bigsection>

      <CreateCategoryFaqUnity
        open={open}
        handleClose={handleClose}
        singleItem={selected}
      />
      <DeleteCategoryFaqUnity
        open={openDelete}
        handleClose={handleCloseDelete}
        singleItem={selected}
      />
    </div>
  );
};

export default ShowCategory;
