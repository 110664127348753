import styled from "styled-components";

export const Bigsection = styled.div`
  .MuiBox-root {
    .btns {
      display: flex;
      gap: 1rem;
      align-items: center;
    }
    .MuiGrid-root {
      .cccccc {
        .key {
          font-size: 16px;
          color: #255557;
          text-transform: capitalize;
          padding: 1rem;
        }
        .value {
          font-size: 14px;
          color: #255557;
          text-transform: capitalize;
          text-wrap: balance;
        }
      }
    }
  }
`;
