import React from "react";
import {
  DeleteButton,
  NoButton,
} from "../../../../components/common/Buttons/deleteButton";
import { Bigsection } from "../../satisfiedCustomers/components/showData/style";
import { useGetAllCategoriesQuery } from "../../../../store/RTK/category";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import DeleteCategory from "../deleteCategoty";
import AddAssetBundle from "../addAssetBundle";
import UpdateCategory from "../updateCategory";
import UpdateCategoryImage from "../updateCategotyImage";
import UpdateCategoryPdf from "../updateCategoryPdf";
const ShowCategoreis = () => {
  const { data, isLoading, isFetching } = useGetAllCategoriesQuery({});
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openAssetBundle, setOpenAssetBundle] = React.useState(false);
  const handleOpenAsset = () => setOpenAssetBundle(true);
  const handleCloseAsset = () => setOpenAssetBundle(false);

  const [openUpdateCategoryMedia, setOpenUpdateCategoryMedia] =
    React.useState(false);
  const handleOpenCategoryMedia = () => setOpenUpdateCategoryMedia(true);
  const handleCloseCategoryMedia = () => setOpenUpdateCategoryMedia(false);

  const [openUpdateCategoryPdf, setOpenUpdateCategoryPdf] =
    React.useState(false);
  const handleOpenCategoryPdf = () => setOpenUpdateCategoryPdf(true);
  const handleCloseCategoryPdf = () => setOpenUpdateCategoryPdf(false);

  const [openUpdateCategory, setOpenUpdateCategory] = React.useState(false);
  const handleOpenCAtegory = () => setOpenUpdateCategory(true);
  const handleCloseCAtegory = () => setOpenUpdateCategory(false);

  const [selected, setSelected] = React.useState<any>();
  const select = (feed: any) => {
    setSelected(feed);
    handleOpen();
  };
  const selectAsset = (feed: any) => {
    setSelected(feed);
    handleOpenAsset();
  };
  const selectUpdateCategory = (feed: any) => {
    setSelected(feed);
    handleOpenCAtegory();
  };

  const selectUpdateCategoryMedia = (feed: any) => {
    setSelected(feed);
    handleOpenCategoryMedia();
  };

  const selectUpdateCategoryPdf = (feed: any) => {
    setSelected(feed);
    handleOpenCategoryPdf();
  };
  const mapping: any = () => {
    return data?.data.map((e: any) => {
      return (
        <tr key={e?.id}>
          <td className="content">{e?.title}</td>
          <td className="content">{e?.qr_code}</td>
          <td className="content">{e?.description}</td>
          <td className="content">{e?.targets_description}</td>
          <td>
            <div className="actions">
              <Link to={`/home/cetegory/singleCategory/${e?.id}`}>
                <Tooltip title="Show Single" placement="top">
                  <RemoveRedEyeIcon />
                </Tooltip>
              </Link>
              <DeleteButton onClick={() => select(e)}>Delete</DeleteButton>
              <NoButton onClick={() => selectAsset(e)}>Asset-Bundle</NoButton>
              <NoButton onClick={() => selectUpdateCategory(e)}>
                Update-Category
              </NoButton>
              <NoButton onClick={() => selectUpdateCategoryMedia(e)}>
                Update-Category cover
              </NoButton>
              <NoButton onClick={() => selectUpdateCategoryPdf(e)}>
                Update-Category pdf
              </NoButton>
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    <Bigsection>
      {isFetching || isLoading ? (
        <h1>Loading</h1>
      ) : (
        <table>
          <tr>
            <th>title</th>
            <th>qr_code</th>
            <th>description</th>
            <th>targets_description</th>
            <th>Actions</th>
          </tr>
          {mapping()}
        </table>
      )}

      <DeleteCategory
        open={open}
        handleClose={handleClose}
        singleItem={selected}
      />
      <AddAssetBundle
        open={openAssetBundle}
        handleClose={handleCloseAsset}
        singleItem={selected}
      />
      <UpdateCategoryImage
        open={openUpdateCategoryMedia}
        handleClose={handleCloseCategoryMedia}
        singleItem={selected}
        key={selected}
      />
      <UpdateCategory
        open={openUpdateCategory}
        handleClose={handleCloseCAtegory}
        singleItem={selected}
        key={selected}
      />

      <UpdateCategoryPdf
        open={openUpdateCategoryPdf}
        handleClose={handleCloseCategoryPdf}
        singleItem={selected}
        key={selected}
      />
    </Bigsection>
  );
};

export default ShowCategoreis;
