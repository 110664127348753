import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Modal } from "@mui/material";
import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import StanderInput from "../../../../components/common/standerInput";
import {
  useUpdateBlogMutation,
  useUpdateDescriptionMutation,
} from "../../../../store/RTK/blogs";
import { SubmitButton } from "../../../../components/common/Buttons/SubmitButton";
import { toast } from "react-toastify";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const UpdateBlog = ({ open, handleOpen, handleClose, singleBlog }: any) => {
  const schema = yup.object({
    title_en: yup.string().required("title_en is required"),
    title_ar: yup.string().required("title_ar is required"),
    author_en: yup.string().required("author_en is required"),
    author_ar: yup.string().required("author_ar is required"),
  });

  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const [updateBlog, { isLoading, isSuccess, isError }] =
    useUpdateBlogMutation();

  useEffect(() => {
    methods.setValue("title_ar", singleBlog?.title_ar);
    methods.setValue("title_en", singleBlog?.title_en);
    methods.setValue("author_ar", singleBlog?.author_ar);
    methods.setValue("author_en", singleBlog?.author_en);
  }, [singleBlog]);

  const onSubmit = async (data: any) => {
    try {
      const sendData = {
        body: data,
        id: singleBlog?.id,
      };
      await updateBlog(sendData);
    } catch (error: any) {}
  };
  useEffect(() => {
    if (isSuccess) {
      toast.success("Doneeeeeeeeeeeee");
      handleClose();
    }
    if (isError) {
      toast.error("Errrrrrr");
    }
  }, [isSuccess, isError]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Grid container columns={12} spacing={3}>
                <Grid item xs={12} style={{}}>
                  <h4>Edit Description</h4>
                </Grid>

                <Grid item xs={12}>
                  <StanderInput
                    name="title_en"
                    placeholder="title_en"
                    Label="title_en"
                    type="string"
                    defaultValue=""
                  ></StanderInput>
                </Grid>
                <Grid item xs={12}>
                  <StanderInput
                    name="title_ar"
                    placeholder="title_ar"
                    Label="title_ar"
                    type="string"
                    defaultValue=""
                  ></StanderInput>
                </Grid>
                <Grid item xs={12}>
                  <StanderInput
                    name="author_en"
                    placeholder="author_en"
                    Label="author_en"
                    type="string"
                    defaultValue=""
                  ></StanderInput>
                </Grid>
                <Grid item xs={12}>
                  <StanderInput
                    name="author_ar"
                    placeholder="author_ar"
                    Label="author_ar"
                    type="string"
                    defaultValue=""
                  ></StanderInput>
                </Grid>
                <Grid item xs={12}>
                  <SubmitButton type="submit" fullWidth>
                    {isLoading ? "creating" : "Save"}
                  </SubmitButton>{" "}
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Box>
      </Modal>
    </div>
  );
};

export default UpdateBlog;
