import React from "react";
import { toast } from "react-toastify";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, TextField } from "@mui/material";
import StanderInput from "../../../../../../../components/common/standerInput";
import { SubmitButton } from "../../../../../../../components/common/Buttons/SubmitButton";
import { useCreateCategoryUnityQuestionMutation } from "../../../../../../../store/RTK/faq";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Switch from "@mui/material/Switch";

const SUPPORTED_FORMATS = ["image/png", "image/jpeg", "image/jpg"];

const CreateFaqUnity = () => {
  const schema = yup.object({
    que_en: yup.string().required("que_en is required"),
    que_ar: yup.string().required("que_ar is required"),
    answers: yup
      .array()
      .required("Array is required")
      .min(1, "Array must have at least 1 item")
      .max(10, "Array must have at most 5 items")
      .of(
        yup.object().shape({
          ans_en: yup.string().required("Answer is reqired is required"),
          ans_ar: yup.string().required("Answer is reqired is required"),
          is_write: yup.string(),
          media: yup
            .mixed()
            .test("fileType", "Unsupported file format", (value: any) => {
              if (!value) return true; // If no file is provided, don't perform validation
              return SUPPORTED_FORMATS.includes(value.type);
            }),
        })
      ),
  });
  const methods = useForm<any>({
    mode: "onChange",
    // resolver: yupResolver(schema),
    defaultValues: {
      answers: [{ ans_en: "", ans_ar: "", media: "", is_write: "" }],
    },
  });
  console.log("Errs", methods?.formState?.errors);
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const id = location?.pathname.split("/")[4];
  const validationErr = methods.formState.errors;
  const control = methods.control;
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: "answers",
    }
  );
  const [error, setError] = React.useState([]);
  const [createCategoryUnityQuestion, { isLoading, isSuccess, isError }] =
    useCreateCategoryUnityQuestionMutation();
  const category_id = {
    category_id: id,
  };
  const onSubmit = async (data: any) => {
    try {
      const formData = new FormData();
      formData.append("category_id", id);
      formData.append("que_en", data?.que_en);
      formData.append("que_ar", data?.que_ar);
      formData.append("is_skip", data?.is_skip);
      data?.answers?.map((e: any, i: number) => {
        for (const property in e) {
          formData.append(
            `answers[${i}][${property}]`,
            property == "is_write" ? Number(e[property]) : e[property]
          );
        }
      });
      await createCategoryUnityQuestion(formData);
      console.log("Send data", data);
      // await methods.reset();
    } catch (error: any) {
      toast.error("something went wrong");
      setError(error?.data?.errors);
    }
  };
  React.useEffect(() => {
    if (isSuccess) {
      toast.success("عاش يابو الزووك");
      navigate(`/home/faq-unity/singleCategory/${id}/show`);
    }
    if (isError) {
      toast.error("يووووة بقي");
    }
  }, [isSuccess, isError]);
  console.log("fields", fields);
  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container columns={12} spacing={3}>
            <Grid item xs={12} style={{}}>
              <h4>Add Account type</h4>
            </Grid>
            <Grid item xs={12}>
              <StanderInput
                name="que_en"
                placeholder="que_en"
                Label="que_en"
                type="string"
                defaultValue=""
              ></StanderInput>
              <StanderInput
                name="que_ar"
                placeholder="que_ar"
                Label="que_ar"
                type="string"
                defaultValue=""
              ></StanderInput>
            </Grid>

            <Grid item xs={12}>
              <label>is_skip</label>
              <Controller
                control={control}
                name={`is_skip`}
                defaultValue={Number(false)}
                render={({ field: { value, onChange, ...field } }) => {
                  return (
                    <Switch
                      onChange={(e) => {
                        onChange(Number(e.target.checked));
                      }}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <button
                type="button"
                onClick={() =>
                  append({ ans_en: "", ans_ar: "", media: "", is_write: "" })
                }
              >
                add Answer
              </button>
              {fields.map((field, index) => (
                <div key={field.id}>
                  <StanderInput
                    name={`answers.[${index}].ans_en`}
                    // placeholder="description"
                    Label={"ans_en"}
                    type="string"
                    defaultValue=""
                  />
                  <StanderInput
                    name={`answers.[${index}].ans_ar`}
                    // placeholder="description"
                    Label="ans_ar"
                    type="string"
                    defaultValue=""
                  />

                  <Grid item xs={12}>
                    <label>is_write</label>
                    <Controller
                      control={control}
                      name={`answers.[${index}].is_write`}
                      defaultValue={Number(false)}
                      render={({ field: { value, onChange, ...field } }) => {
                        return (
                          <Switch
                            onChange={(e) => {
                              onChange(Number(e.target.checked));
                            }}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name={`answers.[${index}].media`}
                      render={({ field: { value, onChange, ...field } }) => {
                        return (
                          <TextField
                            {...field}
                            value={value?.fileName}
                            onChange={(event: any) => {
                              onChange(event.target.files[0]);
                            }}
                            type="file"
                            id="picture"
                          />
                        );
                      }}
                    />
                    <p style={{ color: "red", fontSize: "0.7rem" }}>
                      {" "}
                      {validationErr?.media ? (
                        <>{validationErr?.media.message}</>
                      ) : (
                        <></>
                      )}
                    </p>
                  </Grid>
                  <button type="button" onClick={() => remove(index)}>
                    Delete
                  </button>
                </div>
              ))}
            </Grid>

            <Grid item xs={12}>
              <SubmitButton type="submit" fullWidth>
                {isLoading ? "creating" : "Save"}
              </SubmitButton>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </div>
  );
};

export default CreateFaqUnity;
