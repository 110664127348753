import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../helper/BaseQuery";

export const nontificationApi = createApi({
  reducerPath: "nontificationApi",
  baseQuery,
  tagTypes: ["notification"],
  endpoints: (builder) => ({
    getUsers: builder.query<any, any>({
      query: ({ page, search }) => ({
        url: "/notification/get-users",
        method: "get",
        params: {
          page,
          search: search,
        },
      }),
    }),
    createNotification: builder.mutation<any, any>({
      query: (body) => ({
        url: "/notification/notify",
        method: "POST",
        body,
      }),
      invalidatesTags: ["notification"],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useCreateNotificationMutation,
} = nontificationApi;
