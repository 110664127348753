import React, { useEffect } from "react";
import {
  useCreateCouponMutation,
  useGetHelpcouponQuery,
  useLazyGenerateCodeQuery,
} from "../../../store/RTK/copoun";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Grid } from "@mui/material";
import StanderInput from "../../../components/common/standerInput";
import { SubmitButton } from "../../../components/common/Buttons/SubmitButton";
import SelectInput from "../../../components/common/SelectInput/SelectInput";
import StanderTextArea from "../../../components/common/standardTextArea";
import { toast } from "react-toastify";

const AddCoupoun = () => {
  const { data, isLoading } = useGetHelpcouponQuery({});
  const [generateCode, { data: codeGenerate, isLoading: codeGenerateLoading }] =
    useLazyGenerateCodeQuery({});
  const [createCoupon, { isLoading: CreatecodeGenerateLoading }] =
    useCreateCouponMutation();
  const schema = yup.object({
    account_type_id: yup.string().required("account_type_id is required"),
    title: yup.string().required("title is required"),
    description: yup.string().required("description is required"),
    coupon_code: yup.string().required("coupon_code is required"),
    number_user: yup.number().required("number_user is required"),
    percentage: yup.number().required("percentage is required"),
    currency: yup.string().required("currency is required"),
  });
  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data: any) => {
    console.log("data", data);
    try {
      createCoupon(data).unwrap();
      toast.success("Done");
    } catch (e) {
      toast.error("something wrong");
    }
  };
  let accountTypes = data?.data?.account_type || [];
  let currency = data?.data?.currency || [];
  const newCurrency = () => {
    return currency?.map((item: string) => {
      return { id: item, name: item };
    });
  };
  const generateCocdeNow = () => {
    generateCode("");
  };
  useEffect(() => {
    if (codeGenerate?.data) methods.setValue("coupon_code", codeGenerate?.data);
  }, [codeGenerate]);
  console.log("newCurrency", newCurrency());

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container columns={12} spacing={3}>
            <Grid item xs={12}>
              <SelectInput
                name="account_type_id"
                data={accountTypes}
                label="Account Types"
              ></SelectInput>
            </Grid>
            <Grid item xs={12}>
              <StanderInput
                // required
                name="title"
                placeholder="title"
                Label="title"
                type="string"
                defaultValue=""
              ></StanderInput>
            </Grid>
            <Grid item xs={12}>
              <StanderTextArea
                // required
                name="description"
                placeholder="description"
                Label="description"
                type="string"
                defaultValue=""
              ></StanderTextArea>
            </Grid>
            <Grid item xs={12}>
              <StanderInput
                // required
                name="coupon_code"
                placeholder="coupon code"
                Label="coupon code"
                type="string"
                defaultValue=""
              ></StanderInput>
              <button type="button" onClick={generateCocdeNow}>
                {codeGenerateLoading ? "Generating" : "Generate Code"}
              </button>
            </Grid>
            <Grid item xs={12}>
              <StanderInput
                // required
                name="number_user"
                placeholder="number_user"
                Label="number_user"
                type="number"
                defaultValue=""
              ></StanderInput>
            </Grid>
            <Grid item xs={12}>
              <StanderInput
                // required
                name="percentage"
                placeholder="percentage"
                Label="percentage"
                type="number"
                defaultValue=""
              ></StanderInput>
            </Grid>

            <Grid item xs={12}>
              <SelectInput
                name="currency"
                data={newCurrency()}
                label="currency"
              ></SelectInput>
            </Grid>

            <Grid item xs={12}>
              <SubmitButton type="submit" fullWidth>
                {CreatecodeGenerateLoading ? "creating" : "Save"}
              </SubmitButton>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </div>
  );
};

export default AddCoupoun;
