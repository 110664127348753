import React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { Box, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useDeleteCategoryUnityMutation } from "../../../../../../store/RTK/faq";
import { DeleteButton } from "../../../../../../components/common/Buttons/deleteButton";
import { SubmitButton } from "../../../../../../components/common/Buttons/SubmitButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const DeleteCategoryFaqUnity = ({ open, handleClose, singleItem }: any) => {
  const schema = yup.object({
    question: yup.string().required("question is required"),
    answer: yup.string().required("answer is required"),
  });
  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  const [deleteFaqCategory, { isSuccess, isLoading, isError, data }] =
    useDeleteCategoryUnityMutation();
  const deleteCategory = async () => {
    try {
      await deleteFaqCategory(singleItem?.id);
      methods.reset();
    } catch (error: any) {
      toast.error("something went wrong");
    }
  };
  React.useEffect(() => {
    if (isSuccess) {
      toast.success("Faq has been created");
      handleClose();
    }
    if (isError) {
      toast.error("something went wrong");
    }
  }, [isSuccess, isError]);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid container columns={12} spacing={3}>
        <Grid item xs={12} style={{}}>
          <h4>Add Feedback</h4>
        </Grid>
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure you want to delete this feedBack (
            {singleItem?.title_en})
          </Typography>
          {/* <Typography
            id="modal-modal-description"
            sx={{ mt: 2, textAlign: "center" }}
          > */}
          <div
            className="btns"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              justifyContent: "center",
              padding: "1rem 0",
            }}
          >
            <DeleteButton onClick={deleteCategory}>
              {isLoading ? "Deleting..." : "Delete"}
            </DeleteButton>
            <SubmitButton className="btn" onClick={handleClose}>
              No
            </SubmitButton>
          </div>
          {/* </Typography> */}
        </Box>
      </Grid>
    </Modal>
  );
};

export default DeleteCategoryFaqUnity;
