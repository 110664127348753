import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../helper/BaseQuery";

export const OrderApi = createApi({
  reducerPath: "OrderApi",
  baseQuery,
  tagTypes: ["orders"],
  endpoints: (builder) => ({
    getOrdersRequests: builder.query({
      query: ({ page, status }) => ({
        url: "orders",
        method: "get",
        params: {
          page,
          status,
        },
      }),
      providesTags: ["orders"],
    }),
    getSingleOrderRequest: builder.query({
      query: (id) => ({
        url: `orders/show/${id}`,
        method: "get",
      }),
      providesTags: ["orders"],
    }),
    updateOrderStatus: builder.mutation<any, any>({
      query: ({ body, id }) => ({
        url: `orders/change-status/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["orders"],
    }),
  }),
});

export const {
  useGetOrdersRequestsQuery,
  useGetSingleOrderRequestQuery,
  useUpdateOrderStatusMutation,
} = OrderApi;
