import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../helper/BaseQuery";

export const songsApi = createApi({
  reducerPath: "songsApi",
  baseQuery,
  tagTypes: ["songs"],
  endpoints: (builder) => ({
    getSongs: builder.query({
      query: ({ page, search }) => ({
        url: "songs",
        method: "get",
        params: {
          page,
          search,
        },
      }),
      providesTags: ["songs"],
    }),
    storeSong: builder.mutation<any, any>({
      query: (body) => ({
        url: `songs/store`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["songs"],
    }),
    getSingleSong: builder.query({
      query: (id) => ({
        url: `songs/show/${id}`,
        method: "get",
      }),
      providesTags: ["songs"],
    }),
    updateSong: builder.mutation<any, any>({
      query: ({ body, id }) => ({
        url: `songs/update/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["songs"],
    }),
    deleteSong: builder.mutation<any, any>({
      query: (id) => ({
        url: `songs/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["songs"],
    }),
  }),
});

export const {
  useGetSongsQuery,
  useLazyGetSongsQuery,
  useStoreSongMutation,
  useGetSingleSongQuery,
  useUpdateSongMutation,
  useDeleteSongMutation,
} = songsApi;
