import { Box, Modal, Typography } from "@mui/material";
import React, { useEffect, useLayoutEffect } from "react";
import {
  useGetAccountTypesQuery,
  useUpdateSubscriptionMutation,
} from "../../../store/RTK/subscription";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { toast } from "react-toastify";
import { Grid, TextField } from "@mui/material";
import { Bigsection } from "../../homePage/category/createCategory/style";
import StanderInput from "../../../components/common/standerInput";
import { SubmitButton } from "../../../components/common/Buttons/SubmitButton";
import SelectInput from "../../../components/common/SelectInput/SelectInput";
import {
  useCreateCouponMutation,
  useGetHelpcouponQuery,
  useLazyGenerateCodeQuery,
  useUpdateCouponMutation,
} from "../../../store/RTK/copoun";
import StanderTextArea from "../../../components/common/standardTextArea";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
  height: "80%",
  display: "block",
};

const EditCoupon = ({ open, handleClose, singleItem }: any) => {
  const { data, isLoading } = useGetHelpcouponQuery({});
  const [generateCode, { data: codeGenerate, isLoading: codeGenerateLoading }] =
    useLazyGenerateCodeQuery({});
  const [updateCoupon, { isLoading: CreatecodeGenerateLoading }] =
    useUpdateCouponMutation();
  console.log("singleItem", singleItem);
  const schema = yup.object({
    account_type_id: yup.string().required("account_type_id is required"),
    title: yup.string().required("title is required"),
    description: yup.string().required("description is required"),
    coupon_code: yup.string().required("coupon_code is required"),
    number_user: yup.number().required("number_user is required"),
    percentage: yup.number().required("percentage is required"),
    currency: yup.string().required("currency is required"),
  });
  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      contents: [{ contents: "" }],
    },
  });

  const onSubmit = async (data: any) => {
    const sendData = {
      id: singleItem?.id,
      data: data,
    };
    await updateCoupon(sendData).unwrap();
    toast.success("Edited");
    await handleClose();
    try {
      console.log("sendData", sendData);
    } catch (e) {
      toast.error("Err");
    }
  };
  let accountTypes = data?.data?.account_type || [];
  let currency = data?.data?.currency || [];
  const newCurrency = () => {
    return currency?.map((item: string) => {
      return { id: item, name: item };
    });
  };
  const generateCocdeNow = () => {
    generateCode("");
  };
  useEffect(() => {
    if (codeGenerate?.data) methods.setValue("coupon_code", codeGenerate?.data);
  }, [codeGenerate]);
  useLayoutEffect(() => {
    methods.setValue("account_type_id", singleItem?.account_type_id);
    methods.setValue("title", singleItem?.title);
    methods.setValue("description", singleItem?.description);
    methods.setValue("coupon_code", singleItem?.coupon_code);
    methods.setValue("number_user", singleItem?.number_user);
    methods.setValue("percentage", singleItem?.percentage);
    methods.setValue("currency", singleItem?.currency);
  }, [singleItem]);
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Grid container columns={12} spacing={3}>
                  <Grid item xs={12}>
                    <SelectInput
                      name="account_type_id"
                      data={accountTypes}
                      label="Account Types"
                    ></SelectInput>
                  </Grid>
                  <Grid item xs={12}>
                    <StanderInput
                      // required
                      name="title"
                      placeholder="title"
                      Label="title"
                      type="string"
                      defaultValue=""
                    ></StanderInput>
                  </Grid>
                  <Grid item xs={12}>
                    <StanderTextArea
                      // required
                      name="description"
                      placeholder="description"
                      Label="description"
                      type="string"
                      defaultValue=""
                    ></StanderTextArea>
                  </Grid>
                  <Grid item xs={12}>
                    <StanderInput
                      // required
                      name="coupon_code"
                      placeholder="coupon code"
                      Label="coupon code"
                      type="string"
                      defaultValue=""
                    ></StanderInput>
                    <button type="button" onClick={generateCocdeNow}>
                      {codeGenerateLoading ? "Generating" : "Generate Code"}
                    </button>
                  </Grid>
                  <Grid item xs={12}>
                    <StanderInput
                      // required
                      name="number_user"
                      placeholder="number_user"
                      Label="number_user"
                      type="number"
                      defaultValue=""
                    ></StanderInput>
                  </Grid>
                  <Grid item xs={12}>
                    <StanderInput
                      // required
                      name="percentage"
                      placeholder="percentage"
                      Label="percentage"
                      type="number"
                      defaultValue=""
                    ></StanderInput>
                  </Grid>

                  <Grid item xs={12}>
                    <SelectInput
                      name="currency"
                      data={newCurrency()}
                      label="currency"
                    ></SelectInput>
                  </Grid>

                  <Grid item xs={12}>
                    <SubmitButton type="submit" fullWidth>
                      {CreatecodeGenerateLoading ? "creating" : "Save"}
                    </SubmitButton>
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default EditCoupon;
