import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../helper/BaseQuery";

export const productApi = createApi({
  reducerPath: "productApi",
  baseQuery,
  tagTypes: ["product"],
  endpoints: (builder) => ({
    geProducts: builder.query({
      query: ({ page, search }) => ({
        url: "product/index",
        method: "get",
        params: {
          page,
          search,
        },
      }),
      providesTags: ["product"],
    }),
    storeProduct: builder.mutation<any, any>({
      query: (body) => ({
        url: `product/store`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["product"],
    }),
    getSingleProduct: builder.query({
      query: (id) => ({
        url: `product/show/${id}`,
        method: "get",
      }),
      providesTags: ["product"],
    }),
    updateProduct: builder.mutation<any, any>({
      query: ({ body, id }) => ({
        url: `product/update/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["product"],
    }),
    updateStatusProduct: builder.mutation<any, any>({
      query: (id) => ({
        url: `product/change-status/${id}`,
        method: "PUT",
      }),
      invalidatesTags: ["product"],
    }),
    deleteProduct: builder.mutation<any, any>({
      query: (id) => ({
        url: `product/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["product"],
    }),
    getHelpDataProduct: builder.query({
      query: () => ({
        url: `product/help-data`,
        method: "get",
      }),
      // providesTags: ["product"],
    }),
  }),
});

export const {
  useLazyGeProductsQuery,
  useGeProductsQuery,
  useGetSingleProductQuery,
  useStoreProductMutation,
  useUpdateProductMutation,
  useDeleteProductMutation,
  useGetHelpDataProductQuery,
  useUpdateStatusProductMutation,
} = productApi;
