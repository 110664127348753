import React from "react";
import { Bigsection } from "../../../../../satisfiedCustomers/components/showData/style";
import {
  DeleteButton,
  NoButton,
} from "../../../../../../../components/common/Buttons/deleteButton";
import { NavLink, useParams } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useGetSingleCategoryUnityQuestionQuery } from "../../../../../../../store/RTK/faq";
import UpdateQuestion from "../updateQuestion";
import UpdateAnswer from "./components/updateAnswer";
import UpdateMedia from "./components/updateMedia";

const ShowSingleQuestion = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openUpdated, setUpdated] = React.useState(false);
  const handleOpenUpdated = () => setUpdated(true);
  const handleCloseUpdated = () => setUpdated(false);

  const [openUpdatedMedia, setUpdatedMedia] = React.useState(false);
  const handleOpenUpdatedMedia = () => setUpdatedMedia(true);
  const handleCloseUpdatedMedia = () => setUpdatedMedia(false);

  const [selected, setSelected] = React.useState<any>();
  const params = useParams();
  const id = params?.id;

  const updateSelect = (e: any) => {
    setSelected(e);
    handleOpenUpdated();
  };
  const updateMediaSelect = (e: any) => {
    setSelected(e);
    handleOpenUpdatedMedia();
  };

  const mapping: any = () => {
    return data?.data?.answers.map((e: any) => {
      return (
        <tr key={e?.id}>
          <td className="content">{e?.ans_en}</td>
          <td className="content">{e?.ans_ar}</td>
          <td className="content">{e?.is_write}</td>
          <td className="content">
            <img src={e?.media?.file_path} alt="" />
          </td>
          <td className="actions">
            {/* <DeleteButton onClick={() => deleteSelect(e)}>Delete </DeleteButton> */}
            {/* <NavLink to={`/home/faqs/show/${e?.id}`}>
              <NoButton>
                <RemoveRedEyeIcon />
              </NoButton>
            </NavLink> */}
            <NoButton
              onClick={() => {
                updateSelect(e);
              }}
            >
              Update Answer
            </NoButton>
            <NoButton
              onClick={() => {
                updateMediaSelect(e);
              }}
            >
              Update Media
            </NoButton>
          </td>
        </tr>
      );
    });
  };

  const select = (feed: any) => {
    setSelected(feed);
    handleOpenUpdated();
  };
  const { data, isLoading, isFetching } =
    useGetSingleCategoryUnityQuestionQuery(id);
  console.log("data", data?.data);
  return (
    <>
      <Bigsection>
        <h1 style={{ textAlign: "center" }}>Single Question</h1>{" "}
        <h3 style={{ textAlign: "center" }}>English: {data?.data?.que_en}</h3>
        <h3 style={{ textAlign: "center" }}>Arabic: {data?.data?.que_ar}</h3>
        {isFetching || isLoading ? (
          <h1>Loading</h1>
        ) : (
          <table>
            <tr>
              <th>Answer English</th>
              <th>Answer Arabic</th>
              <th>Is write</th>
              <th>Media</th>
              <th>actions</th>
            </tr>
            {mapping()}
          </table>
        )}
      </Bigsection>

      <UpdateAnswer
        open={openUpdated}
        handleClose={handleCloseUpdated}
        singleItem={selected}
      />
      <UpdateMedia
        open={openUpdatedMedia}
        handleClose={handleCloseUpdatedMedia}
        singleItem={selected}
      />
    </>
  );
};

export default ShowSingleQuestion;
