import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Modal, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import StanderInput from "../../../../components/common/standerInput";
import {
  useUpdateBlogMediaMutation,
  useUpdateDescriptionMutation,
} from "../../../../store/RTK/blogs";
import { SubmitButton } from "../../../../components/common/Buttons/SubmitButton";
import { toast } from "react-toastify";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const SUPPORTED_FORMATS = ["image/png", "image/jpeg", "image/jpg"];

const UpdateImg = ({ open, handleOpen, handleClose, singleBlog }: any) => {
  const schema = yup.object({
    media: yup
      .mixed()
      .required("A file is required")
      .test("fileType", "Unsupported file format", (value: any) => {
        if (!value) return true; // If no file is provided, don't perform validation
        return SUPPORTED_FORMATS.includes(value.type);
      }),
  });

  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const [updateBlogMedia, { isLoading, isSuccess, isError }] =
    useUpdateBlogMediaMutation();

  const onSubmit = async (data: any) => {
    const formData = new FormData();
    try {
      formData.append("media", data?.media);
      formData.append("_method", "put");
      const sendData = {
        id: singleBlog?.id,
        body: formData,
      };

      await updateBlogMedia(sendData);
    } catch (error: any) {}
  };
  useEffect(() => {
    if (isSuccess) {
      toast.success("Doneeeeeeeeeeeee");
      handleClose();
    }
    if (isError) {
      toast.error("Errrrrrr");
    }
  }, [isSuccess, isError]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Grid container columns={12} spacing={3}>
                <Grid item xs={12} style={{}}>
                  <h4>Edit img</h4>
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    control={methods.control}
                    name={"media"}
                    render={({ field: { value, onChange, ...field } }) => {
                      return (
                        <TextField
                          {...field}
                          value={value?.fileName}
                          onChange={(event: any) => {
                            onChange(event.target.files[0]);
                          }}
                          type="file"
                          id="picture"
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SubmitButton type="submit" fullWidth>
                    {isLoading ? "creating" : "Save"}
                  </SubmitButton>{" "}
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Box>
      </Modal>
    </div>
  );
};

export default UpdateImg;
