import React, { useEffect } from "react";
import { toast } from "react-toastify";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Modal } from "@mui/material";
import StanderInput from "../../../../../../../components/common/standerInput";
import { SubmitButton } from "../../../../../../../components/common/Buttons/SubmitButton";
import {
  useCreateCategoryUnityQuestionMutation,
  useUpdateCategoryUnityQuestionMutation,
} from "../../../../../../../store/RTK/faq";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const UpdateQuestion = ({ open, handleClose, singleItem }: any) => {
  const schema = yup.object({
    que_en: yup.string().required("que_en is required"),
    que_ar: yup.string().required("que_ar is required"),
  });
  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const params = useParams();
  const id = params?.id;
  const location = useLocation();
  const validationErr = methods.formState.errors;
  const control = methods.control;

  const [error, setError] = React.useState([]);
  const [updateCategoryUnityQuestion, { isLoading, isSuccess, isError }] =
    useUpdateCategoryUnityQuestionMutation();
  const category_id = {
    category_id: id,
  };
  console.log("singleItem", singleItem?.category_id);
  useEffect(() => {
    methods?.setValue("que_en", singleItem?.que_en);
    methods?.setValue("que_ar", singleItem?.que_ar);
  }, [singleItem]);
  const onSubmit = async (data: any) => {
    try {
      const sendData = {
        id: singleItem?.id,
        body: data,
      };
      await updateCategoryUnityQuestion(sendData);
      console.log("Send data", data);
      // await methods.reset();
    } catch (error: any) {
      toast.error("something went wrong");
      setError(error?.data?.errors);
    }
  };
  React.useEffect(() => {
    if (isSuccess) {
      toast.success("عاش يابو الزووك");
      navigate(`/home/faq-unity/singleCategory/${id}/show`);
    }
    if (isError) {
      toast.error("يووووة بقي");
    }
  }, [isSuccess, isError]);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={style}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid container columns={12} spacing={3}>
              <Grid item xs={12} style={{}}>
                <h4>Add Account type</h4>
              </Grid>
              <Grid item xs={12}>
                <StanderInput
                  name="que_en"
                  placeholder="que_en"
                  Label="que_en"
                  type="string"
                  defaultValue=""
                ></StanderInput>
                <StanderInput
                  name="que_ar"
                  placeholder="que_ar"
                  Label="que_ar"
                  type="string"
                  defaultValue=""
                ></StanderInput>
              </Grid>

              <Grid item xs={12}>
                <SubmitButton type="submit" fullWidth>
                  {isLoading ? "creating" : "Save"}
                </SubmitButton>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Box>
    </Modal>
  );
};

export default UpdateQuestion;
