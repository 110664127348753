import React from "react";
import { Bigsection } from "../homePage/components/nav/style";
import { NavLink, Outlet } from "react-router-dom";
import AllPayment from "./components/allPayment";

const PaymentMethod = () => {
  return (
    <div>
      <AllPayment />
    </div>
  );
};

export default PaymentMethod;
