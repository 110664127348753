import React from "react";
import {
  useGetSingleColoringActivitesQuery,
  useGetSingleSubColoringActivitesQuery,
} from "../../../../../store/RTK/coloringActivities";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  DeleteButton,
  NoButton,
} from "../../../../../components/common/Buttons/deleteButton";
import { Bigsection } from "../../../satisfiedCustomers/components/showData/style";
import { Grid } from "@mui/material";
import { SubStyle } from "./style";
import CreateSub from "./components/addSubCategory";
import { AddButton } from "../../../../../components/common/Buttons/AddButton";
import DeleteSub from "./components/deleteSub";
import EditSub from "./components/editSub";

const Single = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetSingleColoringActivitesQuery(id);
  const navigate = useNavigate();

  const { data: singleSubData, isLoading: singleSubLoading } =
    useGetSingleSubColoringActivitesQuery({ id });
  console.log("singleSubData", singleSubData?.data);
  const [selected, setSelected] = React.useState<any>();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openEdit, setOpenEdit] = React.useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  const [openDelete, setOpenDelete] = React.useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);

  const select = (feed: any) => {
    setSelected(feed);
    handleOpenDelete();
  };

  const selectEdit = (feed: any) => {
    setSelected(feed);
    handleOpenEdit();
  };
  const mapping: any = () => {
    return singleSubData?.data?.map((e: any) => {
      return (
        <tr key={e?.id}>
          <td>{e?.title_ar}</td>
          <td>{e?.title_en}</td>
          <td>
            <a href={e?.media_cover?.file_path} target="_blank">
              <img src={e?.media_cover?.file_path} alt="" />
            </a>
          </td>
          <td className="">
            <div className="actions">
              <DeleteButton onClick={() => select(e)}>Delete</DeleteButton>
              <NoButton onClick={() => selectEdit(e)}>Edit</NoButton>
            </div>
          </td>
        </tr>
      );
    });
  };
  console.log("mapping", mapping());
  return (
    <>
      <SubStyle>
        Single coloring Category Data
        <Grid
          container
          columns={12}
          justifyContent={"space-between"}
          alignItems={"stretch"}
          spacing={2}
        >
          <Grid item xs={12}>
            <div className="headerNav">
              <NoButton
                onClick={() => {
                  navigate(-1);
                }}
              >
                Go back
              </NoButton>
              <AddButton onClick={handleOpen}>Create Sub</AddButton>
            </div>
          </Grid>
          <Grid item xs={12} md={4} lg={3} sx={{ border: " 1px solid black" }}>
            <p className="key">
              title ar:
              <span className="value">{data?.data?.title_ar}</span>
            </p>
            <p className="key">
              title en:
              <span className="value">{data?.data?.title_en}</span>
            </p>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            lg={3}
            sx={{ border: " 1px solid black", textWrap: "wrap" }}
          >
            <p className="key">
              description Ar:
              <span className="valueDescription">
                {data?.data?.description_ar}
              </span>
            </p>
            <p className="key">
              description En:
              <span className="valueDescription">
                {data?.data?.description_en}
              </span>
            </p>
          </Grid>
          <Grid item xs={12} md={4} lg={3} sx={{ border: " 1px solid black" }}>
            <p className="key">
              Cover:
              <span className="value">
                <a href={data?.data?.media_cover?.file_path} target="_blank">
                  Cover link
                </a>
              </span>
            </p>
          </Grid>
          <Grid item xs={12} md={4} lg={3} sx={{ border: " 1px solid black" }}>
            <p className="key">
              Pdf:
              <span className="value">
                <a href={data?.data?.media_pdf?.file_path} target="_blank">
                  Pdf link
                </a>
              </span>
            </p>
          </Grid>
          <Grid item xs={12}>
            <h1>Sub</h1>
            <table>
              <tr>
                <th>Title Ar</th>
                <th>Title En</th>
                <th>Cover</th>
                <th>actions</th>
              </tr>
              {mapping()}
            </table>
          </Grid>
        </Grid>
      </SubStyle>
      <CreateSub open={open} handleClose={handleClose} id={id} />
      <EditSub
        open={openEdit}
        handleClose={handleCloseEdit}
        selected={selected}
      />
      <DeleteSub
        open={openDelete}
        handleClose={handleCloseDelete}
        singleItem={selected}
        key={selected?.id}
      />
    </>
  );
};

export default Single;
