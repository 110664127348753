import React from "react";

import { NavLink, useParams } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  DeleteButton,
  NoButton,
} from "../../../../../../../components/common/Buttons/deleteButton";
import { useGetCategoryUnityQuestionQuery } from "../../../../../../../store/RTK/faq";
import { Bigsection } from "../../../../../satisfiedCustomers/components/showData/style";
import UpdateQuestion from "../updateQuestion";
import DeleteQuestionUnity from "../delete";
import UpdateAnswers from "../updateAnswers";

const ShowSinlgeCategory = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openUpdated, setUpdated] = React.useState(false);
  const handleOpenUpdated = () => setUpdated(true);
  const handleCloseUpdated = () => setUpdated(false);

  // const [openUpdatedAnswer, setUpdatedAnswer] = React.useState(false);
  // const handleOpenUpdatedAnswer = () => setUpdatedAnswer(true);
  // const handleCloseUpdatedAnswer = () => setUpdatedAnswer(false);

  const [selected, setSelected] = React.useState<any>();
  const params = useParams();
  const id = params?.id;
  const updateSelect = (e: any) => {
    setSelected(e);
    handleOpenUpdated();
  };
  // const updateAnsersSelect = (e: any) => {
  //   setSelected(e);
  //   handleOpenUpdatedAnswer();
  // };
  const deleteSelect = (e: any) => {
    setSelected(e);
    handleOpen();
  };
  const mapping: any = () => {
    return data?.data.map((e: any) => {
      return (
        <tr key={e?.id}>
          <td className="content">{e?.que_en}</td>
          <td className="content">{e?.que_ar}</td>
          <td className="content">{e?.is_skip}</td>
          <td>
            <div className="actions">
              <DeleteButton onClick={() => deleteSelect(e)}>
                Delete
              </DeleteButton>
              <NavLink to={`show-single/${e?.id}`}>
                <NoButton>
                  <RemoveRedEyeIcon />
                </NoButton>
              </NavLink>
              <NoButton
                onClick={() => {
                  updateSelect(e);
                }}
              >
                Update question
              </NoButton>
              {/* <NoButton
              onClick={() => {
                updateAnsersSelect(e);
              }}
            >
              Update answers
            </NoButton> */}
            </div>
          </td>
        </tr>
      );
    });
  };

  const select = (feed: any) => {
    setSelected(feed);
    handleOpenUpdated();
  };
  const { data, isLoading, isFetching } = useGetCategoryUnityQuestionQuery(id);

  return (
    <div>
      <Bigsection>
        {isFetching || isLoading ? (
          <h1>Loading</h1>
        ) : (
          <table>
            <tr>
              <th>Question English</th>
              <th>Question Arabic</th>
              <th>Is skip</th>
              <th>actions</th>
            </tr>
            {mapping()}
          </table>
        )}
      </Bigsection>
      <UpdateQuestion
        open={openUpdated}
        handleClose={handleCloseUpdated}
        singleItem={selected}
      />
      {/* <UpdateAnswers
        open={openUpdatedAnswer}
        handleClose={handleCloseUpdatedAnswer}
        singleItem={selected}
      /> */}
      <DeleteQuestionUnity
        open={open}
        handleClose={handleClose}
        singleItem={selected}
      />
    </div>
  );
};

export default ShowSinlgeCategory;
