import React, { useEffect } from "react";
import { toast } from "react-toastify";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Modal, Switch, TextField } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  useUpdateCategoryUnityAnswerMediaMutation,
  useUpdateCategoryUnityAnswerMutation,
} from "../../../../../../../../../store/RTK/faq";
import StanderInput from "../../../../../../../../../components/common/standerInput";
import { SubmitButton } from "../../../../../../../../../components/common/Buttons/SubmitButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const UpdateMedia = ({ open, handleClose, singleItem }: any) => {
  const schema = yup.object({});
  console.log("singleItem", singleItem);
  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const params = useParams();
  const id = params?.id;

  const [error, setError] = React.useState([]);
  const [updateCategoryUnityAnswerMedia, { isLoading, isSuccess, isError }] =
    useUpdateCategoryUnityAnswerMediaMutation();

  const onSubmit = async (data: any) => {
    try {
      const sendData = {
        id: id,
        body: data,
      };
      const formData = new FormData();
      formData.append("answer_id", singleItem?.id);
      formData.append("media", data?.media);
      await updateCategoryUnityAnswerMedia(formData);
      console.log("Send data", data);
      // await methods.reset();
    } catch (error: any) {
      toast.error("something went wrong");
      setError(error?.data?.errors);
    }
  };

  React.useEffect(() => {
    if (isSuccess) {
      toast.success("عاش يابو الزووك");
    }
    if (isError) {
      toast.error("يووووة بقي");
    }
  }, [isSuccess, isError]);
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={style}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Grid container columns={12} spacing={3}>
                <Grid item xs={12} style={{}}>
                  <h4>ppppppppppppppp</h4>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={methods?.control}
                    name={`media`}
                    render={({ field: { value, onChange, ...field } }) => {
                      return (
                        <TextField
                          {...field}
                          value={value?.fileName}
                          onChange={(event: any) => {
                            onChange(event.target.files[0]);
                          }}
                          type="file"
                          id="picture"
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SubmitButton type="submit" fullWidth>
                    {isLoading ? "creating" : "Save"}
                  </SubmitButton>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Box>
      </Modal>
    </div>
  );
};

export default UpdateMedia;
